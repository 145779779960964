<script setup>
import { ref, onMounted } from 'vue';
import Choices from 'choices.js';
import { useRoute } from 'vue-router';
import { usePermitsStore } from '@/stores/permits';

import BaseMap from "@/components/maps/baseMap.vue";
import SubmitModal from "./modal/SubmitModal.vue";
import ApproveModal from "./modal/ApproveModal.vue";
import CancelModal from "./modal/CancelModal.vue";
import CloneModal from "./modal/CloneModal.vue";
import AddStepModal from "./modal/AddStepModal.vue";
import SummaryTab from "./tab/SummaryTab.vue";
import ActivitiesRiskTab from "./tab/ActivitiesRiskTab.vue";
import ProcedureTab from "./tab/ProcedureTab.vue";
import ExtraTab from "./tab/ExtraTab.vue";

// Refs for reactive data
const tab = ref("active");
const isDisabled = ref(true);
const mapType = ref("LiveView");

const permit = ref(null);
const route = useRoute();
const permitId = Number(route.params.id);
const permitLayerName = route.params.layerName;
const store = usePermitsStore();

const selectedPermit = (async () => {
  if (store.permits.length === 0) {
    await store.fetchPermits();
  }
  console.log(route.params);
  permit.value = store.permits.find(p => Number(p.OBJECTID) === permitId && p.layerName === permitLayerName);

  if (permit.value) {
    console.log("Selected Permit Found:", permit.value);
  } else {
    console.error(`Permit with ID ${permitId} not found.`);
  }
});

onMounted(async () => {
  await selectedPermit();
  initializeChoices(["permitType-edit", "permitType", "location-edit", "related-edit"]);
});

// Toggle disabled state and mapType
const toggleDisabled = () => {
  isDisabled.value = !isDisabled.value;
  mapType.value = isDisabled.value ? "LiveView" : "PlanningView";
};

// Helper functions for Choices
const initializeChoices = (ids) => {
  ids.forEach(id => {
    const element = document.getElementById(id);
    if (element) {
      new Choices(element, {
        searchEnabled: true,
        allowHTML: true,
      });
    }
  });

  const peopleElement = document.getElementById("people");
  if (peopleElement) {
    new Choices(peopleElement, {
      delimiter: ",",
      editItems: true,
      maxItemCount: 5,
      removeItemButton: true,
      addItems: true,
      allowHTML: true,
    });
  }
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row mb-2">
      <div class="col-lg-4">
        <h4 v-if="permit">{{ permit.layerName }}</h4>
      </div>
      <div class="col-lg-8 text-end">
        <button class="btn bg-gradient-primary mb-0 toast-btn me-2" type="button" @click="toggleDisabled">Edit</button>
        <button class="btn bg-gradient-info mb-1 toast-btn me-2" type="button" data-bs-toggle="modal" data-bs-target="#submitModal">Submit for approval</button>
        <button class="btn bg-gradient-info mb-1 toast-btn me-2" type="button" data-bs-toggle="modal" data-bs-target="#approveModal">Approve</button>
        <button class="btn bg-gradient-dark mb-1 toast-btn me-2" type="button" data-bs-toggle="modal" data-bs-target="#cancelModal">Cancel / Decline</button>
        <button class="btn bg-gradient-info mb-1 toast-btn me-2" type="button" data-bs-toggle="modal" data-bs-target="#cloneModal">Clone as preset</button>
        <button class="btn bg-gradient-warning mb-1 toast-btn me-2" type="button" @click="$router.push('/activity-list')">Activity Lists</button>
      </div>
    </div>

    <!-- Modals -->
    <SubmitModal v-if="permit" :permit="permit" />
    <ApproveModal />
    <CancelModal />
    <CloneModal v-if="permit" :permit="permit" />
    <AddStepModal />

    <!-- Tabs -->
    <div class="row">
      <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="javascript:;" role="tab"
              aria-selected="true" @click="tab = 'active'">
              <span class="ms-1">Summary</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="javascript:;" role="tab"
              aria-selected="true" @click="tab = 'activites'">
              <span class="ms-1">Activities & Risk</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="javascript:;" role="tab"
              aria-selected="true" @click="tab = 'procedure'">
              <span class="ms-1">Procedure</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false"
              @click="tab = 'extra'">
              <span class="ms-1">Attachments</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="row">
          <div class="card mt-4">
            <SummaryTab :tab="tab" :permit="permit" :isDisabled="isDisabled" />
            <ActivitiesRiskTab :tab="tab" :isDisabled="isDisabled" />
            <ProcedureTab :tab="tab" :operatingSteps="operatingSteps" :isDisabled="isDisabled" />
            <ExtraTab :tab="tab" :permitFiles="permitFiles" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="mt-4">
          <BaseMap :selectedPermit="permit" :mapType="mapType" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fileBlock {
  border-radius: 10px;
  border: solid 1px #ebebeb;
  padding: 10px;
}
</style>
