<script setup>
import { ref, provide, nextTick } from 'vue';
import Start from './components/Start.vue';
import Location from './components/Location.vue';
import Final from './components/Final.vue';

const activeClass = 'js-active position-relative';
const activeStep = ref(0);
const formSteps = 2;

const nextStep = () => {
  if (activeStep.value < formSteps) {
    activeStep.value += 1;
    scrollToTop();
  } else {
    activeStep.value -= 1;
  }
};

const prevStep = () => {
  if (activeStep.value > 0) {
    activeStep.value -= 1;
    scrollToTop();
  }
};

const scrollToTop = () => {
  nextTick(() => {
    const topAnchor = document.querySelector('#top');
    if (topAnchor) {
      topAnchor.scrollIntoView({ behavior: 'smooth' });
    }
  });
};

provide('nextStep', nextStep);
provide('prevStep', prevStep);
provide('activeStep', activeStep);
provide('activeClass', activeClass);
</script>

<template>
  <div id="top" class="py-4 h-100 container-fluid">
    <div class="row">
      <h4>Report Safety Issue</h4>
      <div class="text-center col-12">
        <div class="mb-5 multisteps-form">
          <!--progress bar-->
          <div class="row">
            <div class="mx-auto my-5 col-12 col-lg-8">
              <div class="card">
                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div class="bg-gradient-info shadow-info border-radius-lg pt-4 pb-3">
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn js-active"
                        type="button"
                        title="Start"
                        :class="activeStep >= 0 ? activeClass : ''"
                        @click="activeStep = 0"
                      >
                        <span>Start</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Location"
                        :class="activeStep >= 1 ? activeClass : ''"
                        @click="activeStep = 1"
                      >
                        <span>Location</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Finalise"
                        :class="activeStep >= 2 ? activeClass : ''"
                        @click="activeStep = 2"
                      >
                        <span>Finalise</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <!--single form panel-->
                    <Start v-if="activeStep === 0" />
                    <!--single form panel-->
                    <location :class="activeStep === 1 ? activeClass : ''" />
                    <!--single form panel-->
                    <final :class="activeStep === 2 ? activeClass : ''" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
