<script setup>
import { ref } from 'vue';
import MaterialButton from '@/components/materialKit/MaterialButton.vue';
import IncidentsTable from '@/components/tables/IncidentsTable.vue';

const filterStatus = ref('All');

const filterByStatus = (status) => {
  filterStatus.value = status;
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <h4 class="m-3">Site Incidents</h4>
      </div>
      <div class="col-6 text-end">
        <router-link to="/active-work" class="me-2">
          <MaterialButton variant="gradient" color="info">
            Recent Incidents (Map View)
          </MaterialButton>
        </router-link>
        <router-link to="/report-issue" class="me-2">
          <MaterialButton variant="gradient" color="info">
            Report Incident
          </MaterialButton>
        </router-link>
        <router-link to="/hazards">
          <MaterialButton variant="gradient" color="warning">
            Hazards List
          </MaterialButton>
        </router-link>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">New incidents reported</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-danger"
                  @click="filterByStatus('New')"
                >
                  <h5 class="text-white mt-3">1</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Total incidents in review</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-danger"
                  @click="filterByStatus('In Review')"
                >
                  <h5 class="text-white mt-3">1</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="p-3 pb-0 card-body">
          <button class="btn" @click="filterByStatus('All')">All</button>
          <button class="btn" @click="filterByStatus('New')">New Incidents</button>
          <button class="btn" @click="filterByStatus('In Review')">Incidents in Review</button>
          <button class="btn" @click="filterByStatus('Open Incident')">Open Incidents</button>
          <button class="btn" @click="filterByStatus('Closed')">Closed Incidents</button>
        </div>
        <div class="card">
          <div class="card-body">
            <IncidentsTable :filterStatus="filterStatus" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
tr,
.bg-gradient-info,
.icon-lg {
  cursor: pointer;
}
</style>
