import { defineStore } from "pinia";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { useAuthStore } from "@/stores/auth";
import { usePermitsStore } from "@/stores/permits";
import { useArcGISStore } from "@/stores/arcgisStore";
import { useOrganisationStore } from "@/stores/organisation";

export const useMainStore = defineStore("main", {
  state: () => ({
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    color: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: false,
    showNavbar: false,
    showFooter: false,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    error: null,
  }),
  actions: {
    toggleConfigurator() {
      this.showConfig = !this.showConfig;
    },
    toggleEveryDisplay() {
      this.showNavbar = !this.showNavbar;
      this.showSidenav = !this.showSidenav;
      this.showFooter = !this.showFooter;
    },
    toggleHideConfig() {
      this.hideConfigButton = !this.hideConfigButton;
    },
    setIsPinned(payload) {
      this.isPinned = payload;
    },
    setColor(payload) {
      this.color = payload;
    },
    navbarMinimize() {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];
      if (sidenavShow.classList.contains("g-sidenav-pinned")) {
        sidenavShow.classList.remove("g-sidenav-pinned");
        sidenavShow.classList.add("g-sidenav-hidden");
        this.isPinned = true;
      } else {
        sidenavShow.classList.remove("g-sidenav-hidden");
        sidenavShow.classList.add("g-sidenav-pinned");
        this.isPinned = false;
      }
    },
    toggleNavbarFixed() {
      this.isNavFixed = !this.isNavFixed;
    },
    async logout() {
      const authStore = useAuthStore();
      const permitsStore = usePermitsStore();
      const arcgisStore = useArcGISStore();
      const organisationStore = useOrganisationStore();

      this.showSidenav = false;
      this.showNavbar = false;
      this.showFooter = false;
      this.showMain = true;

      await arcgisStore.logout();
      await permitsStore.logout();
      await authStore.logout();
      await organisationStore.logout();
    },
  },
});
