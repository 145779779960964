<template>
    <div class="p-3 pb-0 card-body">
      <div class="mt-3 row">
        <h4>Home and Recorded Locations</h4>
        <base-map></base-map>
      </div>
    </div>
  </template>
  
  <script>
  import baseMap from "@/components/maps/baseMap.vue";
  
  export default {
    name: "LocationsTab",
    components: {
      baseMap
    }
  };
  </script>
  