<script setup>
import { onMounted, onBeforeUnmount, computed } from "vue";
import { useMainStore } from "@/stores/index";
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";

// Initialize the Pinia store
const store = useMainStore();

// Computed properties from the Pinia store
// const isAbsolute = computed(() => store.isAbsolute);
// const absolute = computed(() => store.absolute);
// const color = computed(() => store.color);
const sidebarType = computed(() => store.sidebarType);
const isDarkMode = computed(() => store.isDarkMode);

// Methods
const sidebar = (type) => {
  store.sidebarType = type;
};

const darkMode = () => {
  if (store.isDarkMode) {
    store.isDarkMode = false;
    deactivateDarkMode();
  } else {
    store.isDarkMode = true;
    activateDarkMode();
  }
};

const sidenavTypeOnResize = () => {
  let white = document.querySelector("#btn-white");
  if (window.innerWidth < 1200) {
    white?.classList.add("disabled");
  } else {
    white?.classList.remove("disabled");
  }
};

// Lifecycle hooks
onMounted(() => {
  window.addEventListener("resize", sidenavTypeOnResize);
  window.addEventListener("load", sidenavTypeOnResize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", sidenavTypeOnResize);
  window.removeEventListener("load", sidenavTypeOnResize);
});
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-lg-8">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Global Site Configuration</h6>
            </div>
          </div>
          <div class="card-body p-3 my-2 ">
            <div class="mt-2 d-flex">
              <h6 class="mb-0">Allow users to draw locations</h6>
              <div class="form-check form-switch ps-0 ms-auto my-auto">
                <input class="form-check-input mt-1 ms-auto" type="checkbox" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Global Display Settings</h6>
            </div>
          </div>
          <div class="p-3 my-2 card-body">
            <div class="mt-3">
              <h6 class="mb-0">Side Navigation Type</h6>
              <p class="text-sm">Set background for the side navigation.</p>
            </div>
            <div class="d-flex">
              <button
                id="btn-dark"
                class="px-3 mb-2 btn bg-gradient-dark"
                :class="sidebarType === 'bg-gradient-dark' ? 'active' : ''"
                @click="sidebar('bg-gradient-dark')"
              >
                Dark
              </button>
              <button
                id="btn-white"
                class="px-3 mb-2 btn bg-gradient-dark ms-2"
                :class="sidebarType === 'bg-white' ? 'active' : ''"
                @click="sidebar('bg-white')"
              >
                White
              </button>
            </div>
            <p class="text-sm d-xl-none d-block mt-2">
              Note: You can change the sidenav type just on desktop view.
            </p>

            <hr class="my-3 horizontal dark" />
            <div class="mt-2 d-flex">
              <h6 class="mb-0">Light Mode / Dark Mode</h6>
              <div class="form-check form-switch ps-0 ms-auto my-auto">
                <input
                  class="form-check-input mt-1 ms-auto"
                  type="checkbox"
                  :checked="isDarkMode"
                  @click="darkMode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ArcGISData />
    </div>
  </div>
</template>

<style scoped>
/* Add any additional styling needed */
</style>
