<script setup>
import { onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import * as Choices from 'choices.js';
import MaterialInput from '@/components/materialKit/MaterialInput.vue';
import MaterialTextarea from '@/components/materialKit/MaterialTextarea.vue';
import MaterialCheckbox from '@/components/materialKit/MaterialCheckbox.vue';
import { usePeopleStore } from '@/stores/people';
import { useSystemUserStore } from '@/stores/systemUser';


const peopleStore = usePeopleStore();
const sysUserStore = useSystemUserStore();
const router = useRouter();
const route = useRoute();

const personId = route.params.id;
const personData = computed(() => peopleStore.selectedPersonData);
const sysUserData = computed(() => sysUserStore.selectedUserData);


onMounted(async () => {
  peopleStore.selectedPerson = personId;
  await peopleStore.fetchSelectedPerson(personId);
  await sysUserStore.fetchSelectedUser(personId);
  if (document.getElementById("choices-skills")) {
    var skills = document.getElementById("choices-skills");
    new Choices(skills, {
      delimiter: ",",
      editItems: true,
      maxItemCount: 5,
      removeItemButton: true,
      addItems: true,
      allowHTML: true,
    });
  }
});

const navigateTo = (path) => {
  router.push(path);
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row mb-5">
      <div class="col-lg-8 mt-lg-0 mt-4">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-1 font-weight-bolder">{{ personData?.Name || sysUserData?.Name }} from {{ personData?.Company || sysUserData?.Company }}</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-12">
                <material-input
                  id="firstName"
                  variant="static"
                  label="Name"
                  :value="personData?.Name || sysUserData?.Name"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <material-input
                  id="email"
                  type="email"
                  variant="static"
                  label="Email"
                  :value="personData?.Email || sysUserData?.Email"
                />
              </div>
              <div class="col-6">
                <material-input
                  id="phoneNumber"
                  type="phone"
                  variant="static"
                  label="Phone Number"
                  :value="personData?.Phone || sysUserData?.Phone"
                />
              </div>
            </div>
            <div class="row mt-4">
              <h5 class="mb-1 font-weight-bolder">Work Details</h5>
              <div class="col-6">
                <div class="p-3">
                  <label class="form-label mt-4 ms-0">Type</label>
                  <material-checkbox id="checkboxId" :checked="personData?.Type == 'Contractor'">Contractor</material-checkbox>
                  <material-checkbox id="checkboxId" :checked="personData?.Type == 'Port Personnel'">Port Personnel</material-checkbox>
                </div>
              </div>
              <div class="col-6">
                <label class="form-label mt-4 ms-0">Roles</label>
                <input
                  id="choices-skills"
                  class="form-control mt-0"
                  type="text"
                  value="Safety, Supervisor"
                  placeholder="Enter something"
                  onfocus="focused(this)"
                  onfocusout="defocused(this)"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <material-input
                  id="title"
                  label="Work Role"
                  variant="static"
                  class="ms-0 mt-4"
                  :value="personData?.WorkRole || 'Not Available'"
                />
              </div>
              <div class="col-6">
                <material-input
                  id="title"
                  label="Company"
                  variant="static"
                  class="mt-4 ms-0"
                  :value="personData?.Company || sysUserData?.Company"
                />
              </div>
              <div class="col-12">
                <material-input
                  id="title"
                  label="Reports to"
                  variant="static"
                  class="mt-4 ms-0"
                  :value="personData?.ReportsTo || sysUserData?.ReportsTo"
                />
              </div>
              <div class="col-12 mt-4">
                <h5 class="mb-1 font-weight-bolder">Qualifications</h5>
                <material-input
                  id="title"
                  label="Licences"
                  variant="static"
                  class="mt-4 ms-0"
                  :value="personData?.Licenses || 'Not Available'"
                />
                <label class="mt-2 ms-0">Qualifications</label>
                <material-textarea
                  id="qualifications"
                  variant="static"
                  class="mt-2 ms-0"
                  :value="personData?.Qualifications || 'Not Available'"
                ></material-textarea>
                <h5 class="mb-1 font-weight-bolder mt-4">Extra</h5>
                <label class="mt-2 ms-0">Notes</label>
                <material-textarea
                  id="description"
                  variant="static"
                  class="mt-2 ms-0"
                  :value="personData?.Description || 'Not Available'"
                ></material-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <button
          class="mb-0 mt-4 btn bg-gradient-info"
          type="button"
          title="Site Works / Permits"
          @click="navigateTo('/activity-list')"
        >
          Site Works / Permits
        </button>
        <div class="card mt-4">
          <div class="card-header">
            <h5>Items Managed</h5>
          </div>
          <div class="card-body pt-0">
            <router-link to="/asset/123456">
              <p class="text-sm">Site A Forklift</p>
            </router-link>
            <hr />
            <router-link to="/asset/123456">
              <p class="text-sm">Crane B</p>
            </router-link>
          </div>
        </div>
        <div class="card mt-4">
          <div class="card-header">
            <h5>Linked to Incidents</h5>
          </div>
          <div class="card-body pt-0">
            <router-link to="/incident/123456">
              <p class="text-sm">Forklift accident</p>
            </router-link>
            <hr />
            <router-link to="/incident/123456">
              <p class="text-sm">Crane Malfunction</p>
            </router-link>
          </div>
        </div>
        <div class="card mt-4">
          <div class="card-header">
            <h5>Linked to Hazards</h5>
          </div>
          <div class="card-body pt-0">
            <router-link to="/hazard/123456">
              <p class="text-sm">Pot Hole</p>
            </router-link>
            <hr />
            <router-link to="/hazard/123456">
              <p class="text-sm">Bad door hinge</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
tr,
.bg-gradient-info {
  cursor: pointer;
}
</style>
