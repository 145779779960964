import { defineStore } from 'pinia';
import TucumcariClient from "@/api/tucumcari";
import { getMockTemplates } from '@/services/mockData';

export const useTemplateStore = defineStore('template', {
  state: () => ({
    templates: [],
    mockTemplates: [],
  }),
  getters: {
    allTemplates(state) {
      return state.templates;
    },
    allMockTemplates(state) {
      return state.mockTemplates;
    }
  },
  actions: {
    async fetchTemplates() {
      const client = new TucumcariClient();
      try {
        const templates = await client.getAllTemplate();
        const responseData = await templates.response;
        const data = await responseData.body.json();
        console.log("API Response:", data);
        this.templates = data;
      } catch (error) {
        console.error("Failed to fetch templates:", error);
      }
    },

    async fetchMockTemplates() {
      const mockTemplate = await getMockTemplates();
      this.mockTemplates = mockTemplate;
    },
  },
});
