export const mockPermits = [
  {
    ID: 1,
    ProjectTitle: "Confined spaces - Clearing our storm sewer manhole",
    Activity: "Permit",
    Applicant: "John Contractor",
    Type: "Confined spaces",
    StartDate: "10/10/2024",
    EndDate: "11/10/2024",
    Location: "Confined space A",
    Status: "Awaiting Approval"
  },
  {
    ID: 2,
    ProjectTitle: "Ship Weld Hot Works",
    Activity: "Permit",
    Applicant: "Rene Ruiz",
    Type: "Hot Works",
    StartDate: "04/07/2024",
    EndDate: "05/07/2024",
    Location: "Dry Dock",
    Status: "Pending"
  },
  {
    ID: 3,
    ProjectTitle: "Confined spaces - Log Yard Barrier Install",
    Activity: "Permit",
    Applicant: "Gary Valencia",
    Type: "Confined spaces",
    StartDate: "12/07/2024",
    EndDate: "13/07/2024",
    Location: "Log Yard",
    Status: "Declined"
  },
  {
    ID: 4,
    ProjectTitle: "Bunkering of the Bank",
    Activity: "Permit",
    Applicant: "Bob Dobalina",
    Type: "Bunkering",
    StartDate: "30/09/2024",
    EndDate: "31/09/2024",
    Location: "Dock 1",
    Status: "Draft"
  },
  {
    ID: 5,
    ProjectTitle: "Inspection of the Boat",
    Activity: "Permit",
    Applicant: "Jimmy Butler",
    Type: "Inspection",
    StartDate: "24/08/2024",
    EndDate: "25/08/2024",
    Location: "Pier 2",
    Status: "Cancelled"
  },
  // // New Mock Data Entries
  // {
  //   ID: 6,
  //   ProjectTitle: "Maintenance of Navigation Equipment",
  //   Activity: "Permit",
  //   Applicant: "Alice Johnson",
  //   Type: "Maintenance",
  //   StartDate: "02/05/2024",
  //   EndDate: "03/05/2024",
  //   Location: "Control Tower",
  //   Status: "Updated"
  // },
  // {
  //   ID: 7,
  //   ProjectTitle: "Electrical Inspection of Dock Lighting",
  //   Activity: "Permit",
  //   Applicant: "John Broca",
  //   Type: "Inspection",
  //   StartDate: "05/05/2024",
  //   EndDate: "06/05/2024",
  //   Location: "Dock 2",
  //   Status: "Pending"
  // },
  // {
  //   ID: 8,
  //   ProjectTitle: "Plumbing Repair in Crew Quarters",
  //   Activity: "Permit",
  //   Applicant: "Jane Smith",
  //   Type: "Repair",
  //   StartDate: "08/05/2024",
  //   EndDate: "09/05/2024",
  //   Location: "Crew Quarters",
  //   Status: "Awaiting Approval"
  // },
  // {
  //   ID: 9,
  //   ProjectTitle: "Painting Exterior of Cargo Terminal",
  //   Activity: "Permit",
  //   Applicant: "Bill Brown",
  //   Type: "Painting",
  //   StartDate: "10/05/2024",
  //   EndDate: "12/05/2024",
  //   Location: "Cargo Terminal",
  //   Status: "Draft"
  // },
  // {
  //   ID: 16780,
  //   ProjectTitle: "Fire Alarm Test in All Areas",
  //   Activity: "Site Work",
  //   Applicant: "Cathy Green",
  //   Type: "Inspection",
  //   StartDate: "15/05/2024",
  //   EndDate: "16/05/2024",
  //   Location: "Admin Building",
  //   Status: "Cancelled"
  // }
];

export const mockIncidents = [
  {
    occurDate: "30/09/2024",
    reportDate: "1/10/2024",
    Title: "Tripped while cleaning manhole",
    Location: "Storm sewer manhole",
    hazards: true,
    Status: "In Review",
    asset: true,
    ID: 123456,
  },
  {
    occurDate: "04/07/2024",
    reportDate: "05/07/2024",
    Title: "Crane Malfunction",
    Location: "The left side",
    hazards: true,
    Status: "Closed",
    asset: true,
    ID: 123456,
  },
  {
    occurDate: "12/09/2024",
    reportDate: "12/09/2024",
    Title: "Forklift accident",
    Location: "Area 52",
    hazards: false,
    Status: "Open Incident",
    asset: true,
    ID: 123456,
  },
  {
    occurDate: "30/08/2024",
    reportDate: "30/08/2024",
    Title: "Crane Malfunction",
    Location: "The left side",
    hazards: false,
    Status: "New",
    asset: "",
    ID: 123456,
  },
];

export const mockAssets = [
  {
    Title: "Forklift",
    expiry: "24/04/2024",
    Location: "Area 52",
    incidents: "Linked",
    Status: "Active",
    ID: 123456
  },
  {
    Title: "Crane",
    expiry: "24/04/2024",
    Location: "The left side",
    incidents: "",
    Status: "Active",
    ID: 123456
  },
  {
    Title: "Building",
    expiry: "",
    Location: "Area 52",
    incidents: "Linked",
    Status: "Archived",
    ID: 123456
  },
  {
    Title: "Loader",
    expiry: "",
    Location: "Area 52",
    incidents: "",
    Status: "Archived",
    ID: 123456
  },
  {
    Title: "Bollards",
    expiry: "",
    Location: "The left side",
    incidents: "Linked",
    Status: "Active",
    ID: 123456
  },
  {
    Title: "Truck",
    expiry: "24/04/2024",
    Location: "The left side",
    incidents: "",
    Status: "Active",
    ID: 123456
  },
];

export const mockHazards = [
  {
    updatedDate: "31/07/2024",
    reportDate: "19/07/2024",
    Title: "Pot Hole",
    Location: "Left area by the water",
    incidents: "Linked",
    Status: "Open Hazard",
    ID: 123456
  },
  {
    updatedDate: "04/08/2024",
    reportDate: "05/08/2024",
    Title: "Dock not draining",
    Location: "The left side",
    incidents: false,
    Status: "Closed",
    ID: 123456
  },
  {
    updatedDate: "12/06/2024",
    reportDate: "12/06/2024",
    Title: "Flapping banner not secure",
    Location: "Area 52",
    incidents: false,
    Status: "Open Hazard",
    ID: 123456
  },
  {
    updatedDate: "07/10/2024",
    reportDate: "05/10/2024",
    Title: "Bad door hinge",
    Location: "Admin Office",
    risks: "Collision",
    incidents: "Linked",
    Status: "New",
    ID: 123456
  },
];

export const mockContractors = [
  {
    name: "Quinn White",
    company: "Fumigation Systems",
    StartDate: "24/04/2024",
    reportsTo: "Dave Monkey",
    role: "Service Worker",
    Status: "Contractor",
    ID: 123456
  },
  {
    name: "Rufus Dogface",
    company: "Bright Spark",
    StartDate: "24/04/2024",
    reportsTo: "Ed White",
    role: "Electrcian",
    Status: "Contractor",
    ID: 123456
  },
  {
    name: "Gary Cat",
    company: "The Port",
    StartDate: "24/04/2024",
    reportsTo: "Mr Admin",
    role: "Supervisor",
    Status: "Port Personnel",
    ID: 123456
  },
  {
    name: "Bob Dobalina",
    company: "The Port",
    StartDate: "24/04/2024",
    reportsTo: "Gary Cat",
    role: "Forklift Driver",
    Status: "Port Personnel",
    ID: 123456
  },
  {
    name: "Barry White",
    company: "Trukers",
    StartDate: "24/04/2024",
    reportsTo: "Gary Cat",
    role: "Truck Driver",
    Status: "Contractor",
    ID: 123456
  },
];

export const mockPersonnel = [
  {
    name: "Quinn White",
    company: "Fumigation Systems",
    StartDate: "24/04/2024",
    reportsTo: "Dave Monkey",
    role: "Service Worker",
    Status: "Contractor",
    ID: 123456
  },
  {
    name: "Rufus Dogface",
    company: "Bright Spark",
    StartDate: "24/04/2024",
    reportsTo: "Ed White",
    role: "Electrcian",
    Status: "Contractor",
    ID: 123456
  },
  {
    name: "Gary Cat",
    company: "The Port",
    StartDate: "24/04/2024",
    reportsTo: "Mr Admin",
    role: "Supervisor",
    Status: "Port Personnel",
    ID: 123456
  },
  {
    name: "Bob Dobalina",
    company: "The Port",
    StartDate: "24/04/2024",
    reportsTo: "Gary Cat",
    role: "Forklift Driver",
    Status: "Port Personnel",
    ID: 123456
  },
  {
    name: "Barry White",
    company: "Trukers",
    StartDate: "24/04/2024",
    reportsTo: "Gary Cat",
    role: "Truck Driver",
    Status: "Contractor",
    ID: 123456
  },
];

export const mockTemplates = [
  {
    code: "BUNK",
    template: "Bunkering",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "CONS",
    template: "Confined spaces",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "CORR",
    template: "CorrIDor Access",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "CRU",
    template: "Cruise Activity",
    Type: "Site Work",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "INS",
    template: "Inspection",
    Type: "Site Work",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "LIFT",
    template: "Crane and Heavy lifts",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "DIVE",
    template: "Dive Ops",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "DRON",
    template: "Drone Ops",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "EVAC",
    template: "Evacuations",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "FUMG",
    template: "Fumigation",
    Type: "Permit",
    Status: "Archived",
    ID: "1234"
  },
  {
    code: "DIGW",
    template: "Ground Penetrating works",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "HOTW",
    template: "Hotworks",
    Type: "Permit",
    Status: "Archived",
    ID: "1234"
  },
  {
    code: "NAVW",
    template: "Nav Warnings",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "WATH",
    template: "Working at Height",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "OTHR",
    template: "Other Permit",
    Type: "Permit",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "CP",
    template: "Capital Project",
    Type: "Site Work",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "RM",
    template: "Repairs / Maintenance",
    Type: "Site Work",
    Status: "Active",
    ID: "1234"
  },
  {
    code: "TMP",
    template: "Traffic Management Plan",
    Type: "Site Work",
    Status: "Active",
    ID: "1234"
  },
];

export const mockPeople = [
  {
    name:"Dave Dog",
    company:"The Port",
    StartDate:"24/04/2024",
    reportsTo:"Mr Admin",
    role:"Manager",
    Status:"System User",
    ID: 123456
  },     
  {
    name:"Bob Boss",
    company:"The Port",
    StartDate:"24/04/2024",
    reportsTo:"Mr Admin",
    role:"Manager",
    Status:"System User",
    ID: 123456
  },
];

export const mockTrafficPlan = [
    {
      plan: "Left Area Plan",
      Location: "Area 52",
      Status: "Active",
      ID: 123456
    },
    {
      plan: "Right Area Plan",
      Location: "The left side",
      Status: "Active",
      ID: 123456
    },
    {
      plan: "Plan for today",
      Location: "Area 52",
      Status: "Archived",
      ID: 123456
    },
    {
      plan: "Test plan",
      Location: "The left side",
      Status: "Active",
      ID: 123456
    },
];

export const getMockPermits = () => {
  return Promise.resolve(mockPermits);
};

export const getMockIncidents = () => {
  return Promise.resolve(mockIncidents);
};

export const getMockAssets = () => {
  return Promise.resolve(mockAssets);
};

export const getMockHazards = () => {
  return Promise.resolve(mockHazards);
};

export const getMockContractors = () => {
  return Promise.resolve(mockContractors);
};

export const getMockPersonnel = () => {
  return Promise.resolve(mockPersonnel);
};

export const getMockTemplates = () => {
  return Promise.resolve(mockTemplates);
};

export const getMockPeople = () => {
  return Promise.resolve(mockPeople);
};

export const getMockTrafficPlan = () => {
  return Promise.resolve(mockTrafficPlan);
};

