<script setup>
import { defineProps } from 'vue';

// Define props
defineProps({
  tab: {
    type: String,
    required: true,
  },
  operatingSteps: {
    type: Array,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    required: true,
  },
});

// Function to open the Risk Score Calculator
const openRiskScoreCalculator = () => {
  window.open('/img/RISK-SCORE-CALCULATOR.png', '_blank');
};

// Function to open the Hierarchy of Control Measures
const openControlMeasures = () => {
  window.open('/img/Hierarchy-of-Control-Measures.png', '_blank');
};
</script>

<template>
  <div v-show="tab === 'procedure'" class="p-3 pb-0 card-body">
    <!-- Traffic Management Plan Section -->
    <div class="col-12 mb-4">
      <div class="mt-3 row">
        <div class="mt-2 mb-4 border card-plain border-radius-lg p-4">
          <div v-if="!isDisabled" class="text-end mb-4">
            <button class="btn mb-0 toast-btn me-2" type="button">Remove</button>
          </div>
          <a href="#" target="_blank">Traffic Management Plan for Route 6</a>
        </div>
      </div>

      <!-- Buttons for Calculators -->
      <div class="text-end mb-4">
        <button
          class="btn bg-gradient-info mb-0 toast-btn me-2"
          type="button"
          @click="openRiskScoreCalculator"
        >
          Check Risk Score Calculator
        </button>
        <button
          class="btn bg-gradient-info mb-0 toast-btn me-2"
          type="button"
          @click="openControlMeasures"
        >
          Check Hierarchy of Control Measures
        </button>
      </div>

      <!-- Instructional Text -->
      <p class="text-dark">
        IF RESIDUAL RISK SCORE IS STILL HIGH OR CRITICAL, A WORK METHOD STATEMENT MUST BE CREATED AND APPROVED BY INFRASTRUCTURE SERVICES MANAGER
      </p>

      <!-- Operating Steps Section -->
      <div v-for="(operatingStep, index) in operatingSteps" :key="index" class="col-12 mb-4">
        <div class="mt-2 border card-plain border-radius-lg p-4">
          <div v-if="!isDisabled" class="text-end mb-4">
            <button class="btn mb-0 toast-btn me-2" type="button">Remove step</button>
          </div>
          <h6 class="text-dark font-weight-bold mb-0">Step: {{ operatingStep.step }}</h6>
          <div class="row">
            <div class="col-6">
              <p class="text-sm mt-1 mb-0">
                <b>Hazards:</b> <br>
                {{ operatingStep.hazrd }}
              </p>
            </div>
            <div class="col-6">
              <p class="text-sm mt-1 mb-0">
                <b>Inherent Risk Score:</b> {{ operatingStep.inheritRisk }}<br>
                <b>Residual Risk Score: <span class="text-success">{{ operatingStep.residualRisk }}</span></b><br>
                <b>Action By:</b> {{ operatingStep.group }}
              </p>
            </div>
          </div>
          <hr>
          <p class="text-sm mt-1 mb-0 font-weight-bold">Control Methods & Monitoring:</p>
          <ul class="text-sm mt-1 mb-0">
            <li v-for="(control, controlIndex) in operatingStep.control" :key="controlIndex">{{ control }}</li>
          </ul>
        </div>
      </div>

      <!-- Button to Add New Step -->
      <div v-if="!isDisabled" class="text-end mb-4">
        <button
          class="btn bg-gradient-info mb-0 toast-btn me-2"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#addStepModal"
        >
          Add new step
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
