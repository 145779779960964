import { defineStore } from "pinia";
import { useArcGISStore } from "@/stores/arcgisStore.js";
import TucumcariClient from "@/api/tucumcari";
import { getMockPermits } from '@/services/mockData';

// import axios from 'axios'; // To simulate API call

const scrollToTop = () => {
  const topAnchor = document.querySelector("#top");
  if (topAnchor) {
    topAnchor.scrollIntoView({ behavior: "smooth" });
  }
};

export const usePermitsStore = defineStore("permits", {
  state: () => ({
    activeStep: 0,
    formSteps: 4,
    permits: [], // Existing permits from ArcGISStore
    mockPermits: [],
    selectedPreset: "",
    selectedPeople: [],
    selectedTMPlan: "",
    selectedActivities: [],
    selectedConflicts: [],
    additionalFiles: [],

    // New fields for Start.vue
    projectTitle: "",
    applicant: "",
    description: "",
    qualificationsRequired: "",
    workLocation: "",

    // New fields for Conflicts.vue
    startDate: null,
    endDate: null,

    // New fields for Activities.vue
    standardActivities: [],
    risks: [],

    selectedApprovers: [],
    selectActivityType: "",

    selectedPermitTemplate: {},
    selectedTemplate: "",
  }),
  getters: {
    allPermits: (state) => state.permits,
    allMockPermits(state) {
      return  state.mockPermits;
    },

    getPermitById: (state) => (id) => {
      return state.permits.find((permit) => permit.OBJECTID === id);
    },
  },
  actions: {
    async fetchPermits() {
      const arcgisStore = useArcGISStore();
      try {
        if (arcgisStore.allItems.length === 0) {
          await arcgisStore.fetchAccessToken();
        }
        // Update the store with filtered data
        this.permits = arcgisStore.allItems;
        console.log("Fetched Permits:", this.permits);
      } catch (error) {
        console.error("Failed to fetch permits:", error);
      }
    },

    async fetchMockPermits() {
      const mockPermits = await getMockPermits();
      this.mockPermits = mockPermits;
    },

    async fetchPermitTemplate(id) {
      const client = new TucumcariClient();
      try {
        const template = await client.getTemplateById(id);
        const responseData = await template.response;
        const data = await responseData.body.json();
        console.log("API Response:", data);
        this.selectedPermitTemplate = data;
        console.log("Permit template object:", this.selectedPermitTemplate);

        this.projectTitle = data.ProjectTitle || '';
        this.description = data.Description || '';
        this.qualificationsRequired = data.QualificationsRequired || '';

        // Location Tab
        this.workLocation = data.Location && data.Location.Name ? data.Location.Name : '';

        // Map Standard Activities and Critical Risks
        if (data.ActivitiesAndRisks && data.ActivitiesAndRisks.length > 0) {
          this.standardActivities = data.ActivitiesAndRisks[0].StandardActivities || [];
          this.risks = data.ActivitiesAndRisks[0].CriticalRisks || [];
        } else {
          this.standardActivities = [];
          this.risks = [];
        }
      } catch (error) {
        console.error("Failed to fetch permit template values:", error);
      }
    },

    updateActivity(activity, isChecked) {
      if (isChecked && !this.standardActivities.includes(activity)) {
        this.standardActivities.push(activity);
      } else if (!isChecked && this.standardActivities.includes(activity)) {
        this.standardActivities = this.standardActivities.filter(a => a !== activity);
      }
    },
    
    updateRisk(risk, isChecked) {
      if (isChecked && !this.risks.includes(risk)) {
        this.risks.push(risk);
      } else if (!isChecked && this.risks.includes(risk)) {
        this.risks = this.risks.filter(r => r !== risk);
      }
    },

    async submitPermit() {
      try {
        const newPermit = {
          selectActivityType: this.selectActivityType,
          projectTitle: this.projectTitle,
          applicant: this.applicant,
          description: this.description,
          qualificationsRequired: this.qualificationsRequired,
          selectedTemplate: this.selectedTemplate,
          selectedPreset: this.selectedPreset,
          selectedPeople: this.selectedPeople,
          selectedTMPlan: this.selectedTMPlan,
          selectedActivities: this.selectedActivities,
          selectedConflicts: this.selectedConflicts,
          additionalFiles: this.additionalFiles,
          workLocation: this.workLocation,
          startDate: this.startDate,
          endDate: this.endDate,
          standardActivities: this.standardActivities,
          risks: this.risks,
          selectedApprovers: this.selectedApprovers,
        };

        // For now, we can mock the API call using axios or just log the data
        console.log("Submitting permit data:", newPermit);

        // In the future, replace this with a real API call:
        /*
        const response = await axios.post('/api/permits', newPermit);
        console.log('Permit added successfully:', response.data);
        */

        // Clear the form after submission
        this.resetForm();
      } catch (error) {
        console.error("Failed to submit permit:", error);
      }
    },

    logout() {
      this.permits = [];
      this.resetForm();
    },

    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      }
      scrollToTop();
      console.log(this.activeStep);
    },

    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
      scrollToTop();
      console.log(this.activeStep);
    },

    resetForm() {
      this.activeStep = 0;
      this.formSteps = 4;
      this.selectActivityType = "";
      this.selectedTemplate = "";
      this.selectedPreset = "";
      this.selectedPeople = [];
      this.selectedTMPlan = "";
      this.selectedActivities = [];
      this.selectedConflicts = [];
      this.additionalFiles = [];
      this.projectTitle = "";
      this.applicant = "";
      this.description = "";
      this.qualificationsRequired = "";
      this.workLocation = "";
      this.startDate = null;
      this.endDate = null;
      this.standardActivities = [];
      this.risks = [];
      this.selectedApprovers = [];
      this.selectedPermitTemplate = {};
    },

    // Wizard state management
    setSelectActivityType(value) {
      this.selectActivityType = value;
    },

    setSelectedTemplate(template) {
      this.selectedTemplate = template;
      console.log("Selected Template", this.selectedTemplate);
    },

    setSelectedPreset(value) {
      this.selectedPreset = value;
    },

    setSelectedPeople(values) {
      this.selectedPeople = values;  // Replace the selectedPeople array with the new selection
    },
    
    setSelectedTMPlan(value) {
      this.selectedTMPlan = value;
    },

    setSelectedActivities(values) {
      this.selectedActivities = values;
    },

    setSelectedConflicts(values) {
      this.selectedConflicts = values;
    },

    setAdditionalFiles(files) {
      this.additionalFiles = files;
    },

    setProjectTitle(value) {
      this.projectTitle = value;
    },

    setApplicant(value) {
      this.applicant = value;
    },

    setRelatedTo(value) {
      this.description = value;
    },

    setQualificationsRequired(value) {
      this.qualificationsRequired = value;
    },

    setWorkLocation(value) {
      this.workLocation = value;
    },

    // Actions for Conflicts.vue
    setStartDate(value) {
      this.startDate = value;
    },
    
    setEndDate(value) {
      this.endDate = value;
    },

    // Actions for Activities.vue
    addActivity(activity) {
      if (!this.standardActivities.includes(activity)) {
        this.standardActivities.push(activity);
      }
    },

    addRisk(risk) {
      if (!this.risks.includes(risk)) {
        this.risks.push(risk);
      }
    },

    // Action to set approvers in Final.vue
    setSelectedApprovers(values) {
      this.selectedApprovers = values;
    },
  },
});
