import { defineStore } from 'pinia';
import TucumcariClient from "@/api/tucumcari";

export const useOrganisationStore = defineStore('organisation', {
  state: () => ({
    organisations: [],
    selectedOrganisation: "",
  }),
  getters: {
    allOrganisations: (state) => state.organisations,
  },
  actions: {
    async fetchOrganisations() {
      const client = new TucumcariClient();
      try {
        const organisations = await client.getOrganisations();
        const responseData = await organisations.response;
        const data = await responseData.body.json();
        console.log("API Response:", data);
        this.organisations = data;
      } catch (error) {
        console.error("Failed to fetch organisations:", error);
      }
    },
    logout() {
      this.organisations = [];
      this.selectedOrganisation = "";
    },
  },
});
