<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import { DataTable } from 'simple-datatables';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { usePeopleStore } from '@/stores/people';

const peopleStore = usePeopleStore();
const personnel = computed(() => peopleStore.allPeople);

const props = defineProps({
  filterByStatus: {
    type: String,
    required: true,
  },
});

const dataTable = ref(null);
const router = useRouter();
const sortedList = computed(() => {
  return personnel.value.slice().sort((a, b) => {
    const dateA = moment(a.CreatedOn, 'DD/MM/YYYY');
    const dateB = moment(b.CreatedOn, 'DD/MM/YYYY');
    return dateB - dateA;
  });
});

const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

const initDataTable = () => {
  // Check if the permits table exists
  const personnelTable = document.querySelector("#personnel");

  if (!personnelTable) {
    console.error("Personnel table not found. Make sure the DOM is rendered.");
    return; // Exit if table is not found
  }

  if (dataTable.value) {
    dataTable.value.destroy();
  }
  dataTable.value = new DataTable('#personnel', {
    searchable: true,
    fixedHeight: true,
  });

  const tableBody = document.querySelector('#personnel tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    const id = row.dataset.id;
    row.addEventListener('click', () => {
      router.push(`/person-profile/${id}`);
    });
  });
};

const filterTable = (status) => {
  initDataTable();
  const tableBody = document.querySelector('#personnel tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    const statusCell = row.querySelector('td:nth-child(1)');
    if (statusCell && statusCell.textContent.trim() !== status) {
      row.remove();
    }
  });
};

onMounted(async () => {
  initDataTable();
  filterTable(props.filterByStatus);
});

watch(() => props.filterByStatus, (newStatus) => {
  if (newStatus === 'All') {
    initDataTable();
  } else {
    filterTable(newStatus);
  }
});
</script>

<template>
  <div class="table-responsive">
    <table id="personnel" class="table table-flush">
      <thead class="thead-light">
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Company</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Role</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Reports To</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Start</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in sortedList" :key="item.ID" :data-id="item.ID">
          <td class="text-sm font-weight-normal">
            <button class="btn w-100 m-0 px-1 py-0 text-white" :class="{
              pending: item.Type == 'Contractor',
              waiting: item.Type == 'Port Personnel',
              draft: item.Type == 'System User',
            }">
              {{ item.Type }}
            </button>
          </td>
          <td class="text-sm font-weight-normal">{{ item.Name }}</td>
          <td class="text-sm font-weight-normal">{{ item.Company }}</td>
          <td class="text-sm font-weight-normal">{{ item.WorkRole }}</td>
          <td class="text-sm font-weight-normal">{{ item.ReportsTo }}</td>
          <td class="text-sm font-weight-normal">{{ formatDate(item.CreatedOn) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
tr {
  cursor: pointer;
}
</style>
