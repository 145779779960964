<script setup>
import { defineProps, onMounted, ref, watch } from 'vue';
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

const props = defineProps({
  tab: {
    type: String,
    required: true,
  },
  permit: {
    type: Object,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    required: true,
  },
});

// const date = ref(null);
// const fromDate = ref(props.permit.ApprovedStartTime || null);
// const toDate = ref(props.permit.ApprovedEndTime || null);
const fromDate = ref('10/10/2024 9:00AM');
const toDate = ref('11/10/2024 5:00PM');

const config = {
  allowInput: true,
  altInput: true,
  enableTime: true,
  // defaultDate: '2024-04-19T12:00:00'
  // minDate: props.permit.availableTimeExtent?.start, // Limit to available time range
  // maxDate: props.permit.availableTimeExtent?.end,
};

// Emit changes to the BaseMap.vue timeSlider
function emitDateChange() {
  const event = new CustomEvent('flatPickrDateChanged', {
    detail: {
      fromDate: fromDate.value,
      toDate: toDate.value,
    }
  });
  document.dispatchEvent(event);
}

// Watch for changes in fromDate or toDate and emit them
watch([fromDate, toDate], emitDateChange);

onMounted(() => {
  document.addEventListener('timeSliderUpdated', (event) => {
    fromDate.value = event.detail.fromDate;
    toDate.value = event.detail.toDate;
  });
});

watch(() => props.permit, (newPermit) => {
  console.log('Updated Permit: ', newPermit);
});
</script>

<template>
  <div v-if="tab === 'active'" class="p-3 pt-0 card-body mx-4 my-4">
    <div class="row mt-2">
      <h4>Permit</h4>
      <div class="col-12 col-sm-6">
        <MaterialInput
          id="title"
          label="Project"
          variant="static"
          class="mt-2 ms-0"
          :value="permit?.Description || permit?.Title || 'Confined spaces - Clearing our storm sewer manhole'"
          :disabled="isDisabled"
          @update:value="(val) => 'permit.layerName = val'"
        />
        <MaterialInput
          id="applicant"
          label="Applicant"
          variant="static"
          class="mt-4 ms-0"
          :value="permit?.Who || 'John Contractor'"
          :disabled="isDisabled"
          @update:value="(val) => 'permit.applicant = val'"
        />
        <div v-if="isDisabled">
          <label class="mt-4 ms-0">Conducting Work</label>
          <option class="disabledLink">
            <a href="/contractors" target="_blank">Ron - Gutter cleaner</a>
          </option>
        </div>
        <div v-show="!isDisabled">
          <label class="mt-3 ms-0">Conducting Work</label>
          <select id="people" ref="skills" multiple class="form-control" name="Status">
            <option value="Rufus Dogface">Rufus Dogface - Contractor</option>
            <option value="Bob Guy">Bob Guy</option>
            <option value="The Electrician">The Electrician - Contractor</option>
            <option value="Fun Time">Fun Times</option>
            <option value="Mary Onthestop">Mary Onthestop</option>
            <option value="Plumbers">Plumbers - Contractor</option>
            <option value="Those helpful guys">Those helpful guys - Contractor</option>
          </select>
        </div>
        <MaterialInput
          id="approver"
          label="Approver/Supervisor"
          variant="static"
          class="mt-4 ms-0"
          :value="permit?.Who || 'Paula - H&S Manager'"
          :disabled="isDisabled"
          @update:value="(val) => 'permit.approver = val'"
        />
        <div v-if="isDisabled">
          <label class="mt-4 ms-0">Locations</label>
          <option class="isDisabled">That Place</option>
        </div>
        <div v-show="!isDisabled">
          <label class="mt-3 ms-0">Locations</label>
          <select id="location-edit" ref="skills" multiple class="form-control" name="Locations">
            <option value="That Place" selected>Confined space A</option>
            <option value="Left Area">Left Area</option>
            <option value="The Bank">The Bank</option>
          </select>
        </div>
        <div v-if="isDisabled">
          <label class="mt-4 ms-0">Template</label>
          <option class="isDisabled">Confined Space</option>
        </div>
        <div v-show="!isDisabled">
          <label class="mt-4 ms-0">Template</label>
          <select id="permitType-edit" class="form-control" name="Status">
            <option value="Confined Space" selected>Confined Space</option>
            <option value="Bunkering">Bunkering</option>
            <option value="Corridor Access">Corridor Access</option>
            <option value="Crane and Heavy Lifts">Crane and Heavy Lifts</option>
            <option value="Dive Ops">Dive Ops</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <label class="mt-2 ms-0">Status</label>
        <button class="btn w-100 mb-2 p-0 text-white" :class="{
          pending: permit?.Status == 'Pending', // Pending
          waiting: permit?.Status == 'Awaiting approval', // Awaiting my approval
          draft: permit?.Status == 'Draft',
          cancelled: permit?.Status == 'Declined/cancelled',
          updated: permit?.Status == 'Updated',
        }">
          {{ permit?.Status || 'Awaiting approval'}}
        </button>
        <div>
          <label class="mt-4 ms-0">Logged</label>
          <option class="disabledLink">
            <a href="#">October 10, 2024 10.30AM</a>
          </option>
        </div>
        <!-- <label class="mt-3 ms-0">From</label>
        <div class="input-group input-group-static">
          <span class="input-group-text"><i class="fas fa-calendar"></i></span>
          <flat-pickr v-model="date" :disabled="isDisabled" class="form-control datetimepicker" :config="config" />
        </div>
        <label class="mt-3 ms-0">To</label>
        <div class="input-group input-group-static">
          <span class="input-group-text"><i class="fas fa-calendar"></i></span>
          <flat-pickr v-model="date" :disabled="isDisabled" class="form-control datetimepicker" :config="config" />
        </div> -->
        <label class="mt-3 ms-0">From</label>
      <div class="input-group input-group-static">
        <span class="input-group-text"><i class="fas fa-calendar"></i></span>
        <flat-pickr v-model="fromDate" :disabled="isDisabled" class="form-control datetimepicker" :config="config" />
      </div>

      <label class="mt-3 ms-0">To</label>
      <div class="input-group input-group-static">
        <span class="input-group-text"><i class="fas fa-calendar"></i></span>
        <flat-pickr v-model="toDate" :disabled="isDisabled" class="form-control datetimepicker" :config="config" />
      </div>
        <div>
          <label class="mt-4 ms-0">Approved On</label>
          <option class="disabledLink">Not yet approved</option>
        </div>
        <div v-if="isDisabled">
          <label class="mt-4 ms-0">Related to</label>
          <option class="disabledLink">
            <a href="#">None</a>
          </option>
        </div>
        <div v-show="!isDisabled">
          <label class="mt-4 ms-0">Type</label>
          <select id="related-edit" class="form-control" name="Related">
            <option value="Confined Space" selected>Site Work: Barrier Install</option>
            <option value="Bunkering">Hot Work: Barrier Install</option>
            <option value="Corridor Access">Excavation/Ground Penetration: Barrier Install</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <label class="mt-2 ms-0">Descriptions</label>
        <MaterialInput
          id="qualification"
          label="Qualifications Required"
          variant="static"
          class="mt-4 ms-0"
          :value="permit?.BunkeringMethod || '17599: Plan a confined space entry '"
          :disabled="isDisabled"
          @update:value="(val) => 'permit.inputs.qualification = val'"
        />
        <label class="mt-2 ms-0">Description</label>
        <MaterialTextarea
          id="description"
          variant="static"
          class="mt-2 ms-0"
          :value="permit?.Description || 'Post storm clean-out of debris. '"
          :disabled="isDisabled"
          @update:value="(val) => 'permit.inputs.description = val'"
        ></MaterialTextarea>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
