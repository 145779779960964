<script setup>
import { onMounted, inject } from 'vue';
import MaterialTextarea from '@/components/materialKit/MaterialTextarea.vue';
import Dropzone from 'dropzone';

const prevStep = inject('prevStep');

onMounted(() => {
  let myDropzone = new Dropzone('#addFiles');
  myDropzone.on('addedfile', (file) => {
    console.log(`File added: ${file.name}`);
  });
});
</script>

<template>
  <div
    class="pt-3 bg-white multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="mt-4 row text-start">
        <h4>Notes</h4>
        <div class="col-12 mb-4">
          <label class="form-control mb-0">Note</label>
          <material-textarea id="notes" variant="static" class="ms-0"></material-textarea>
        </div>
        <h4>Add Supporting files</h4>
        <div class="col-12 mb-4">
          <label class="form-control mb-0">Upload files</label>
          <div id="addFiles" action="/file-upload" class="form-control border dropzone"></div>
        </div>
      </div>
      <div class="mt-4 button-row d-flex">
        <button
          class="mb-0 btn bg-gradient-light js-btn-prev"
          type="button"
          title="Prev"
          @click="prevStep"
        >
          Prev
        </button>
        <div class="text-end ms-auto">
          <button
            class="mb-0 btn bg-gradient-dark"
            type="button"
            title="Send"
            @click="$router.push('/incidents')"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
