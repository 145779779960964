<script setup>
import Start from './components/Start.vue';
import Conflicts from './components/Conflicts.vue';
import Activities from './components/Activites.vue';
import Procedure from './components/Procedure.vue';
import Final from './components/Final.vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import MaterialSnackbar from "@/components/materialKit/MaterialSnackbar.vue";
import { useRouter } from 'vue-router';
import { usePermitsStore } from '@/stores/permits';
import { useTemplateStore } from '@/stores/template';
import { usePeopleStore } from '@/stores/people';

// Import the store
const permitsStore = usePermitsStore();
const templateStore = useTemplateStore();
const peopleStore = usePeopleStore();
const router = useRouter();

// Reactive variable to control the snackbar
const snackbar = ref({
  visible: false,
  title: "",
  description: "",
  icon: { component: "check_circle", color: "success" },
  color: "success",
  date: new Date().toLocaleString(),
});

// Method to show the snackbar
const showSnackbar = (type) => {
  snackbar.value.visible = true;
  snackbar.value.date = new Date().toLocaleString();

  if (type === "draft") {
    snackbar.value.title = "Draft Saved";
    snackbar.value.description = "Your draft has been successfully saved!";
    snackbar.value.icon.component = "save";
    snackbar.value.icon.color = "info";
    snackbar.value.color = "info";
  } else if (type === "save") {
    snackbar.value.title = "Permit Saved";
    snackbar.value.description = "Your permit has been successfully saved!";
    snackbar.value.icon.component = "check_circle";
    snackbar.value.icon.color = "success";
    snackbar.value.color = "success";
  }

  // Automatically hide the snackbar after 3 seconds
  setTimeout(() => {
    snackbar.value.visible = false;
    router.push('/activity-list'); // Navigate to activity-list after snackbar disappears
  }, 3000);
};

// Initialize data on component mount
onMounted(async () => {
  await templateStore.fetchTemplates();
  await peopleStore.fetchPeople();

  // Ensure that permitsStore has an activeStep value, default to 0 if undefined
  if (permitsStore.activeStep === undefined || permitsStore.activeStep === null) {
    permitsStore.activeStep = 0;
  }
});

// Reset form on unmount
onBeforeUnmount(() => permitsStore.resetForm());
</script>

<template>
  <div id="top" class="py-4 h-100 container-fluid">
    <div class="row">
      <h4>New Permit / Site Works</h4>
      <div class="text-center col-12">
        <div class="mb-5 multisteps-form">
          <!--progress bar-->
          <div class="row">
            <div class="mx-auto my-5 col-12 col-lg-8">
              <div class="card">
                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div class="bg-gradient-info shadow-info border-radius-lg pt-4 pb-3">
                    <div class="multisteps-form__progress">
                      <button class="multisteps-form__progress-btn js-active" type="button" title="Start"
                        :class="permitsStore.activeStep >= 0 ? 'js-active position-relative' : ''"
                        @click="permitsStore.activeStep = 0">
                        <span>Start</span>
                      </button>
                      <button class="multisteps-form__progress-btn" type="button" title="Check"
                        :class="permitsStore.activeStep >= 1 ? 'js-active position-relative' : ''"
                        @click="permitsStore.activeStep = 1">
                        <span>Check</span>
                      </button>
                      <button class="multisteps-form__progress-btn" type="button" title="Activities & Risk"
                        :class="permitsStore.activeStep >= 2 ? 'js-active position-relative' : ''"
                        @click="permitsStore.activeStep = 2">
                        <span>Activities & Risk</span>
                      </button>
                      <button class="multisteps-form__progress-btn" type="button" title="Procedure"
                        :class="permitsStore.activeStep >= 3 ? 'js-active position-relative' : ''"
                        @click="permitsStore.activeStep = 3">
                        <span>Procedure</span>
                      </button>
                      <button class="multisteps-form__progress-btn" type="button" title="Finalise"
                        :class="permitsStore.activeStep >= 4 ? 'js-active position-relative' : ''"
                        @click="permitsStore.activeStep = 4">
                        <span>Finalise</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <form class="multisteps-form__form">
                    <!-- All steps are mounted but only visible when active -->
                    <Start v-if="permitsStore.activeStep === 0" />
                    <Conflicts v-show="permitsStore.activeStep === 1" />
                    <Activities v-show="permitsStore.activeStep === 2" />
                    <Procedure v-show="permitsStore.activeStep === 3" />
                    <Final v-show="permitsStore.activeStep === 4" />
                  </form>
                </div>

                <div class="row">
                  <div class="card-footer text-end">
                    <div class="mt-4 button-row d-flex col-12">
                      <button v-if="permitsStore.activeStep > 0" class="mb-0 btn bg-gradient-light js-btn-prev"
                        type="button" title="Prev" @click="permitsStore.prevStep">Prev</button>

                      <button v-if="permitsStore.activeStep < permitsStore.formSteps"
                        class="mb-0 btn bg-gradient-dark ms-auto js-btn-next" type="button" title="Next"
                        @click="permitsStore.nextStep">Next</button>

                      <div v-if="permitsStore.activeStep === 4" class="text-end ms-auto">
                        <button class="mb-0 btn bg-gradient-primary me-4" type="button" title="Save as draft"
                          @click="showSnackbar('draft')">Save as draft</button>
                        <button class="mb-0 btn bg-gradient-dark" type="button" title="Save"
                          @click="showSnackbar('save')">Save</button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Material Snackbar Toast -->
                <MaterialSnackbar v-if="snackbar.visible" :title="snackbar.title" :description="snackbar.description"
                  :icon="snackbar.icon" :color="snackbar.color" :date="snackbar.date"
                  :closeHandler="() => { snackbar.visible = false; }" class="snackbar-top-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Snackbar positioned at the top-right corner */
.snackbar-top-right {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1050; /* Ensure it appears above other content */
  width: auto; /* Adjust width if necessary */
}
</style>
