<script setup>
import { defineProps, onMounted } from 'vue';
import TimelineList from "@/components/cards/TimelineList.vue";
import TimelineItem from "@/components/cards/TimelineItem.vue";
import { useRoute, useRouter } from 'vue-router';

// Define props using Composition API
const props = defineProps({
  tab: {
    type: String,
    required: true,
  },
});

const route = useRoute();
const router = useRouter();
const page = route.name;

onMounted(() => {
  console.log('Route', route);
  console.log('Router', router);
  console.log('Page name', page);
})
</script>

<template>
  <div v-show="props.tab === 'timeline'" class="p-3 pb-0 card-body">
    <div v-if="route.name == 'Incident'">
      <div class="mt-3 row">
        <h4>Timeline</h4>
        <timeline-list title="Timeline">
          <TimelineItem color="primary" :icon="{ component: 'dataset_linked', class: 'text-white text-sm' }"
            title="Follow up actions added" date-time="11 October 4:54 AM"
            description="Follow up actions added to this incident" />
          <TimelineItem color="success" :icon="{ component: 'done', class: 'text-white text-sm' }"
            title="Incident sent for review" date-time="4 October 12:30 PM"
            description="Sent to safety agent to review" />
          <TimelineItem color="info" :icon="{ component: 'flag', class: 'text-white text-sm' }" title="Incident updated"
            date-time="3 October 10:30 AM" description="Follow up actions and other fields saved" />
          <TimelineItem color="info" :icon="{ component: 'flag', class: 'text-white text-sm' }"
            title="Incident first reported" date-time="1 October 2:30 PM"
            description="Injury occurred while clearing out debris." />
        </timeline-list>
      </div>
    </div>
    <div v-else>
      <div class="mt-3 row">
        <h4>Timeline</h4>
        <timeline-list title="Timeline">
          <TimelineItem color="primary" :icon="{ component: 'dataset_linked', class: 'text-white text-sm' }"
            title="Follow up actions added" date-time="31 July 4:54 AM"
            description="Follow up actions added to this hazard" />
          <TimelineItem color="success" :icon="{ component: 'done', class: 'text-white text-sm' }"
            title="Hazard sent for review" date-time="26 July 12:30 PM"
            description="Sent to safety agent to review" />
          <TimelineItem color="info" :icon="{ component: 'flag', class: 'text-white text-sm' }" title="Hazard updated"
            date-time="20 July 10:30 AM" description="Follow up actions." />
          <TimelineItem color="info" :icon="{ component: 'flag', class: 'text-white text-sm' }"
            title="Hazard first reported" date-time="19 July 2:30 PM"
            description="Pot hole on the left area by the water." />
        </timeline-list>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
