<template>
    <div v-show="tab === 'extra'" class="p-3 pb-0 card-body">
      <div class="mt-3 row">
        <h4>Notes</h4>
        <div class="col-12 mb-4">
          <label class="form-control mb-0">Note</label>
          <material-textarea id="notes" variant="static" class="ms-0" :disabled="isDisabled"></material-textarea>
        </div>
        <h4>Supporting files</h4>
        <div v-for="(incidentFile, index) in incidentFiles" :key="index" class="col-3">
          <div class="text-center fileBlock mt-2">
            <a :href="incidentFile.link" target="_blank">
              <span class="material-icons">{{ incidentFile.icon }}</span>
              <p class="text-sm">{{ incidentFile.fileName }}</p>
            </a>
          </div>
        </div>
        <div class="col-12 mb-4">
          <label class="form-control mb-0">Upload files</label>
          <div id="incidentFiles" action="/file-upload" class="form-control border dropzone"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
  
  export default {
    name: "ExtraTab",
    components: { MaterialTextarea },
    props: {
      tab: {
        type: String,
        required: true,
      },
      incidentFiles: {
        type: Array,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .fileBlock {
    border-radius: 10px;
    border: solid 1px #ebebeb;
    padding: 10px;
  }
  </style>
  