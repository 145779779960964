<template>
  <div class="py-4 container-fluid">
    <div class="row mb-2">
      <div class="col-lg-4">
        <h4>{{ data.inputs.title }}</h4>
      </div>
      <div class="col-lg-8 text-end">
        <button class="btn bg-gradient-primary mb-0 toast-btn me-2" type="button" @click="toggleDisabled">Edit</button>
        <button class="btn bg-gradient-info mb-0 toast-btn me-2" type="button" data-bs-toggle="modal" data-bs-target="#mergeModal">Merge</button>
        <button class="btn bg-gradient-dark mb-0 toast-btn me-2" type="button" data-bs-toggle="modal" data-bs-target="#closeModal">Close</button>
        <router-link to="/hazards">
          <MaterialButton variant="gradient" color="warning">Hazards List</MaterialButton>
        </router-link>
      </div>
    </div>

    <!-- Modals -->
    <MergeModal />
    <CloseModal />

    <div class="row">
      <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true" @click="tab = 'active'">
              <span class="ms-1">Summary</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true" @click="tab = 'reports'">
              <span class="ms-1">Reports</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true" @click="tab = 'timeline'">
              <span class="ms-1">Timeline</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true" @click="tab = 'followup'">
              <span class="ms-1">Follow Up Actions</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false" @click="tab = 'extra'">
              <span class="ms-1">Notes & Attachments</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true" @click="tab = 'contacts'">
              <span class="ms-1">Linked Contacts</span>
            </a>
          </li>
        </ul>
      </div>

      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <SummaryTab :tab="tab" :data="data" :isDisabled="isDisabled" />
          <ReportsTab :tab="tab" :reports="reports" />
          <TimelineTab :tab="tab" />
          <FollowUpTab :tab="tab" :data="data" :isDisabled="isDisabled" />
          <ExtraTab :tab="tab" :incidentFiles="incidentFiles" :isDisabled="isDisabled" />
          <ContactsTab :tab="tab" :contacts="hazardContacts" :isDisabled="isDisabled" />
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="mt-4 border-radius-2">
          <base-map></base-map>
        </div>
      </div>
    </div>

    <div v-if="!isDisabled" class="text-end mb-4">
      <button class="btn bg-gradient-info mb-0 toast-btn me-2" type="button" @click="toggleDisabled">Save</button>
    </div>
  </div>
</template>

<script>
import baseMap from "@/components/maps/baseMap.vue";
import Dropzone from "dropzone";
import Choices from "choices.js";
import setNavPills from "@/assets/js/nav-pills.js";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";

import MergeModal from "./modal/MergeModal.vue";
import CloseModal from "./modal/CloseModal.vue";

import SummaryTab from "./tab/SummaryTab.vue";
import ReportsTab from "./tab/ReportsTab.vue";
import TimelineTab from "./tab/TimelineTab.vue";
import FollowUpTab from "./tab/FollowUpTab.vue";
import ExtraTab from "./tab/ExtraTab.vue";
import ContactsTab from "./tab/ContactsTab.vue";

export default {
  name: "Hazard",
  components: {
    baseMap,
    MaterialButton,
    MergeModal,
    CloseModal,
    SummaryTab,
    ReportsTab,
    TimelineTab,
    FollowUpTab,
    ExtraTab,
    ContactsTab,
  },
  data() {
    return {
      tab: "active",
      isDisabled: true,
      data: {
        inputs: {
          title: "Pot Hole",
          location: "Left area by the water",
          reviewer: "Paula - H&S Advisor",
          lastUpdated: "July 31, 2024 10.30AM",
          incidentOccurred: "July 19, 2024 2:30PM",
          updatedBy: "Jane Contractor",
          incidents: "",
          status: "Open Hazard",
          asset: "",
          description: "Pot hole on the left area by the water",
          id: 123456,
          action: "Added pot hole as a hazard, booked to get repaired. Most likely will happen Wednesday",
        },
      },
      reports: [
        {
          reported: "Jane Contractor",
          when: "19/07/2024 2.30PM",
          fromStatus: "New",
          title: "Pot hole",
          descipt: "A person was injured because of the pot hole",
          supportImages: [
            require("@/assets/img/potHole.jpg"),
          ],
        },
      ],
      incidentFiles: [],
      hazardContacts: [
      {
          mainContact: "John Contractor",
          company: "Manhole Services",
          phone: "021 123456",
          email: "johncontractor@test.com",
          linked: "None",
        },
        {
          mainContact: "Chris Port",
          company: "The Port",
          phone: "021 123456",
          email: "chrisport@test.com",
          linked: "None",
        },
      ],
    };
  },
  mounted() {
    setNavPills();
    this.getChoices("location-edit");

    if (document.getElementById("asset-edit")) {
      var asset = document.getElementById("asset-edit");
      new Choices(asset, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }

    if (document.getElementById("hazard-edit")) {
      var hazard = document.getElementById("hazard-edit");
      new Choices(hazard, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }

    let myDropzone = new Dropzone("#incidentFiles");
    myDropzone.on("addedfile", (file) => {
      console.log(`File added: ${file.name}`);
    });
  },
  methods: {
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: true,
          allowHTML: true,
        });
      }
    },
    toggleDisabled() {
      this.isDisabled = !this.isDisabled;
    },
  },
};
</script>
