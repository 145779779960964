<template>
  <div class="py-4 container-fluid">
    <div class="row mb-2">
      <div class="col-lg-4">
        <h4>{{ data.inputs.title }}</h4>
      </div>
      <div class="col-lg-8 text-end">
        <button class="btn bg-gradient-primary mb-0 toast-btn me-2" type="button" @click="toggleDisabled">Edit</button>
        <button class="btn bg-gradient-dark mb-0 toast-btn me-2" type="button" data-bs-toggle="modal" data-bs-target="#archiveModal">Archive</button>
        <router-link to="/asset-management">
          <MaterialButton variant="gradient" color="warning">Registry</MaterialButton>
        </router-link>
        <ArchiveModal />
      </div>
    </div>
    <div class="row">
      <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true" @click="tab = 'summary'"><span class="ms-1">Summary</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true" @click="tab = 'locations'"><span class="ms-1">Locations</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true" @click="tab = 'servicing'"><span class="ms-1">Servicing & Contacts</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true" @click="tab = 'related'"><span class="ms-1">Related Incidents / Hazards</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true" @click="tab = 'custom'"><span class="ms-1">Custom Integrations</span></a>
          </li>
        </ul>
      </div>
      <div class="col-12">
        <div class="card mt-4">
          <SummaryTab v-show="tab === 'summary'" :data="data" :isDisabled="isDisabled" />
          <LocationsTab v-show="tab === 'locations'" />
          <ServicingTab v-show="tab === 'servicing'" :services="services" :contacts="incidentContacts" :isDisabled="isDisabled" />
          <RelatedTab v-show="tab === 'related'" :safety="safety" />
          <CustomTab v-show="tab === 'custom'" :integration="data.inputs.action" :isDisabled="isDisabled" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArchiveModal from "./modal/ArchiveModal.vue";
import SummaryTab from "./tab/SummaryTab.vue";
import LocationsTab from "./tab/LocationsTab.vue";
import ServicingTab from "./tab/ServicingTab.vue";
import RelatedTab from "./tab/RelatedTab.vue";
import CustomTab from "./tab/CustomTab.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";

export default {
  name: "Asset",
  components: {
    ArchiveModal,
    SummaryTab,
    LocationsTab,
    ServicingTab,
    RelatedTab,
    CustomTab,
    MaterialButton,
  },
  data() {
    return {
      tab: "summary",
      isDisabled: true,
      data: {
        inputs: {
          title: "Forklift",
          location: "Area 52",
          make: "LiuGong 3.5Ton Diesel Engine",
          updatedBy: "Gary Cat",
          hazards: "Pot hole",
          asset: "Forklift",
          description: "Rated capacity: 3500kg, Load center: 500mm, Unladen mass:4900kg, Maximum lift height:4500mm, Tyres type: solid tyre",
          id: 123456,
          action: "No integrations added",
          nextService: "",
          booked: "not yet booked",
          warranty: "out of date",
          licence: "Forklift licence required",
          purchased: "Bob's Forklifts",
        },
      },
      safety: [
        {
          occurDate: "24/04/2024",
          reportDate: "24/04/2024",
          title: "Forklift accident",
          location: "Area 52",
          status: "In Review",
          type: "Hazard",
          id: 123456,
        },
        {
          occurDate: "04/04/2024",
          reportDate: "05/04/2024",
          title: "Crane Malfunction",
          location: "The left side",
          status: "Closed",
          type: "Hazard",
          id: 123456,
        },
        {
          occurDate: "12/04/2024",
          reportDate: "12/04/2024",
          title: "Forklift accident",
          location: "Area 52",
          status: "Open Incident",
          type: "Incident",
          id: 123456,
        },
        {
          occurDate: "30/04/2024",
          reportDate: "30/04/2024",
          title: "Crane Malfunction",
          location: "The left side",
          status: "New",
          type: "Incident",
          id: 123456,
        },
      ],
      services: [
        {
          bookedBy: "Gary Cat",
          when: "12/04/2023",
          due: "24/02/2023",
          note: "Watch out for bald tyres",
        },
        {
          bookedBy: "Gary Cat",
          when: "12/04/2022",
          due: "24/02/2022",
          note: "Passed",
        },
        {
          bookedBy: "Gary Cat",
          when: "12/04/2021",
          due: "24/02/2021",
          note: "Passed",
        },
      ],
      incidentContacts: [
        {
          mainContact: "Jeff Bat : Forklift Servicing",
          company: "Forklift Wheels",
          phone: "021 123456",
          email: "forklift@test.com",
          linked: "For Repairs",
        },
        {
          mainContact: "Gary Cat : Responsible for Asset",
          company: "Port Personal",
          phone: "021 123456",
          email: "port@test.com",
          linked: "Reported Incident",
        },
      ],
    };
  },
  mounted() {
    setNavPills();
  },
  methods: {
    toggleDisabled() {
      this.isDisabled = !this.isDisabled;
    },
  },
};
</script>
