import { defineStore } from "pinia";
import TucumcariClient from "@/api/tucumcari";
import { getMockIncidents } from "@/services/mockData";

export const useIncidentStore = defineStore("incidents", {
  state: () => ({
    incidents: [],
  }),
  getters: {
    allIncidents(state) {
      return state.incidents;
    },
  },
  actions: {
    async fetchMockIncidents() {
      const incidents = await getMockIncidents();
      this.incidents = incidents;
    },
    async fetchIncidents() {
      const client = new TucumcariClient();
      try {
        const incidents = await client.getAllIncident();
        console.log("API Response:", incidents); // Log the incidents data
        //this.incidents = incidents;
      } catch (error) {
        console.error("Failed to fetch incidents:", error);
      }
    },
  },
});
