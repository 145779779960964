<script setup>
import { ref, onMounted } from 'vue';
import BaseMap from "@/components/maps/baseMap.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import { hasPermission } from '@/utils/checkPermission';
// import { useArcGISStore } from '@/stores/arcgisStore'

// Reactive state for the selected tab
const tab = ref('active');
// const arcgisStore = useArcGISStore();

// Initialize nav pills on component mount
onMounted(() => {
  setNavPills();
});
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-4">
        <h4 class="m-3">Active Work</h4>
      </div>
      <div class="col-8 text-end">
        <!-- Navigation buttons -->
        <router-link to="/activity-list" class="me-2">
          <MaterialButton variant="gradient" color="info">
            Permits & Site Works List
          </MaterialButton>
        </router-link>
        <router-link to="/add-activity" class="me-2">
          <MaterialButton variant="gradient" color="info">
            New Permit/Site Works
          </MaterialButton>
        </router-link>
        <router-link to="/hazards" class="me-2">
          <MaterialButton v-if="hasPermission('canFilter')" variant="gradient" color="warning">
            Hazards List
          </MaterialButton>
        </router-link>
        <router-link to="/incidents">
          <MaterialButton v-if="hasPermission('canFilter')" variant="gradient" color="danger">
            Incident List
          </MaterialButton>
        </router-link>
      </div>

      <!-- Navigation pills for different tabs -->
      <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1 active"
              href="javascript:;"
              role="tab"
              @click="tab = 'active'"
            >
              <i class="material-icons text-lg position-relative">my_location</i>
              <span class="ms-1">Active work/permits</span>
            </a>
          </li>
          <li v-if="hasPermission('canFilter')" class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              href="javascript:;"
              role="tab"
              @click="tab = 'approval'"
            >
              <i class="material-icons text-lg position-relative">check_circle</i>
              <span class="ms-1">Permits needing approval</span>
            </a>
          </li>
          <li v-if="hasPermission('canFilter')" class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              href="javascript:;"
              role="tab"
              @click="tab = 'recent'"
            >
              <i class="material-icons text-lg position-relative">timer</i>
              <span class="ms-1">Recent incidents</span>
            </a>
          </li>
          <li v-if="hasPermission('canFilter')" class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              href="javascript:;"
              role="tab"
              @click="tab = 'open'"
            >
              <i class="material-icons text-lg position-relative">list_alt</i>
              <span class="ms-1">Open incidents</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              href="javascript:;"
              role="tab"
              @click="tab = 'hazards'"
            >
              <i class="material-icons text-lg position-relative">support</i>
              <span class="ms-1">All hazards</span>
            </a>
          </li>
          <li v-if="hasPermission('canFilter')" class="nav-item">
            <a
              class="nav-link mb-0 px-0 py-1"
              href="javascript:;"
              role="tab"
              @click="tab = 'managed'"
            >
              <i class="material-icons text-lg position-relative">analytics</i>
              <span class="ms-1">Managed hazards</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Card for displaying tab content -->
    <div class="card mt-4">
      <div class="row">
        <!-- Display the active map -->
        <div class="col-12">
          <BaseMap :mapType="'PlanningView'" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Custom styles specific to this page */
.nav-wrapper {
  padding-top: 20px;
}
</style>
