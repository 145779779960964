<template>
  <div class="mapContainer" style="width:100%; height:90vh; display:block;position:relative; border-radius:10px;">
    <div id="mapViewDiv"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, onBeforeUnmount, watch } from 'vue';
import WebMap from "@arcgis/core/WebMap.js";
import config from "@arcgis/core/config.js";
import MapView from "@arcgis/core/views/MapView.js";
import Query from '@arcgis/core/rest/support/Query.js';
import TimeSlider from "@arcgis/core/widgets/TimeSlider.js";
import TimeExtent from "@arcgis/core/TimeExtent.js";
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils.js';
import Legend from '@arcgis/core/widgets/Legend.js';
import Sketch from '@arcgis/core/widgets/Sketch.js';
// import Graphic from "@arcgis/core/Graphic.js";
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer.js';
import "@arcgis/core/assets/esri/themes/light/main.css";
import { DateTime } from "luxon";
import { useArcGISStore } from '@/stores/arcgisStore';
import { useOrganisationStore } from "@/stores/organisation";

const arcgisStore = useArcGISStore();
const organisationStore = useOrganisationStore();
const props = defineProps({
  mapType: {
    type: String,
    required: true
  },
  selectedPermit: {
    type: Object,
    required: false,
    default: null,
  },
  canDraw: {
    type: Boolean,
    required: false,
    default: false,
  }
});

let mapView;
let pamFeatureLayer;
// let confinedSpaceLayer;
let highlightHandle;
let timeSlider;
let sketchWidget;
let graphicsLayer;

const isUpdating = ref(false);
const isLegendVisible = ref(false);
const loadingMaps = ref(false);
const mapMode = ref(props.mapType);

onMounted(async () => {
  await nextTick();
  document.addEventListener('flatPickrDateChanged', (event) => {
    if (!isUpdating.value && timeSlider) { // Ensure timeSlider is initialized before using it
      isUpdating.value = true; // Set flag before updating timeSlider

      const { fromDate, toDate } = event.detail;

      // Use Luxon to parse the flatpickr date string format 'dd-MM-yyyy HH:mm'
      const parsedFromDate = DateTime.fromFormat(fromDate, 'dd-MM-yyyy HH:mm', { zone: 'local' });
      const parsedToDate = DateTime.fromFormat(toDate, 'dd-MM-yyyy HH:mm', { zone: 'local' });

      // Convert to ISO format (what the TimeSlider expects)
      const formattedFromDate = parsedFromDate.toISO();
      const formattedToDate = parsedToDate.toISO();

      // Update the timeSlider's timeExtent with the new dates from flatpickr
      if (timeSlider) { // Double-check timeSlider exists before accessing
        timeSlider.timeExtent = new TimeExtent({
          start: new Date(formattedFromDate),
          end: new Date(formattedToDate)
        });
      }

      // Reset the isUpdating flag after a short delay
      setTimeout(() => {
        isUpdating.value = false;
      }, 100);
    }
  });

  // Proceed with map initialization
  mapMode.value = props.mapType;
  // isLegendVisible.value = true;
  await initializeMap();
});

// Ensure to remove the event listener when component is unmounted to avoid memory leaks
onBeforeUnmount(() => {
  document.removeEventListener('flatPickrDateChanged', () => { });
});

watch(() => props.mapType, async (newMapType) => {
  mapMode.value = newMapType;
  if (mapMode.value === 'PlanningView') {
    await nextTick();
    addTimeSliderContainer();
    addLegendContainer();
    setupTimeSlider();
    addLegend();
  } else {
    if (timeSlider) {
      removeTimeSliderContainer();
      timeSlider.destroy();
      timeSlider = null;
    }
    await initializeMap();
  }
});

async function initializeMap() {
  loadingMaps.value = true;
  config.apiKey = arcgisStore.accessToken;

  const webMap = new WebMap({
    portalItem: {
      id: organisationStore.selectedOrganisation,
    }
  });

  mapView = new MapView({
    map: webMap,
    container: "mapViewDiv",
    padding: {
      left: 49
    }
  });

  graphicsLayer = new GraphicsLayer();
  mapView.map.add(graphicsLayer);

  setupMenuPanelAndMapControls();

  console.log('Items', webMap.portalItem);
  console.log('Items', webMap.allLayers.items);

  await webMap.when(async () => {
    if (mapMode.value === 'PlanningView') {
      await nextTick();
      addTimeSliderContainer();
      addLegendContainer();
      setupTimeSlider();
      addLegend();
      if (props.canDraw) {
        setupSketch();
      }
    } else {

      if (timeSlider) {
         removeTimeSliderContainer();
         timeSlider.destroy();
         timeSlider = null;
       }

      const workItemsGroupLayer = webMap.allLayers.find(layer => layer.title === 'Work Items');
      // const confinedSpaces = webMap.allLayers.find(layer => layer.title === 'Confined Spaces');

      if (workItemsGroupLayer) {
        pamFeatureLayer = workItemsGroupLayer.layers.find(layer => layer.title === props.selectedPermit?.layerName);
        if (!pamFeatureLayer) {
          console.error('Sublayer not found within Work Items group layer');
          return;
        }
      } else {
        console.error('Work Items group layer not found!');
        return;
      }
      // if (confinedSpaces) {
      //   console.log('Confined Space:', confinedSpaces.layers);
      //   confinedSpaceLayer = confinedSpaces.layers.find(layer => layer.title === props.selectedPermit?.layerName);
      //   if (!confinedSpaceLayer) {
      //     console.error('Sublayer not found within Work Items group layer');
      //     return;
      //   }
      // } else {
      //   console.error('Work Items group layer not found!');
      //   return;
      // }


      try {
        const layerView = await mapView.whenLayerView(pamFeatureLayer);
        await reactiveUtils.whenOnce(() => !layerView.updating);
        console.log("LayerView is ready for highlighting.");

        if (props.selectedPermit) {
          highlightAndCenterOnPermit(props.selectedPermit, layerView);
        }
      } catch (error) {
        console.error("Error loading layer view or updating:", error);
      }
    }
  });

  loadingMaps.value = false;
}

async function addLegendContainer() {
  const existingWrapper = document.getElementById('legend-wrapper');

  if (!existingWrapper) {
    // Create a wrapper that holds both the toggle button and the legend container
    const mapContainer = document.querySelector('.mapContainer');
    if (mapContainer) {
      const wrapper = document.createElement('div');
      wrapper.id = 'legend-wrapper';
      wrapper.style.position = 'absolute';
      wrapper.style.top = '50px';
      wrapper.style.right = '10px';
      wrapper.style.width = '250px';
      wrapper.style.height = 'auto';  // Make the wrapper height dynamic
      wrapper.style.minHeight = '50px';
      wrapper.style.maxHeight = '350px';
      wrapper.style.backgroundColor = 'transparent';
      wrapper.style.padding = '10px';
      wrapper.style.borderRadius = '5px';

      // Add the toggle button outside the legend container, but inside the wrapper
      const toggleButton = document.createElement('button');
      toggleButton.style.position = 'absolute';
      toggleButton.style.top = '-5px';
      toggleButton.style.right = '5px';
      toggleButton.style.background = 'none';
      toggleButton.style.color = 'white';
      toggleButton.style.border = 'none';
      toggleButton.style.fontSize = '30px';
      toggleButton.style.cursor = 'pointer';
      toggleButton.innerHTML = '<i class="fas fa-times-circle"></i>';
      toggleButton.title = "Hide Legend";
      toggleButton.type = "button"

      // Toggle functionality for the button
      toggleButton.onclick = function () {
        toggleLegend(); // Call the toggle function to hide/show the legend container
      };

      // Append the toggle button to the wrapper
      wrapper.appendChild(toggleButton);

      // Create the legend container that will be hidden/shown
      const legendContainer = document.createElement('div');
      legendContainer.id = 'legend-container';
      legendContainer.style.width = '100%';
      legendContainer.style.height = '300px';
      legendContainer.style.marginTop = '25px';  // Add some space from the button
      legendContainer.style.overflowY = 'auto';

      // Append the legend container to the wrapper
      wrapper.appendChild(legendContainer);

      // Append the wrapper to the map container
      mapContainer.appendChild(wrapper);
      console.log("Legend wrapper and container dynamically added.");
    } else {
      console.error('mapContainer not found');
    }
  } else {
    console.log('Legend wrapper already exists.');
  }
}

// Dynamically add the Legend
async function addLegend() {

  const legendContainer = document.getElementById('legend-container');
  const toggleButton = document.getElementById('legend-wrapper')?.querySelector('button');

  if (legendContainer && mapView && mapView.map && mapView.map.layers.length > 0) {
    // Only add legend if the map and layers are ready
    new Legend({
      view: mapView,
      container: legendContainer,
    });

    // Handle legend visibility based on canDraw
    if (props.canDraw) {
      legendContainer.style.display = 'block';  // Show the legend container
      isLegendVisible.value = true;  // Set visibility to true

      // Update the icon to fa-times-circle since legend is visible
      if (toggleButton) {
        toggleButton.innerHTML = '<i class="fas fa-times-circle"></i>';
        toggleButton.title = "Hide Legend";
      }
    } else {
      legendContainer.style.display = 'none';  // Hide the legend container
      isLegendVisible.value = false;  // Set visibility to false

      // Update the icon to fa-info-circle since legend is hidden
      if (toggleButton) {
        toggleButton.innerHTML = '<i class="fas fa-info-circle"></i>';
        toggleButton.title = "Show Legend";
      }
    }
    console.log("Legend successfully added to the map.");
  } else {
    console.error("Map or map layers are not ready for the Legend.");
  }
}

function toggleLegend() {
  const legendContainer = document.getElementById('legend-container');
  const toggleButton = document.getElementById('legend-wrapper')?.querySelector('button');

  if (legendContainer) {
    isLegendVisible.value = !isLegendVisible.value;
    legendContainer.style.display = isLegendVisible.value == true ? 'block' : 'none';  // Toggle only the content

    // Update button icon and title based on visibility
    if (isLegendVisible.value) {
      toggleButton.innerHTML = '<i class="fas fa-times-circle"></i>';  // Change to close icon
      toggleButton.title = "Hide Legend";
    } else {
      toggleButton.innerHTML = '<i class="fas fa-info-circle"></i>';  // Change to open icon
      toggleButton.title = "Show Legend";
    }
  } else {
    console.error("Legend container not found");
  }
}

// Setup Sketch but don't start drawing
function setupSketch() {
  if (!mapView || !graphicsLayer) {
    console.error("MapView or GraphicsLayer is not initialized for sketch setup");
    return;
  }
  sketchWidget = new Sketch({
    layer: graphicsLayer,  // Use the GraphicsLayer here
    view: mapView,
    creationMode: "update",
    visibleElements: {
      settingsMenu: false,
      createTools: {
        "point": true,
        "polyline": true,
        "polygon": true
      }
    }
  });

  mapView.ui.add(sketchWidget, "top-left");

  // Event listener for the sketch widget
  sketchWidget.on("create", function (event) {
    if (event.state === "complete") {
      console.log("Shape created:", event.graphic.geometry);
      // The graphic will automatically be added to the graphics layer
    }
  });
}

function highlightAndCenterOnPermit(permit, layerView) {
  if (!pamFeatureLayer) return;

  if (highlightHandle) {
    highlightHandle.remove();
  }

  const query = new Query();
  query.where = `OBJECTID=${permit.OBJECTID}`;
  query.returnGeometry = true;
  query.outFields = ["*"];

  pamFeatureLayer.queryFeatures(query).then((result) => {
    const feature = result.features[0];
    if (feature) {
      console.log("Feature found:", feature);

      highlightHandle = layerView.highlight([feature.getObjectId()]);

      mapView.goTo({
        target: feature.geometry,
        zoom: 18
      }, {
        duration: 2000,
        easing: "in-out-expo"
      }).catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      });
    } else {
      console.error("No features found with the specified OBJECTID.");
    }
  }).catch((error) => {
    console.error('Error querying features:', error);
  });
}

function setupTimeSlider() {
  const timeSliderContainer = document.getElementById('timeSlider-container');
  if (!timeSliderContainer) {
    console.error('TimeSlider container not found in the DOM');
    return;
  }

  const availableTimeExtent = new TimeExtent({
    start: DateTime.now().minus({ days: 1 }).startOf('day').toJSDate(),
    end: DateTime.now().plus({ days: 10 }).endOf('day').toJSDate()
  });

  const defaultTimeExtent = new TimeExtent({
    start: DateTime.now().plus({ hours: 4 }).toJSDate(),
    end: DateTime.now().plus({ hours: 5 }).toJSDate()
  });

  timeSlider = new TimeSlider({
    container: "timeSlider-container",
    mode: "time-window",
    layout: 'wide',
    view: mapView,
    timeVisible: true,
    timeExtent: defaultTimeExtent,
    fullTimeExtent: availableTimeExtent,
    stops: {
      interval: {
        value: 1,
        unit: "hours"
      }
    },
    tickConfigs: [{
      mode: "count",
      values: 21
    },
    {
      labelsVisible: true,
      mode: "count",
      values: 6,
      labelFormatFunction: (value) => {
        const dateLabel = DateTime.fromMillis(value);
        return `${dateLabel.toLocaleString({ month: 'short', day: 'numeric' })}`;
      },
    }],
  });

  reactiveUtils.watch(
    () => timeSlider.timeExtent,
    () => {
      if (!isUpdating.value) {
        isUpdating.value = true;
        const startDateTime = DateTime.fromJSDate(timeSlider.timeExtent.start);
        const endDateTime = DateTime.fromJSDate(timeSlider.timeExtent.end);

        document.dispatchEvent(new CustomEvent('timeSliderUpdated', {
          detail: {
            fromDate: startDateTime.toISO(),
            toDate: endDateTime.toISO()
          }
        }));

        setTimeout(() => {
          isUpdating.value = false;
        }, 100);
      }
    }
  );
}

function addTimeSliderContainer() {
  const existingContainer = document.getElementById('timeSlider-container');

  if (!existingContainer) {
    const mapContainer = document.querySelector('.mapContainer');
    if (mapContainer) {
      const timeSliderContainer = document.createElement('div');
      timeSliderContainer.id = 'timeSlider-container';
      timeSliderContainer.style.position = 'absolute';
      timeSliderContainer.style.bottom = '10px';
      timeSliderContainer.style.left = '50%';
      timeSliderContainer.style.transform = 'translateX(-50%)';
      timeSliderContainer.style.width = '80%';

      mapContainer.appendChild(timeSliderContainer);
    } else {
      console.error('mapContainer not found');
    }
  } else {
    console.log('Time slider container already exists, skipping addition.');
  }
}

function removeTimeSliderContainer() {
  const timeSliderContainer = document.getElementById('timeSlider-container');
  if (timeSliderContainer) {
    timeSliderContainer.remove();
  }
}

function setupMenuPanelAndMapControls() {
  mapView.ui.move("zoom", "bottom-right");
}
</script>

<style>
#mapViewDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: white;
  border-radius: 10px;
}

/* Simple Info Icon for Legend Toggle */
.legend-toggle-icon {
  position: absolute;
  top: -10px;
  /* Move it slightly above the legend wrapper */
  right: 10px;
  /* Align to the right */
  z-index: 150;
  /* Ensure the button is clickable */
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
}

.legend-toggle-icon i {
  pointer-events: none;
}

#legend-wrapper {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 250px;
  height: auto;
  max-height: 350px;
  /* Adjust max-height to make sure it doesn't get too large */
  padding: 10px;
  border-radius: 5px;
  overflow-y: hidden;
  /* Allow scrolling if the content exceeds the max height */
  z-index: 100;
  /* Ensure the legend stays above other elements */
}

/* Legend Panel */
#legend-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /* Allow vertical scrolling if content exceeds */
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.legend-open {
  display: block;
  /* Show legend when toggled */
}
</style>

<style src="@esri/calcite-components/dist/calcite/calcite.css"></style>
