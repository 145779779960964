<script setup>
import { ref } from 'vue';
import TemplatesTable from "@/components/tables/TemplatesTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";

// Ref to hold the filter status
const filterByStatus = ref("Active");

// Function to set the filter status
const setFilterByStatus = (status) => {
  filterByStatus.value = status;
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <h4 class="m-3">Works Templates</h4>
      </div>
      <div class="col-6 text-end">
        <router-link to="/edit-template">
          <MaterialButton variant="gradient" color="info">
            Create New Template
          </MaterialButton>
        </router-link>
      </div>
      <div class="mt-1 mb-1">
        <button class="btn" @click="setFilterByStatus('Active')">Active Templates</button>
        <button class="btn" @click="setFilterByStatus('Site Work')">Site Works Templates</button>
        <button class="btn" @click="setFilterByStatus('Permit')">Permit Templates</button>
        <button class="btn" @click="setFilterByStatus('Archived')">Archived Templates</button>
      </div>
      <div class="card">
        <div class="card-body">
          <TemplatesTable :filterByStatus="filterByStatus" :filterByType="filterByType" />
        </div>
      </div>
    </div>
  </div>
</template>
