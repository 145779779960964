import { defineStore } from 'pinia';
import TucumcariClient from "@/api/tucumcari";

export const usePeopleStore = defineStore('people', {
  state: () => ({
    people: [],
    selectedPerson: "",
    selectedPersonData: {},
  }),
  getters: {
    allPeople: (state) => state.people,
  },
  actions: {
    async fetchPeople() {
      const client = new TucumcariClient();
      try {
        const people = await client.getAllPeople();
        const responseData = await people.response;
        const data = await responseData.body.json();
        console.log("API Response:", data);
        this.people = data;
      } catch (error) {
        console.error("Failed to fetch people:", error);
      }
    },

    async fetchSelectedPerson(id){
      console.log('Selected Person', id);
      console.log('Selected Person via state', this.selectedPerson);
      console.log('all the fetched people:', this.people);
      this.selectedPersonData = this.people.find(person => person.ID === id);
      console.log('Selected Person Data', this.selectedPersonData);

    }
  },
});
