<template>
    <div
      id="integrationModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="integrationModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="integrationModal" class="modal-title">
              Add Integration
            </h5>
            <MaterialButton
              color="none"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
            </MaterialButton>
          </div>
          <div class="modal-body">
            <form>
              <label class="form-control mb-0">Integration</label>
              <material-textarea
                id="integration"
                variant="static"
                class="ms-0"
              ></material-textarea>
            </form>
          </div>
          <div class="modal-footer justify-content-between">
            <MaterialButton
              variant="gradient"
              color="dark"
              data-bs-dismiss="modal"
            >
              Cancel
            </MaterialButton>
            <MaterialButton
              variant="gradient"
              color="info"
              class="mb-0"
              data-bs-dismiss="modal"
            >
              Add
            </MaterialButton>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
  import MaterialButton from "@/components/materialKit/MaterialButton.vue";
  
  export default {
    name: "IntegrationModal",
    components: {
      MaterialTextarea,
      MaterialButton,
    },
  };
  </script>
  