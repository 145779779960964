<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useTrafficPlanStore } from '@/stores/modules/trafficplan';
import { DataTable } from 'simple-datatables';

// Props
const props = defineProps({
  filterByStatus: {
    type: String,
    default: 'Active',
  },
});

// Pinia store
const trafficPlanStore = useTrafficPlanStore();
const dataTable = ref(null);

// Fetch traffic plans from the store
const fetchTrafficPlans = async () => {
  await trafficPlanStore.fetchMockTrafficPlan();
  // await trafficPlanStore.fetchTrafficPlan();
  filterTable(props.filterByStatus);
  initDataTable();
};

// Get traffic plans from the store
const trafficPlans = computed(() => trafficPlanStore.allTrafficPlan);

const filterTable = (status) => {
  const tableBody = document.querySelector('#plans tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    const statusCell = row.querySelector('td:nth-child(3)');
    if (status === 'Active' && statusCell.textContent.trim() === status) {
      row.style.display = '';
    } else if (status === 'Archived' && statusCell.textContent.trim() === status) {
      row.style.display = '';
    } else {
      row.style.display = 'none';
    }
  });
};

// Initialize DataTable after fetching the traffic plans
const initDataTable = () => {
  if (dataTable.value) {
    dataTable.value.destroy();
  }
  dataTable.value = new DataTable('#plans', {
    searchable: true,
    paging: false,
  });

  const tableBody = document.querySelector('#plans tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    row.addEventListener('click', () => {
      window.location.href = `/traffic-management`;
    });
  });
};

// Fetch traffic plans and initialize DataTable
onMounted(fetchTrafficPlans);

// Watch for changes in filterByStatus to reinitialize the DataTable
watch(() => props.filterByStatus, (newStatus) => {
    filterTable(newStatus);
});
</script>

<template>
  <div class="table-responsive">
    <table id="plans" class="table table-flush">
      <thead class="thead-light">
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Plan</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Location</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in trafficPlans" :key="item.ID" :data-id="item.ID">
          <td class="text-sm font-weight-normal">{{ item.plan }}</td>
          <td class="text-sm font-weight-normal">{{ item.Location }}</td>
          <td class="text-sm font-weight-normal">
            <button
              class="btn w-100 m-0 px-1 py-0 text-white"
              :class="{ new: item.Status === 'Active', cancelled: item.Status === 'Archived' }"
            >
              {{ item.Status }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
tr {
  cursor: pointer;
}
</style>
