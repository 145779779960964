<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
      <h4 class="m-3">Management Reports</h4> </div>
      <div class="col-6 text-end">
              <router-link to="/active-work" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Site Map View
                  </MaterialButton></router-link>
              <router-link to="/report-issue" class="me-2">
                  <MaterialButton
                    variant="gradient"
                    color="info"
                  >Report Safety Issue
                  </MaterialButton></router-link>
                </div>

      <div class="col-lg-8 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Critical Site Report</h5> 
              </div>
              <div class="col-lg-3 col-sm-12 text-end"> 
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-primary"
                  ><h5 class="text-white mt-3">April</h5>
                </div>
              </div>
              </div>
            </div>
          </div>
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-8 col-sm-12">
                <h5 class="m-3">Managers Report</h5> 
              </div>
              <div class="col-lg-4 col-sm-12 text-end"> 
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-primary"
                  ><h5 class="text-white mt-3">March</h5>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      <div class="col-lg-4 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
                <h5 class="m-3">Policies</h5> 
                <p>Reporting and site policy list</p>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/materialKit/MaterialButton.vue";

export default {
  name: "Profile",
  components: {MaterialButton,
  },
};
</script>
