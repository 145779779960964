<script setup>
import { ref, onMounted, onBeforeMount, computed } from "vue";
import { useMainStore } from "@/stores/index";
import { useAuthStore } from "@/stores/auth";
import { useArcGISStore } from "@/stores/arcgisStore";
import { useOrganisationStore } from "@/stores/organisation";
import tempImage from "@/assets/img/Ship-Pin.png";
import { signOut } from 'aws-amplify/auth';

const mainStore = useMainStore();
const authStore = useAuthStore();
const arcgisStore = useArcGISStore();
const organisationStore = useOrganisationStore();

const isModalOpen = ref(true);
const isLoading = ref(false);
const body = document.getElementsByTagName("body")[0];

const selectOrg = async (org) => {
    console.log("Selected Organisation:", org);
    organisationStore.selectedOrganisation = org.WebMapID;
    arcgisStore.initializeArcGISData();

    isModalOpen.value = false;
    mainStore.toggleEveryDisplay();
};

const userOrganisation = computed(() => authStore.userDetails?.Organisations);

onMounted(async () => {
    isLoading.value = true;

    if (!authStore.loginId) {
        isModalOpen.value = true;
    }
    isLoading.value = false;
});

onBeforeMount(() => {
    body.classList.remove("bg-gray-100");
});

const signOutUser = async () => {
    await mainStore.logout();
    await signOut();
};
</script>

<template>
    <div v-if="isModalOpen" class="modal-overlay">
        <div class="modal-background"></div>
        <span class="mask bg-gradient-dark opacity-6"></span>
        <div class="modal-content">
            
          <div class="card z-index-0">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-dark shadow-info border-radius-lg py-3 pe-1">
                <router-link to="/">
                  <img
                    src="@/assets/img/TucumcariLogo-Full-TransaprentBack.png"
                    class="navbar-brand-img w-50 pb-3 mx-auto d-block position-relative"
                    alt="main_logo"
                  />
                </router-link>
              </div>
            </div>
            <div class="card-body">
            <h2 class="modal-title">Select an Organisation:</h2>
            <span v-if="isLoading" class="loading-message">Loading data...</span>
            <ul v-else class="org-list">
                <li
                v-for="org in userOrganisation" :key="org.ID" class="org-item"
                    @click="selectOrg(org)">
                    <img :src="tempImage" alt="" class="org-icon" />
                    <span>{{ org.Name }}</span>
                </li>
            </ul>
            <span class="sign-out">
                <router-link
                    to="/sign-in" class="d-flex align-items-center font-weight-bold text-primary"
                    @click="signOutUser">
                    
          <button class="btn bg-gradient-primary mb-0 toast-btn me-2" type="button">
                    <i class="material-icons me-1">logout</i>
                    Sign out
          </button>
                </router-link>
            </span>
              <p class="mt-4 text-sm text-center">
                Note: You will need to sign in again to select a new location
              </p>
            </div>
          </div>


        </div>
    </div>
</template>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://tucumcari.co.nz/wp-content/uploads/2023/07/Piles-2low.jpg') no-repeat center center;
    background-size: cover;
    z-index: 1;
}
.mask{
    z-index: 2;
}

.modal-content {
    position: relative;
    max-width: 500px;
    width: 100%;
    text-align: center;
    z-index: 3;
}

.modal-title {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #333;
}

.org-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* Adjust the spacing */
    padding: 0;
    list-style-type: none;
    margin: 0;
    gap: 10px;
    /* Add some spacing between the items */
}

.org-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s;
    margin: 0 10px;
    /* Control horizontal margin between items */
}

.org-item:hover {
    transform: scale(1.1);
}

.org-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.org-item span {
    font-size: 1.2em;
    color: #333;
}

.loading-message {
    text-align: center;
    font-size: 1.2em;
    color: #333;
}

.sign-out {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
</style>
