<script setup>
import { ref, watch, computed, onMounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { DataTable } from 'simple-datatables';
import moment from 'moment';
import { useAssetStore } from '@/stores/modules/assets';

const router = useRouter();
const store = useAssetStore();
const dataTable = ref(null);
const props = defineProps({
  filterByStatus: {
    type: String,
    default: 'all'
  }
});

const sortedList = computed(() => {
  return store.allAssets.slice().sort((a, b) => {
    const dateA = moment(a.reportDate, 'DD/MM/YYYY');
    const dateB = moment(b.reportDate, 'DD/MM/YYYY');
    return dateB - dateA;
  });
});

const initDataTable = () => {
  if (dataTable.value) {
    dataTable.value.destroy();
  }
  dataTable.value = new DataTable('#assets', {
    searchable: true,
    fixedHeight: true,
  });
  addRowLinks();
};

const addRowLinks = () => {
  const tableBody = document.querySelector('#assets tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    const id = row.dataset.id || '123456';
    row.addEventListener('click', () => {
      router.push(`/registry/${id}`);
    });
  });
};

const filterTable = (status) => {
  initDataTable();
  const tableBody = document.querySelector('#assets tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    const statusCell = row.querySelector('td:nth-child(5)');
    const linkedCell = row.querySelector('td:nth-child(4)');
    if (status !== 'all' && statusCell && statusCell.textContent.trim() !== status) {
      row.remove();
    } else if (status === 'Linked' && statusCell && linkedCell.textContent.trim() === status) {
      row.style.display = '';
    }
  });
};

watch(() => props.filterByStatus, (newStatus) => {
  filterTable(newStatus);
});

onMounted(async () => {
  await store.fetchAssets();
  await store.fetchMockAssets();
  nextTick(() => {
    initDataTable();
    filterTable(props.filterByStatus);
  });
});
</script>

<template>
  <div class="table-responsive">
    <table id="assets" class="table table-flush">
      <thead class="thead-light">
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Name </th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Rest Location </th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Upcoming Expiry </th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Linked to incidents </th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Status </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in sortedList" :key="item.id" :data-id="item.id">
          <td class="text-sm font-weight-normal">{{ item.Title }}</td>
          <td class="text-sm font-weight-normal">{{ item.Location }}</td>
          <td class="text-sm font-weight-normal">{{ item.expiry }}</td>
          <td class="text-sm font-weight-normal">
            <button class="btn w-100 mb-0 p-0 text-white" :class="{ openIcid: item.incidents == 'Linked' }">{{ item.incidents }}</button>
          </td>
          <td class="text-sm font-weight-normal">
            <button class="btn w-100 mb-0 p-0 text-white" :class="{ new: item.Status == 'Active', closed: item.Status == 'Archived' }">{{ item.Status }}</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
tr {
  cursor: pointer;
}
</style>
