import { defineStore } from 'pinia';
import TucumcariClient from "@/api/tucumcari";

export const useRoleStore = defineStore('role', {
  state: () => ({
    roles: [],
  }),
  getters: {
    allRoles: (state) => state.roles,
  },
  actions: {
    async fetchRoles() {
      const client = new TucumcariClient();
      try {
        const roles = await client.getRoles();
        const responseData = await roles.response;
        const data = await responseData.body.json();
        console.log("API Response:", data);
        this.roles = data;
      } catch (error) {
        console.error("Failed to fetch roles:", error);
      }
    },
  },
});
