import { defineStore } from 'pinia';
import TucumcariClient from "@/api/tucumcari";
import { getMockTrafficPlan } from '@/services/mockData';

export const useTrafficPlanStore = defineStore('trafficplan', {
  state: () => ({
    trafficPlans: [],
  }),
  getters: {
    allTrafficPlan: (state) => state.trafficPlans,
  },
  actions: {
    async fetchMockTrafficPlan() {
      const trafficPlans = await getMockTrafficPlan();
      this.trafficPlans = trafficPlans;
    },
    async fetchTrafficPlan() {
      const client = new TucumcariClient();
      try {
        const trafficPlans = await client.getAllTrafficManagementPlan();
        console.log("API Response:", trafficPlans); // Log the contractors data
        //this.trafficPlans = trafficPlans;
      } catch (error) {
        console.error("Failed to fetch trafficPlans:", error);
      }
    },
  },
});
