<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useHazardStore } from '@/stores/modules/hazards';
import { DataTable } from 'simple-datatables';
import moment from 'moment';

const props = defineProps({
  filterStatus: {
    type: String,
    required: true,
  },
});

const store = useHazardStore();
const router = useRouter();
const dataTable = ref(null);

const hazards = computed(() => store.allHazards);

const sortedList = computed(() => {
  return hazards.value.slice().sort((a, b) => {
    const dateA = moment(a.reportDate, 'DD/MM/YYYY');
    const dateB = moment(b.reportDate, 'DD/MM/YYYY');
    return dateB - dateA;
  });
});

const fetchHazards = async () => {
  await store.fetchMockHazards();
  await store.fetchHazards();
  initDataTable();
};

const initDataTable = () => {
  if (dataTable.value) {
    dataTable.value.destroy();
  }
  dataTable.value = new DataTable('#hazards', {
    searchable: true,
    fixedHeight: true,
  });

  const tableBody = document.querySelector('#hazards tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    const id = row.dataset.id;
    row.addEventListener('click', () => {
      console.log('Row clicked', id);
      router.push(`/hazard/${id}`);
    });
  });
};

const filterHazards = (status) => {
  const tableBody = document.querySelector('#hazards tbody');
  const rows = tableBody.querySelectorAll('tr');
  console.log(status);
  rows.forEach((row) => {
    const statusCell = row.querySelector('td:nth-child(1)');
    const linkedCell = row.querySelector('td:nth-child(6)');
    console.log(statusCell.textContent.trim());
    if (status === 'All' || statusCell.textContent.trim() === status) {
      row.style.display = '';
    } else if (status === 'Linked' && linkedCell.textContent.trim() === status) {
      row.style.display = '';
    } else {
      row.style.display = 'none';
    }
  });
};

watch(() => props.filterStatus, (newStatus) => {
  filterHazards(newStatus);
});

onMounted(fetchHazards);
</script>

<template>
  <div class="table-responsive">
    <table id="hazards" class="table table-flush">
      <thead class="thead-light">
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">First Reported</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Last Updated</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Location</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Linked to incidents</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in sortedList" :key="item.ID" :data-id="item.ID">
          <td class="text-sm font-weight-normal">
            <button class="btn w-100 mb-0 p-0 text-white" :class="{
              new: item.Status == 'New',
              openIcid: item.Status == 'Open Hazard',
              closed: item.Status == 'Closed',
            }">
              {{ item.Status }}
            </button>
          </td>
          <td class="text-sm font-weight-normal">{{ item.Title }}</td>
          <td class="text-sm font-weight-normal">{{ item.reportDate }}</td>
          <td class="text-sm font-weight-normal">{{ item.updatedDate }}</td>
          <td class="text-sm font-weight-normal">{{ item.Location }}</td>
          <td class="text-sm font-weight-normal">
            <button class="btn w-100 mb-0 p-0 text-white" :class="{ openIcid: item.incidents == 'Linked' }">
              {{ item.incidents }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
tr {
  cursor: pointer;
}
</style>
