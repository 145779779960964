<template>
    <div
      id="serviceModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="serviceModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="serviceModal" class="modal-title">
              Enter Service Details
            </h5>
            <MaterialButton
              color="none"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
            </MaterialButton>
          </div>
          <div class="modal-body">
            <form>
              <material-input
                id="bookedBy"
                label="Booked By"
                variant="static"
                class="mt-4 ms-0"
              />
              <material-input
                id="bookedFor"
                label="Booked For"
                variant="static"
                class="mt-4 ms-0"
              />
              <material-input
                id="note"
                label="Note"
                variant="static"
                class="mt-4 ms-0"
              />
            </form>
          </div>
          <div class="modal-footer justify-content-between">
            <MaterialButton
              variant="gradient"
              color="dark"
              data-bs-dismiss="modal"
            >
              Cancel
            </MaterialButton>
            <MaterialButton
              variant="gradient"
              color="info"
              class="mb-0"
              data-bs-dismiss="modal"
            >
              Add
            </MaterialButton>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MaterialButton from "@/components/materialKit/MaterialButton.vue";
  import MaterialInput from "@/components/materialKit/MaterialInput.vue";
  
  export default {
    name: "ServiceModal",
    components: {
      MaterialButton,
      MaterialInput
    },
  };
  </script>
  