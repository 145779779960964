<template>
    <div class="p-3 pb-0 card-body">
      <div class="mt-3 row">
        <div class="table-responsive">
          <table id="datatable-basic" class="table table-flush">
            <thead class="thead-light">
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">First Reported</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Incident Occurred</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Location</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in safety" :key="item.id" :data-id="item.id">
                <td class="text-sm font-weight-normal">
                  <button class="btn w-100 mb-0 p-0 text-white" :class="{ draft: item.status === 'New', review: item.status === 'In Review', openIcid: item.status === 'Open Incident', closed: item.status === 'Closed' }">{{ item.status }}</button>
                </td>
                <td class="text-sm font-weight-normal">{{ item.reportDate }}</td>
                <td class="text-sm font-weight-normal">{{ item.occurDate }}</td>
                <td class="text-sm font-weight-normal">{{ item.title }}</td>
                <td class="text-sm font-weight-normal">{{ item.location }}</td>
                <td class="text-sm font-weight-normal">
                  <button class="btn w-100 mb-0 p-0 text-white" :class="{ openIcid: item.type === 'Hazard', new: item.type === 'Incident' }" @click="$router.push('/hazard/123456')">{{ item.type }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "RelatedTab",
    props: {
      safety: Array
    }
  };
  </script>
  