import { createApp } from "vue";
import { createPinia } from "pinia";
import env from './env.json';
import App from "./App.vue";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import { setAssetPath } from '@esri/calcite-components/dist/components';

import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import * as auth from "aws-amplify/auth";
import * as api from "aws-amplify/api";
import AmplifyVue from "@aws-amplify/ui-vue";

import TucumcariClient from "./api/tucumcari";
import { useAuthStore } from "@/stores/auth";
import { useArcGISStore } from "@/stores/arcgisStore";

setAssetPath(location.href);

window.amplify = {
  auth,
  api,
  tucumcari: new TucumcariClient()
};

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolId: "ap-southeast-2_HBGI1V8op", // Tucumcari
        userPoolClientId: "7kmvj111g9k9bn2i41ir3e4chk", // Tucumcari > App integration > App client list > Tucumcari
        loginWith: {
          oauth: {
            domain: "tucumcari.auth.ap-southeast-2.amazoncognito.com",
            scopes: [
              "email",
              "profile",
              "openid",
              "aws.cognito.signin.user.admin",
            ],
            responseType: "code",
          },
        },
      },
    },
    API: {
      REST: {
        Tucumcari: {
          endpoint: `${env.tucumcari.url}/${env.tucumcari.application || env.application}`,
        },
      },
    },
  },
  {
    API: {
      REST: {
        headers: async ({ apiName }) => ({
          Authorization: apiName.toLowerCase().endsWith('public') ? undefined : `Bearer ${(await auth.fetchAuthSession()).tokens.accessToken.toString()}`,
          PrimaryDomain: env[apiName.toLowerCase()].primaryDomain || env.primaryDomain,
          "x-api-key": env[apiName.toLowerCase()].xApiKey || env.xApiKey,
        }),
      },
    },
  }
);

const appInstance = createApp(App);
appInstance.use(createPinia());

const authStore = useAuthStore();
const arcgisStore = useArcGISStore();

// Redirect on login and set loginId for display
Hub.listen("auth", async ({ payload }) => {
  if (payload.event === "signedIn") {
    await arcgisStore.fetchOauthInfo();
    await authStore.fetchLoginId();
    router.push({ name: "/" });
  } else if (payload.event === "signedOut") {
    arcgisStore.logout();
  }
});

appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(MaterialDashboard);
appInstance.use(AmplifyVue);
appInstance.mount("#app");
