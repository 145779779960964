<script setup>
import { ref, onMounted, nextTick, defineProps, toRefs, watch, computed } from 'vue';
import { useRouter } from "vue-router";
import { DataTable } from 'simple-datatables';
import moment from 'moment';
import { usePermitsStore } from '@/stores/permits';

const props = defineProps({
  filterStatus: {
    type: String,
    default: null
  }
});

const permitsStore = usePermitsStore();
const dataTable = ref(null);

const { filterStatus } = toRefs(props);

const permits = computed(() => permitsStore.permits); // Original permits
// const mockPermits = computed(() => permitsStore.allMockPermits); // Mock permits

// Ensure mock data is sorted by date
const sortedList = computed(() => {
  return permits.value.slice().sort((a, b) => {
    const dateA = moment(a.FromDateTime, 'DD/MM/YYYY');
    const dateB = moment(b.FromDateTime, 'DD/MM/YYYY');
    return dateB - dateA;
  });
});

// Router instance
const router = useRouter();

const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

// Initialize the DataTable
// const initDataTable = () => {
//   nextTick(() => {
//     const permitsTable = document.querySelector("#permits");

//     if (!permitsTable) {
//       console.error("Permits table not found. Make sure the DOM is rendered.");
//       return; // Exit if table is not found
//     }

//     // Only destroy the DataTable if it exists
//     if (dataTable.value) {
//       if (dataTable.value.destroy && dataTable.value.table) {
//         dataTable.value.destroy();
//       }
//       dataTable.value = null; // Reset to null
//     }

//     // Create a new DataTable instance
//     dataTable.value = new DataTable(permitsTable, {
//       searchable: true,
//       fixedHeight: true,
//     });

//     // Attach click listeners after pagination
//     dataTable.value.on("datatable.page", () => {
//       nextTick(() => {
//         attachRowClickListeners();
//       });
//     });

//     dataTable.value.on("datatable.update", () => {
//       nextTick(() => {
//         attachRowClickListeners();
//       });
//     });

//     attachRowClickListeners(); // Attach click listeners to current rows
//   });
// };

// Attach row click listeners
const attachRowClickListeners = () => {
  const tableBody = document.querySelector("#permits tbody");
  const rows = tableBody.querySelectorAll("tr");
  rows.forEach((row) => {
    row.removeEventListener("click", handleRowClick); // Remove previous listeners
    row.addEventListener("click", handleRowClick); // Attach new listener
  });
};

// Handle row click and navigate
const handleRowClick = (event) => {
  const id = event.currentTarget.getAttribute("data-id");
  const layerName = event.currentTarget.getAttribute("data-layer");
  if (id && layerName) {
    navigateToPermit(id, layerName); // Pass both id and layerName
  }
};

const navigateToPermit = (id, layerName) => {
  router.push({ name: 'Permit', params: { id: id, layerName: layerName } }); // Navigate to permit
};

// Apply the filter to manually update the DOM and create new rows
const applyFilter = () => {
  const filteredData = filterStatus.value
    ? sortedList.value.filter((item) => item.Status === filterStatus.value)
    : sortedList.value;

  const tableBody = document.querySelector("#permits tbody");

  if (!tableBody) {
    console.error("Table body not found. Ensure the table is rendered.");
    return;
  }

  // Clear current table rows
  tableBody.innerHTML = "";

  // Add filtered rows manually
  filteredData.forEach((item) => {
    const row = document.createElement("tr");
    row.setAttribute("data-id", item.OBJECTID);
    row.setAttribute("data-layer", item.layerName);
    row.innerHTML = `
      <td class="text-sm font-weight-normal"><button class="btn w-100 m-0 px-1 py-0 text-white ${getStatusClass(item.Status)}">${item.Status == 'P' || '' ? 'Pending' : 'Awaiting  Approval'}</button></td>
      <td class="text-sm font-weight-normal">${item.Discription || 'N/A'}</td>
      <td class="text-sm font-weight-normal">${item.layerName || 'N/A'}</td>
      <td class="text-sm font-weight-normal">${item.Who || 'N/A'}</td>
      <td class="text-sm font-weight-normal">${item.PermitType || 'N/A'}</td>
      <td class="text-sm font-weight-normal">${item.Shape__Area || 'N/A'}</td>
      <td class="text-sm font-weight-normal">${formatDate(item.ApprovedStartTime)}</td>
      <td class="text-sm font-weight-normal">${formatDate(item.ApprovedEndTime)}</td>
    `;
    tableBody.appendChild(row);
  });

  // Re-initialize DataTable after updating rows
  nextTick(() => {
    const permitsTable = document.querySelector("#permits");
    if (permitsTable) {
      if (dataTable.value) {
        if (dataTable.value.destroy && dataTable.value.table) {
          dataTable.value.destroy(); // Destroy old DataTable
        }
        dataTable.value = null; // Reset to null
      }

      dataTable.value = new DataTable(permitsTable, {
        searchable: true,
        fixedHeight: true,
      });

      dataTable.value.on("datatable.page", () => {
        nextTick(() => {
          attachRowClickListeners();
        });
      });

      dataTable.value.on("datatable.update", () => {
        nextTick(() => {
          attachRowClickListeners();
        });
      });

      attachRowClickListeners(); // Reattach listeners
    }
  });
};

// Get the appropriate class for the status
const getStatusClass = (status) => {
  return {
    "P": "pending",
    "A": "waiting",
    "Declined": "updated",
    "Draft": "draft",
    "Cancelled": "cancelled",
    "Updated": "updated"
  }[status] || "";
};

// Ensure mock data is loaded and apply filters once ready
onMounted(async () => {
  await permitsStore.fetchPermits();
  await permitsStore.fetchMockPermits(); // Ensure data is fetched
  nextTick(() => {
    applyFilter(); // Apply initial filter
    // initDataTable(); // Initialize DataTable
  });
});

// Re-apply filter when the filter status changes
watch(filterStatus, () => {
  applyFilter(); // Apply filter on filter status change
});

</script>

<template>
  <div class="table-responsive">
    <table id="permits" class="table table-flush">
      <thead class="thead-light">
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Project</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Activity</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Applicant</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Location</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Start</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">End</th>
        </tr>
      </thead>
      <tbody>
        <!-- Rows will be dynamically created in the applyFilter function -->
      </tbody>
    </table>
  </div>
</template>

<style scoped>
tr {
  cursor: pointer;
}
</style>
