<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import MaterialInput from '@/components/materialKit/MaterialInput.vue';
import MaterialTextarea from '@/components/materialKit/MaterialTextarea.vue';
import MaterialCheckbox from '@/components/materialKit/MaterialCheckbox.vue';

const router = useRouter();

const name = ref('');
const company = ref('');
const workRole = ref('');
const reportsTo = ref('');
const licences = ref('');
const qualifications = ref('');
const description = ref('');
const email = ref('');
const phone = ref('');
const type = ref([]);
const roles = ref([]);

const savePerson = () => {
  // Save logic here
  router.push('/contractors');
};
</script>

<template>
  <div id="top" class="py-4 h-100 container-fluid">
    <div class="row">
      <h4>Add People</h4>
      <div class="col-8 mx-auto">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row mt-4">
              <div class="col-12 col-sm-8">
                <material-input
                  id="name"
                  label="Name"
                  variant="static"
                  class="mt-2 ms-0"
                  v-model="name"
                />
                <material-input
                  id="company"
                  label="Company"
                  variant="static"
                  class="mt-4 ms-0"
                  v-model="company"
                />
                <material-input
                  id="workRole"
                  label="Work Role"
                  variant="static"
                  class="mt-4 ms-0"
                  v-model="workRole"
                />
                <material-input
                  id="reportsTo"
                  label="Reports to"
                  variant="static"
                  class="mt-4 ms-0"
                  v-model="reportsTo"
                />
                <material-input
                  id="licences"
                  label="Licences"
                  variant="static"
                  class="mt-4 ms-0"
                  v-model="licences"
                />
                <material-input
                  id="qualifications"
                  label="Qualifications"
                  variant="static"
                  class="mt-4 ms-0"
                  v-model="qualifications"
                />
                <label class="mt-2 ms-0">Description</label>
                <material-textarea
                  id="description"
                  variant="static"
                  class="mt-2 ms-0"
                  v-model="description"
                ></material-textarea>
              </div>
              <div class="col-12 col-sm-4 p-3">
                <h5 class="text-sm">Type</h5>
                <div class="p-3">
                  <material-checkbox
                    id="contractor"
                    v-model="type"
                    value="Contractor"
                  >
                    Contractor
                  </material-checkbox>
                  <material-checkbox
                    id="portPersonnel"
                    v-model="type"
                    value="Port Personnel"
                  >
                    Port Personnel
                  </material-checkbox>
                </div>
                <h5 class="text-sm">Roles</h5>
                <div class="p-3">
                  <material-checkbox
                    id="safetyRole"
                    v-model="roles"
                    value="Safety Role"
                  >
                    Safety Role
                  </material-checkbox>
                  <material-checkbox
                    id="supervisorRole"
                    v-model="roles"
                    value="Supervisor Role"
                  >
                    Supervisor Role
                  </material-checkbox>
                  <material-checkbox
                    id="adminRole"
                    v-model="roles"
                    value="Admin Role"
                  >
                    Admin Role
                  </material-checkbox>
                  <h5 class="m-3">Contact</h5>
                  <material-input
                    id="email"
                    label="Email"
                    variant="static"
                    class="mt-4 ms-0"
                    v-model="email"
                  />
                  <material-input
                    id="phone"
                    label="Phone"
                    variant="static"
                    class="mt-4 ms-0"
                    v-model="phone"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                  @click="savePerson"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
