<script setup>
import { computed, onMounted, ref } from 'vue';
import MaterialCheckbox from '@/components/materialKit/MaterialCheckbox.vue';
import MaterialInput from '@/components/materialKit/MaterialInput.vue';
import { useRoleStore } from '@/stores/role';

const roleStore = useRoleStore();

const roles = computed(() => roleStore.allRoles);

const newRoleTitle = ref('');
const newRoleReportsTo = ref('');
const newRoleAddEditPermissions = ref([]);
const newRoleApprovePermissions = ref([]);

const saveNewRole = () => {
  const newRole = {
    title: newRoleTitle.value,
    reportsTo: newRoleReportsTo.value,
    addEditPermissions: newRoleAddEditPermissions.value,
    approvePermissions: newRoleApprovePermissions.value,
  };
  roles.value.push(newRole);
  newRoleTitle.value = '';
  newRoleReportsTo.value = '';
  newRoleAddEditPermissions.value = [];
  newRoleApprovePermissions.value = [];
};

onMounted(async () => await roleStore.fetchRoles());

</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row mb-5">
      <!-- Active Roles Section -->
      <div class="col-lg-8 mt-lg-0 mt-4">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-1 font-weight-bolder">Active Roles</h5>
          </div>

          <!-- Check if there are roles, and if not, show a message -->
          <div v-if="roles.length === 0">
            <p>No roles available.</p>
          </div>

          <!-- Displaying roles dynamically using v-for -->
          <div class="card-body pt-0" v-else>
            <div v-for="role in roles" :key="role.ID">
              <div class="row">
                <p class="mb-0 font-weight-bold text-sm">{{ role.Title }}</p>
                <p class="text-sm" v-if="role.ReportsTo">Reports to {{ role.ReportsTo }}</p>
                
                <div class="col-6">
                  <p class="text-sm">Add/Edit Permissions</p>
                  <material-checkbox
                    id="safety-add-edit"
                    :checked="role.SafetyAddEditUpdate"
                    :disabled="true"
                  >
                    Safety Add Edit Update
                  </material-checkbox>
                  <material-checkbox
                    id="permit-add-edit"
                    :checked="role.PermitAddEditUpdate"
                    :disabled="true"
                  >
                    Permit Add Edit Update
                  </material-checkbox>
                </div>

                <div class="col-6">
                  <p class="text-sm">Approve Permissions</p>
                  <material-checkbox
                    id="safety-approve"
                    :checked="role.SafetyApprove"
                    :disabled="true"
                  >
                    Safety Approve
                  </material-checkbox>
                  <material-checkbox
                    id="permit-approve"
                    :checked="role.PermitApprove"
                    :disabled="true"
                  >
                    Permit Approve
                  </material-checkbox>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>

      <!-- New Role Form Section -->
      <div class="col-lg-4">
        <div class="card mt-4">
          <div class="card-header">
            <h5>Create New Role</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <!-- Input for Role Title -->
              <material-input
                v-model="newRoleTitle"
                id="role-title"
                label="Role Title"
                variant="static"
                class="mt-2 ms-0 mb-4"
              />

              <!-- Input for Reports To -->
              <material-input
                v-model="newRoleReportsTo"
                id="role-reports-to"
                label="Reports To"
                variant="static"
                class="mt-2 ms-0 mb-4"
              />

              <!-- Checkboxes for Add/Edit Permissions -->
              <div class="col-6">
                <p class="text-sm">Add/Edit Permissions</p>
                <material-checkbox
                  v-model="newRoleAddEditPermissions"
                  id="safety-add-edit"
                  value="Safety Add Edit Update"
                >
                  Safety Add Edit Update
                </material-checkbox>
                <material-checkbox
                  v-model="newRoleAddEditPermissions"
                  id="permit-add-edit"
                  value="Permit Add Edit Update"
                >
                  Permit Add Edit Update
                </material-checkbox>
              </div>

              <!-- Checkboxes for Approve Permissions -->
              <div class="col-6">
                <p class="text-sm">Approve Permissions</p>
                <material-checkbox
                  v-model="newRoleApprovePermissions"
                  id="safety-approve"
                  value="Safety Approve"
                >
                  Safety Approve
                </material-checkbox>
                <material-checkbox
                  v-model="newRoleApprovePermissions"
                  id="permit-approve"
                  value="Permit Approve"
                >
                  Permit Approve
                </material-checkbox>
              </div>
            </div>

            <!-- Save Button -->
            <button
              class="btn bg-gradient-info mb-0 toast-btn me-2"
              type="button"
              @click="saveNewRole"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
tr,
.bg-gradient-info {
  cursor: pointer;
}
</style>
