<script setup>
import { onMounted, onBeforeUnmount, watch, ref } from 'vue';
import { usePermitsStore } from '@/stores/permits';
import Choices from 'choices.js';
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
import { hasPermission } from '@/utils/checkPermission';

// Use the Pinia store
const permitsStore = usePermitsStore();
let choicesInstances = {}; // Store instances globally within the component

const showModal = ref(false);
// const isModalVisible = ref(false);

// const toggleModal = () => {
//   isModalVisible.value = !isModalVisible.value;
// };

// Open the modal
const openModal = () => {
  showModal.value = true;
};

// Close the modal
const closeModal = () => {
  showModal.value = false;
};

const operatingSteps = ref([
  {
    step: "Can work be done without entry to the confined space?",
    hazard: ["Oxygen-deficient atmospheres"],
    inherentRisk: "Moderate",
    residualRisk: "Low",
    group: "All",
    control: [
      "Check to see if the work can be done with equipment from outside the confined space.",
      "Use a suitable detector to determine whether the confined space contains a safe oxygen level for breathing.",
      "Where possible, carry out atmospheric testing without entering the confined space.",
      "Use suitable self-contained breathing apparatus or supplied-air respirator as necessary.",
    ],
  },
  {
    step: "Isolate contaminants and moving parts",
    hazard: [
      "Uncontrolled introduction of steam, water, or other gas or liquid.",
      "Cleaning, painting or welding may produce dangerous vapours and fumes.",
      "Operation of moving parts (Example: Being trapped or crushed by augers, mixers, agitators or conveyor belts). ",
    ],
    inherentRisk: "High",
    residualRisk: "Moderate",
    group: "All",
    control: [
      "Prevent accidental introduction of materials (for example, steam, water or bulk materials, through piping, ducts, vents, etc).",
      "De-energise, lockout or tagout machinery.",
      "Use a suitable cleaning method to remove harmful solids or sludges.",
      "Purge with fresh air to remove harmful gases or vapours.",
      "Use appropriate PPE which can include respiratory protective equipment (RPE), safety helmet, gloves, hearing protectors, safety harness, lifeline.",
    ],
    note: "Warning: Never use oxygen to purge a confined space as this can create a fire and explosion hazard."
  },
  {
    step: "Test the atmosphere for toxic and flammable contaminants",
    hazard: [
      "Toxic atmospheres, containing gases, vapours, dusts or fumes that have poisonous effects on the body. Some of these substances may be carcinogenic.",
      "Flammable or explosive atmospheres, containing flammable gases, vapours or dusts which could be ignited by a spark or open flame.",
    ],
    inherentRisk: "High",
    residualRisk: "Moderate",
    group: "All",
    control: [
      "Test for toxic contaminants (for example, hydrogen sulphide, methane, carbon monoxide) and flammable contaminants (for example, petroleum vapours).",
      "Use appropriate detection equipment, which should be correctly calibrated at regular intervals.",
      "Use appropriate PPE which can include: respiratory protective equipment (RPE), safety helmet, gloves, hearing protectors, safety harness, lifeline."
    ],
    note: "Warning: Never use oxygen to purge a confined space as this can create a fire and explosion hazard."
  },
  {
    step: "Ventilating the confined space safely",
    hazard: [
      "Uncontrolled introduction of steam or other gas.",
      "Flammable or explosive atmospheres, containing flammable gases"
    ],
    inherentRisk: "High",
    residualRisk: "Low",
    group: "All",
    control: [
      "Ventilate the confined space by using a blower designed for the purpose.",
      "When blowing air into the confined space, make sure high volume of air is blown in at a low speed – this can be provided by trunking and a blower made especially for this purpose.",
      "Test again for levels of oxygen and other gases to ensure that contaminants are reduced to below the Workplace Exposure Standard, or a safe level.",
      "Use suitable self-contained breathing apparatus or supplied-air respirator as necessary."
    ],
  },
]);

const initializeChoices = (id, defaultValue) => {
  const element = document.getElementById(id);
  if (element) {
    // Destroy existing Choices instance if it exists
    if (choicesInstances[id]) {
      choicesInstances[id].destroy();
    }

    // Create a new Choices instance
    const choicesInstance = new Choices(element, {
      searchEnabled: true,
      allowHTML: true,
    });

    // Set the default value if provided
    if (defaultValue) {
      choicesInstance.setChoiceByValue(defaultValue);
    }

    // Store the Choices instance in the global object
    choicesInstances[id] = choicesInstance;

    return choicesInstance;
  }
};

onMounted(() => {
  initializeChoices('tmplans', permitsStore.selectedTMPlan);
});

// Clean up Choices instances before the component is destroyed
onBeforeUnmount(() => {
  Object.keys(choicesInstances).forEach((id) => {
    if (choicesInstances[id]) {
      choicesInstances[id].destroy();
    }
  });
  choicesInstances = {}; // Clear the instances after destruction
});

// Watch for changes and update the store
watch(
  () => document.getElementById('tmplans')?.value,
  (newValue) => {
    permitsStore.setSelectedTMPlan(newValue);
  }
);

const openRiskScoreCalculator = () => {
  window.open('/img/RISK-SCORE-CALCULATOR.png', '_blank');
};

const openHierarchyControl = () => {
  window.open('/img/Hierarchy-of-Control-Measures.png', '_blank');
};
</script>

<template>
  <div class="pt-3 bg-white multisteps-form__panel border-radius-xl js-active position-relative"
    data-animation="FadeIn">
    <div class="multisteps-form__content">
      <div class="row text-start">
        <h4>Add Traffic Management Plan</h4>
        <div class="col-12 mb-4">
          <div class="d-block">
            <select id="tmplans" class="form-control" name="Plans">
              <option value="Traffic Plan not required">Traffic Plan not required</option>
              <option value="Traffic Plan for Route 6">Traffic Plan for Route 6</option>
              <option value="Traffic Plan for Left lane">Traffic Plan for Left lane</option>
              <option value="Traffic Plan near the Boat">Traffic Plan near the Boat</option>
            </select>
          </div>
        </div>
      </div>
      <!-- Buttons for opening modal and other actions -->
      <div class="row text-start">
        <h4>Procedure Steps</h4>
        <div class="text-end mb-4 d-flex justify-content-between">
          <button class="btn bg-gradient-info mb-0" type="button" @click="openModal">Add Additional Step</button>
          <div>
            <button class="btn bg-gradient-info mb-0 toast-btn me-2" type="button" @click="openRiskScoreCalculator">
              Check Risk Score Calculator
            </button>
            <button class="btn bg-gradient-info mb-0 toast-btn me-2" type="button" @click="openHierarchyControl">
              Check Hierarchy of Control Measures
            </button>
          </div>
        </div>
        <p class="text-dark">
          IF RESIDUAL RISK SCORE IS STILL HIGH OR CRITICAL, A WORK METHOD STATEMENT
          MUST BE CREATED AND APPROVED BY INFRASTRUCTURE SERVICES MANAGER
        </p>
      </div>

      <!-- Modal Component -->
      <div v-if="showModal" class="modal-overlay">
        <div class="modal-content">
          <header class="modal-header">
            <h5>Add Step</h5>
            <button class="close-btn" @click="closeModal">&times;</button>
          </header>
          <section class="modal-body">
            <material-input id="step" label="Step" variant="static" class="mt-2 ms-0" />
            <label class="mt-4 ms-0">Hazards</label>
            <material-textarea id="hazards" placeholder="Add canned text by typing /add" variant="static"
              class="mt-0 ms-0" />
            <div class="row mt-2">
              <div class="col-12 col-sm-6">
                <material-input id="riskScore1" label="Inherent Risk Score" variant="static" class="mt-4 ms-0" />
              </div>
              <div class="col-12 col-sm-6">
                <material-input id="riskScore2" label="Residual Risk Score" variant="static" class="mt-4 ms-0" />
              </div>
            </div>
            <label class="mt-4 ms-0">Control Methods & Monitoring</label>
            <material-textarea id="control" placeholder="Add canned text by typing /add" variant="static"
              class="mt-0 ms-0" />
          </section>
          <footer class="modal-footer">
            <button class="btn bg-gradient-primary" @click="closeModal">Save</button>
            <button class="btn bg-gradient-secondary" @click="closeModal">Cancel</button>
          </footer>
        </div>
      </div>

      <div class="row mt-2 text-start">
        <div v-for="(operatingStep, index) in operatingSteps" :key="index" class="col-12 mb-4">
          <div class="mt-2 border card-plain border-radius-lg p-4">
            <div class="row">
              <div class="col-9">
                <h6 class="text-dark font-weight-bold mb-0">Step: {{ operatingStep.step }}</h6>
              </div>
              <div v-if="hasPermission('canRemove')" class="col-3 text-end mb-4">
                <button class="btn mb-0 toast-btn me-2" type="button">
                  Remove step
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <p class="text-sm mt-1 mb-0">
                  <b>Hazards:</b> <br>
                <ul class="text-sm mt-1 mb-0">
                  <li v-for="(hazard, controlIndex) in operatingStep.hazard" :key="controlIndex">
                    {{ hazard }}
                  </li>
                </ul>
                </p>
              </div>
              <div class="col-6">
                <p class="text-sm mt-1 mb-0">
                  <b>Inherent Risk Score: </b>
                  <span :class="{ 'text-danger': operatingStep.inherentRisk === 'High' }">
                    <b>{{ operatingStep.inherentRisk }} </b>
                  </span>
                  <br>
                  <b>Residual Risk Score: <span class="text-success">{{ operatingStep.residualRisk }}</span></b><br>
                  <b>Action By:</b> {{ operatingStep.group }}
                </p>
              </div>
            </div>
            <hr>
            <p class="text-sm mt-1 mb-0 font-weight-bold">Control Methods & Monitoring:</p>
            <ul class="text-sm mt-1 mb-0">
              <li v-for="(control, controlIndex) in operatingStep.control" :key="controlIndex">
                {{ control }}
              </li>
            </ul>
            <small class="danger">{{ operatingStep.note }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  /* Increased opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it is above all other elements */
}

.modal-content {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 600px;
  /* Increased width for better visibility */
  max-width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  /* Add shadow for better visibility */
  z-index: 2;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  margin-top: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.modal-footer .btn {
  margin-left: 0.5rem;
}
</style>
