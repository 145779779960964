import { defineStore } from 'pinia';
import TucumcariClient from "@/api/tucumcari";
import { getMockHazards } from '@/services/mockData';

export const useHazardStore = defineStore('hazards', {
  state: () => ({
    hazards: [],
  }),
  getters: {
    allHazards: (state) => state.hazards,
  },
  actions: {
    async fetchMockHazards() {
      const hazards = await getMockHazards();
      this.hazards = hazards;
    },
    async fetchHazards() {
      const client = new TucumcariClient();
      try {
        const hazards = await client.getAllHazard();
        console.log("API Response:", hazards); // Log the hazards data
        //this.hazards = hazards;
      } catch (error) {
        console.error("Failed to fetch hazards:", error);
      }
    },
  },
});
