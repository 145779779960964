<script setup>
import "@aws-amplify/ui-vue/styles.css";
import { onBeforeMount, onBeforeUnmount } from 'vue';
// import { useMainStore } from '@/stores/index';
import { Authenticator } from "@aws-amplify/ui-vue";

// const store = useMainStore();
// const { toggleEveryDisplay, toggleHideConfig } = store;
const body = document.getElementsByTagName("body")[0];

onBeforeMount(() => {
  // toggleEveryDisplay();
  // toggleHideConfig();
  body.classList.remove("bg-gray-100");
});

onBeforeUnmount(() => {
  // toggleEveryDisplay();
  // toggleHideConfig();
  body.classList.add("bg-gray-100");
});
</script>

<template>
  <div
    class="page-header align-items-start min-vh-100"
    style="background-image: url('https://tucumcari.co.nz/wp-content/uploads/2023/07/Piles-2low.jpg');"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-6 col-md-8 col-12 mx-auto">
          <div class="card z-index-0">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-dark shadow-info border-radius-lg py-3 pe-1">
                <router-link to="/">
                  <img
                    src="@/assets/img/TucumcariLogo-Full-TransaprentBack.png"
                    class="navbar-brand-img w-50 pb-3 mx-auto d-block position-relative"
                    alt="main_logo"
                  />
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <Authenticator :hide-sign-up="true"></Authenticator>
              <p class="mt-4 text-sm text-center">
                Note: You will need to sign in again to select a new location
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              ©{{ new Date().getFullYear() }}, developed and delivered by
              <a
                href="https://firecrest.co.nz"
                class="font-weight-bold text-white"
                target="_blank"
              >
                Firecrest Systems
              </a>.
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul class="nav nav-footer justify-content-center justify-content-lg-end">
              <li class="nav-item">
                <a
                  href="https://tucumcari.co.nz/"
                  class="nav-link text-white"
                  target="_blank"
                >
                  Tucumcari Website
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://tucumcari.co.nz/contact/"
                  class="nav-link text-white"
                  target="_blank"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped>
/* Your styles here */
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
}
</style>
<style>
:root {
  --amplify-primary-color: #2b92cf !important; /* Change this to your desired color */
  --amplify-primary-tint: #0a5a8a !important; /*Change this to a lighter shade if needed */
  --amplify-primary-white: #ffffff !important; /*Change this to a darker shade if needed */
}
/* You can also override specific button classes if needed 
.amplify-button {
  background-color: var(--amplify-primary-color);
  border-color: var(--amplify-primary-shade);

}
.amplify-button:hover {
  background-color: var(--amplify-primary-tint);
}*/
.amplify-button--primary {
  --amplify-internal-button-background-color: var(--amplify-primary-color);
}
.amplify-button--primary:hover {
  --amplify-internal-button-background-color: var(--amplify-primary-tint);
}
.amplify-button--link {
  --amplify-internal-button-color: var(--amplify-primary-color);
}
.amplify-button--link:hover {
  --amplify-internal-button-background-color: var(--amplify-primary-white);
  --amplify-internal-button-border-color: var(---amplify-primary-white);
  --amplify-internal-button-color: var(--amplify-primary-color);
}
[data-amplify-authenticator] [data-amplify-router] {
  background-color: transparent !important;
  box-shadow: none !important;
  border-color: transparent !important;
  border-width: 0 !important;
  border-style: none !important;
}
</style>
