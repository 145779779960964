<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useTemplateStore } from '@/stores/template';
import { DataTable } from 'simple-datatables';
import { useRouter } from 'vue-router';

// Props
const props = defineProps({
  filterByStatus: {
    type: String,
    default: 'Active',
  },
});

// Pinia store
const templateStore = useTemplateStore();
const dataTable = ref(null);
const router = useRouter();

// Fetch templates from the store
const fetchTemplates = async () => {
  await templateStore.fetchMockTemplates();
  // await templateStore.fetchTemplates();
  filterTable(props.filterByStatus);
  initDataTable();
};

// Get templates from the store
const templates = computed(() => templateStore.allMockTemplates);

const filterTable = (status) => {
  const tableBody = document.querySelector('#templates tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    const statusCell = row.querySelector('td:nth-child(1)');
    const typeCell = row.querySelector('td:nth-child(2)');
    if (status === 'Active' && statusCell.textContent.trim() === status) {
      row.style.display = '';
    } else if (status === 'Permit' && typeCell.textContent.trim() === status) {
      row.style.display = '';
    } else if (status === 'Site Work' && typeCell.textContent.trim() === status) {
      row.style.display = '';
    } else if (status === 'Archived' && statusCell.textContent.trim() === status) {
      row.style.display = '';
    } else {
      row.style.display = 'none';
    }
  });
};

// Initialize DataTable after fetching the templates
const initDataTable = () => {
  if (dataTable.value) {
    dataTable.value.destroy();
  }
  dataTable.value = new DataTable('#templates', {
    searchable: true,
    paging: false,
  });

  const tableBody = document.querySelector('#templates tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    row.addEventListener('click', () => {
      router.push(`/edit-template`);
    });
  });
};

// Fetch templates and initialize DataTable
onMounted(fetchTemplates);

// Watch for changes in filterByStatus to reinitialize the DataTable
watch(() => props.filterByStatus, (newStatus) => {
    filterTable(newStatus);
});
</script>

<template>
  <div class="table-responsive">
    <table id="templates" class="table table-flush">
      <thead class="thead-light">
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Template</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Code</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in templates" :key="item.ID" :data-id="item.ID">
          <td class="text-sm font-weight-normal">
            <button
              class="btn w-100 m-0 px-1 py-0 text-white"
              :class="{ new: item.Status == 'Active', cancelled: item.Status == 'Archived' }"
            >
              {{ item.Status }}
            </button>
          </td>
          <td class="text-sm font-weight-normal">
            <button
              class="btn w-100 m-0 px-1 py-0 text-white"
              :class="{ waiting: item.Type == 'Permit', pending: item.Type == 'Site Work' }"
            >
              {{ item.Type }}
            </button>
          </td>
          <td class="text-sm font-weight-normal">{{ item.template }}</td>
          <td class="text-sm font-weight-normal">{{ item.code }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
tr {
  cursor: pointer;
}
</style>
