<script setup>
import { ref } from 'vue';
import AssetsTable from "@/components/tables/AssetsTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";

const filterByStatus = ref('all');

const showLinked = () => {
  filterByStatus.value = 'Linked';
};

const showActive = () => {
  filterByStatus.value = 'Active';
};

const showExpire = () => {
  filterByStatus.value = '24/04/2024';
};

const showArchived = () => {
  filterByStatus.value = 'Archived';
};

const showAll = () => {
  filterByStatus.value = 'all';
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <h4 class="m-3">Registry</h4>
      </div>
      <div class="col-6 text-end">
        <router-link to="/active-work" class="me-2">
          <MaterialButton variant="gradient" color="info">
            Registry Locations (Map View)
          </MaterialButton>
        </router-link>
        <router-link to="/add-asset" class="me-2">
          <MaterialButton variant="gradient" color="info">
            Add Item
          </MaterialButton>
        </router-link>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Item with an upcoming expiry</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning"
                  @click="showExpire"
                >
                  <h5 class="text-white mt-3">8</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Item linked to incidents</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning"
                  @click="showLinked"
                >
                  <h5 class="text-white mt-3">12</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="p-3 pb-0 card-body">
          <button class="btn" @click="showAll">All</button>
          <button class="btn" @click="showActive">Active</button>
          <button class="btn" @click="showExpire">Upcoming Expiry</button>
          <button class="btn" @click="showLinked">Linked to incidents</button>
          <button class="btn" @click="showArchived">Archived</button>
        </div>
        <div class="card">
          <div class="card-body">
            <AssetsTable :filterByStatus="filterByStatus" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
tr,
.bg-gradient-info,
.icon-lg {
  cursor: pointer;
}
</style>
