<template>
    <div v-show="tab === 'active'" class="p-3 pt-0 card-body mx-4 my-4">
      <div class="row mt-4">
        <div class="col-12 col-sm-6">
          <material-input
            id="title"
            label="Title"
            variant="static"
            class="mt-2 ms-0"
            :value="data.inputs.title"
            :disabled="isDisabled"
            @update:value="(val) => '(data.inputs.title = val)'"
          />
          <div v-if="isDisabled">
            <label class="mt-4 ms-0">Location</label>
            <option class="isDisabled">{{ data.inputs.location }}</option>
          </div>
          <div v-show="!isDisabled">
            <label class="mt-4 ms-0">Location</label>
            <select id="location-edit" class="form-control" name="Location">
              <option value="That Place" selected>Storm sewer manhole</option>
              <option value="Left Area">Left Area</option>
              <option value="The Bank">The Bank</option>
            </select>
          </div>
          <label class="mt-4 ms-0">Last updated by</label>
          <option class="disabledLink"><a href="#">{{ data.inputs.updatedBy }}</a></option>
          <div v-if="isDisabled">
            <label class="mt-4 ms-0">Reviewer</label>
            <option class="disabledLink"><a href="#">{{ data.inputs.reviewer }}</a></option>
          </div>
          <div v-show="!isDisabled">
            <label class="mt-4 ms-0">Reviewer</label>
            <select id="reviewer-edit" class="form-control" name="Related">
              <option value="Rufus Dogface">Rufus Dogface - Contractor</option>
              <option value="Bob Guy">Bob Guy</option>
              <option value="The Electrician">The Electrician - Contractor</option>
              <option value="Fun Time">Fun Times</option>
              <option value="Mary Onthestop" selected>Mary Onthestop</option>
              <option value="Plumbers">Plumbers - Contractor</option>
              <option value="Those helpful guys">Those helpful guys - Contractor</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-2 ms-0">Status</label>
          <button
            class="btn w-100 mb-2 p-0 text-white"
            :class="{
              draft: data.inputs.status == 'New',
              review: data.inputs.status == 'In Review',
              openIcid: data.inputs.status == 'Open Incident' || 'Open Hazard',
              closed: data.inputs.status == 'Closed',
            }"
          >
            {{ data.inputs.status }}
          </button>
          <div>
            <label class="mt-4 ms-0">Last updated</label>
            <option class="disabledLink">{{ data.inputs.lastUpdated }}</option>
          </div>
          <div v-if="isDisabled">
            <label class="mt-4 ms-0">Incident occurred</label>
            <option class="isDisabled">{{ data.inputs.incidentOccurred }}</option>
          </div>
          <!-- <div class="input-group input-group-static">
            <span class="input-group-text"><i class="fas fa-calendar"></i></span>
            <flatPickr
              v-model="date"
              :value="data.inputs.incidentOccurred"
              :disabled="isDisabled"
              class="form-control datetimepicker"
              :config="{
                allowInput: true,
                altInput: true,
                enableTime: true,
                defaultDate: '2024-04-19T12:00:00'
              }"
            />
          </div> -->
          <div>
            <label class="mt-4 ms-0">Reviewed On</label>
            <option class="disabledLink">Not yet reviewed</option>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label class="mt-2 ms-0">Description</label>
        <material-textarea
          id="description"
          variant="static"
          class="mt-2 ms-0"
          :value="data.inputs.description"
          :disabled="isDisabled"
          @update:value="(val) => '(data.inputs.description = val)'"
        ></material-textarea>
        <div v-if="isDisabled">
          <label v-if="data.inputs.hazards" class="mt-4 ms-0">Associated Hazards</label>
          <label v-else class="mt-4 ms-0">Associated Incidents</label>
          <material-textarea
          id="description"
          variant="static"
          class="mt-2 ms-0"
          :value="data.inputs.hazards || data.inputs.incidents"
          :disabled="isDisabled"
          @update:value="(val) => '(data.inputs.hazards = val)'"
        ></material-textarea>
        </div>
        <div v-show="!isDisabled">
          <label v-if="data.inputs.hazards" class="mt-4 ms-0">Associated Hazards</label>
          <label v-else class="mt-4 ms-0">Associated Incidents</label>
          <select id="hazard-edit" multiple class="form-control" name="hazards">
            <option value="Oxygen-deficient atmospheres" selected>Oxygen-deficient atmospheres</option>
            <option value="Toxic gases, vapours, dusts or fumes" selected>Toxic gases, vapours, dusts or fumes</option>
            <option value="Flammable gases vapours or dusts" selected>Flammable gases vapours or dusts</option>
            <option value="Engulfment" selected>Engulfment</option>
            <option value="Operation of moving parts" selected>Operation of moving parts</option>
            <option value="Radiation" selected>Radiation</option>
            <option value="Uncontrolled introduction of steam, water, gas or liquid" selected>Uncontrolled introduction of steam, water, gas or liquid</option>
            <option value="Extreme temperature, High noise levels" selected>Extreme temperature, High noise levels</option>

          </select>
        </div>
        <div v-if="isDisabled">
          <label class="mt-4 ms-0">Associated Assets</label>
          <option class="disabledLink"><a href="#">{{ data.inputs.asset }}</a></option>
        </div>
        <div v-show="!isDisabled">
          <label class="mt-4 ms-0">Associated Assets</label>
          <select id="asset-edit" multiple class="form-control" name="assets">
            <option value="Saws" selected>Saws</option>
            <option value="Lifts" selected>Lifts</option>
            <option value="Vehicle">Vehicle</option>
            <option value="Ship">Ship</option>
          </select>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MaterialInput from "@/components/materialKit/MaterialInput.vue";
  import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
  
  export default {
    name: "SummaryTab",
    components: { MaterialInput, MaterialTextarea },
    props: {
      tab: {
        type: String,
        required: true,
      },
      data: {
        type: Object,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        required: true,
      },
    },
  };
  </script>
  