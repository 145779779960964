<script setup>
import { defineProps, onMounted } from 'vue';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';

defineProps({
  tab: {
    type: String,
    required: true,
  },
  permitFiles: {
    type: Array,
    required: true,
  },
});

onMounted(() => {
  new Dropzone("#permitFiles", {
    url: "/file-upload",
    init: function () {
      this.on("addedfile", function (file) {
        console.log(`File added: ${file.name}`);
      });
    }
  });
});
</script>

<template>
  <div v-show="tab === 'extra'" class="p-3 pb-0 card-body">
    <div class="mt-3 row">
      <h4>Supporting files</h4>
      <div
        v-for="(permitFile, index) in permitFiles"
        :key="index"
        class="col-3"
      >
        <div class="text-center fileBlock mt-2">
          <a :href="permitFile.link" target="_blank">
            <span class="material-icons">{{ permitFile.icon }}</span>
            <p class="text-sm">{{ permitFile.fileName }}</p>
          </a>
        </div>
      </div>
      <div class="col-12 mb-4">
        <label class="form-control mb-0">Upload files</label>
        <div
          id="permitFiles"
          action="/file-upload"
          class="form-control border dropzone"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fileBlock {
  border-radius: 10px;
  border: solid 1px #ebebeb;
  padding: 10px;
}
</style>
