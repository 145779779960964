<template>
    <div class="p-3 pt-0 card-body mx-4 my-4">
      <div class="row mt-4">
        <div class="col-12 col-sm-8">
          <material-input
            id="title"
            label="Title"
            variant="static"
            class="mt-2 ms-0"
            :value="data.inputs.title"
            :disabled="isDisabled"
            @update:value="(val) => '(data.inputs.title = val)'"
          />
          <material-input
            id="make"
            label="Make and Model"
            variant="static"
            class="mt-4 ms-0"
            :value="data.inputs.make"
            :disabled="isDisabled"
            @update:value="(val) => '(data.inputs.make = val)'"
          />
          <material-input
            id="purchased"
            label="Purchased from"
            variant="static"
            class="mt-4 ms-0"
            :value="data.inputs.purchased"
            :disabled="isDisabled"
            @update:value="(val) => '(data.inputs.purchased = val)'"
          />
          <material-input
            id="warranty"
            label="Warranty"
            variant="static"
            class="mt-4 ms-0"
            :value="data.inputs.warranty"
            :disabled="isDisabled"
            @update:value="(val) => '(data.inputs.warranty = val)'"
          />
          <material-input
            id="licence"
            label="Licence"
            variant="static"
            class="mt-4 ms-0"
            :value="data.inputs.licence"
            :disabled="isDisabled"
            @update:value="(val) => '(data.inputs.licence = val)'"
          />
        </div>
  
        <div class="col-12 col-sm-4">
          <div>
            <label class="ms-0">Purchase date</label>
            <option class="disabledLink">April 24 2012</option>
          </div>
  
          <label class="mt-4 ms-0">Next Service Due</label>
          <div class="input-group input-group-static">
            <span class="input-group-text"><i class="fas fa-calendar"></i></span>
            <flatPickr
              v-model="date"
              :disabled="isDisabled"
              class="form-control datetimepicker"
              :config="{
                allowInput: true,
                altInput: true,
                enableTime: false,
                defaultDate: '2024-04-19T12'
              }"
            />
          </div>
        </div>
      </div>
  
      <div class="col-12">
        <label class="mt-2 ms-0">Description</label>
        <material-textarea
          id="description"
          variant="static"
          class="mt-2 ms-0"
          :value="data.inputs.description"
          :disabled="isDisabled"
          @update:value="(val) => '(data.inputs.description = val)'"
        ></material-textarea>
      </div>
    </div>
  </template>
  
  <script>
  import flatPickr from "vue-flatpickr-component";
  import MaterialInput from "@/components/materialKit/MaterialInput.vue";
  import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
  
  export default {
    name: "SummaryTab",
    components: {
      flatPickr,
      MaterialInput,
      MaterialTextarea,
    },
    props: {
      data: Object,
      isDisabled: Boolean,
    },
  };
  </script>
  