<script setup>
import { onMounted, watch, ref, onBeforeUnmount } from 'vue';
import { usePermitsStore } from '@/stores/permits';
import Choices from 'choices.js';
import MaterialCheckbox from "@/components/materialKit/MaterialCheckbox.vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";

// Use the Pinia store
const permitsStore = usePermitsStore();
let choicesInstances = {}; // Store Choices instances
const customActivity = ref(""); // For adding custom activities
const customRisk = ref(""); // For adding custom risks

// Initialize Choices for the dropdowns
const initializeChoices = (id, defaultValue) => {
  const element = document.getElementById(id);
  if (element) {
    if (choicesInstances[id]) {
      choicesInstances[id].destroy();
    }
    const choicesInstance = new Choices(element, {
      searchEnabled: true,
      allowHTML: true,
    });
    element.choicesInstance = choicesInstance;
    if (defaultValue) {
      choicesInstance.setChoiceByValue(defaultValue);
    }
    choicesInstances[id] = choicesInstance;
    return choicesInstance;
  }
};

// Watch for when the Conflicts step becomes active
watch(() => permitsStore.activeStep, (newStep) => {
  if (newStep === 2) {  // Assuming 1 is the index for the Conflicts tab
    console.log('Activities and Risks tab is now active');

    permitsStore.standardActivities;
    permitsStore.risks;

    console.log('Standard Activities:', permitsStore.standardActivities);
    console.log('Critical Risks:', permitsStore.risks);
  }
});

onMounted(() => {
  // Initialize dropdown for activities
  initializeChoices('activities', permitsStore.selectedActivities);
});

// Clean up Choices instances before the component is destroyed
onBeforeUnmount(() => {
  Object.keys(choicesInstances).forEach((id) => {
    if (choicesInstances[id]) {
      choicesInstances[id].destroy();
    }
  });
  choicesInstances = {}; // Clear the instances after destruction
});

// Watch for changes in custom activity input and checkboxes for activities/risks
watch(
  () => {
    const element = document.getElementById('activities');
    if (element) {
      return Array.from(element.selectedOptions).map(option => option.value);
    }
    return [];
  },
  (newValues) => {
    permitsStore.setSelectedActivities(newValues);
  }
);

// Method to handle adding custom activities and risks
const addCustomActivity = () => {
  if (customActivity.value.trim()) {
    permitsStore.addActivity(customActivity.value);
    customActivity.value = ""; // Clear input after adding
  }
};

const addCustomRisk = () => {
  if (customRisk.value.trim()) {
    permitsStore.addRisk(customRisk.value);
    customRisk.value = ""; // Clear input after adding
  }
};

// Method to handle activity updates
const updateActivity = (activity, isChecked) => {
  if (isChecked && !permitsStore.standardActivities.includes(activity)) {
    permitsStore.addActivity(activity);
  } else if (!isChecked) {
    permitsStore.standardActivities = permitsStore.standardActivities.filter(a => a !== activity);
  }
};

// // Method to handle risk updates
const updateRisk = (risk, isChecked) => {
  if (isChecked && !permitsStore.risks.includes(risk)) {
    permitsStore.addRisk(risk);
  } else if (!isChecked) {
    permitsStore.risks = permitsStore.risks.filter(r => r !== risk);
  }
};
</script>

<template>
  <div class="pt-3 bg-white multisteps-form__panel border-radius-xl js-active position-relative"
    data-animation="FadeIn">
    <div class="multisteps-form__content">
      <div class="row mt-4 text-start">
        <h4>Standard Activities</h4>
        <div class="d-flex align-items-center ">
          <material-input
            id="add-act"
            v-model="customActivity"
            label="Add Standard Activity"
            variant="static"
            class="flex-grow-1 me-2 w-75"
          />
          <button type="button" class="btn btn-info mt-4" @click="addCustomActivity">
            ADD ACTIVITY
          </button>
        </div>
        <!-- Standard Activities Checkboxes -->
        <div class="col-12 col-sm-3">
          <material-checkbox :checked="permitsStore.standardActivities.includes('Work at Heights')"
            @change="updateActivity('Work at Heights', $event.target.checked)">
            Work at Heights
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Confined Space Entry')"
            @change="updateActivity('Confined Space Entry', $event.target.checked)">
            Confined Space Entry
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Hazardous Substance')"
            @change="updateActivity('Hazardous Substance', $event.target.checked)">
            Hazardous Substance
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Mobile Plant')"
            @change="updateActivity('Mobile Plant', $event.target.checked)">
            Mobile Plant
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Roof Access')"
            @change="updateActivity('Roof Access', $event.target.checked)">
            Roof Access
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Power tools')"
            @change="updateActivity('Power Tools', $event.target.checked)">
            Power Tools
          </material-checkbox>
        </div>

        <div class="col-12 col-sm-3">
          <material-checkbox :checked="permitsStore.standardActivities.includes('Excavation >300mm')"
            @change="updateActivity('Excavation >300mm', $event.target.checked)">
            Excavation >300mm
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Excavation >1.5m')"
            @change="updateActivity('Excavation >1.5m', $event.target.checked)">
            Excavation >1.5m
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Working with chemicals')"
            @change="updateActivity('Working with Chemicals', $event.target.checked)">
            Working with Chemicals
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Energised electrical work')"
            @change="updateActivity('Energised electrical work', $event.target.checked)">
            Energised electrical work
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Breakwater Access')"
            @change="updateActivity('Breakwater Access', $event.target.checked)">
            Breakwater Access
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Workshop Machinery')"
            @change="updateActivity('Workshop Machinery', $event.target.checked)">
            Workshop Machinery
          </material-checkbox>
        </div>

        <div class="col-12 col-sm-3">
          <material-checkbox :checked="permitsStore.standardActivities.includes('Hot Works')"
            @change="updateActivity('Hot Works', $event.target.checked)">
            Hot Works
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Punt')"
            @change="updateActivity('Punt', $event.target.checked)">
            Punt
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Forklift Cage')"
            @change="updateActivity('Forklift Cage', $event.target.checked)">
            Forklift Cage
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Palfinger')"
            @change="updateActivity('Palfinger', $event.target.checked)">
            Palfinger
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Pits')"
            @change="updateActivity('Pits', $event.target.checked)">
            Pits
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Welding or Gas Cutting')"
            @change="updateActivity('Welding or Gas Cutting', $event.target.checked)">
            Welding or Gas Cutting
          </material-checkbox>
        </div>

        <div class="col-12 col-sm-3">
          <material-checkbox :checked="permitsStore.standardActivities.includes('Pavement Inspection')"
            @change="updateActivity('Pavement Inspection', $event.target.checked)">
            Pavement Inspection
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Building Inspections')"
            @change="updateActivity('Building Inspections', $event.target.checked)">
            Building Inspections
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Survey Boat Operations')"
            @change="updateActivity('Survey Boat Operations', $event.target.checked)">
            Survey Boat Operations
          </material-checkbox>
          <material-checkbox :checked="permitsStore.standardActivities.includes('Bollard Removal/Exchange')"
            @change="updateActivity('Bollard Removal/Exchange', $event.target.checked)">
            Bollard Removal/Exchange
          </material-checkbox>
        </div>
               <!-- Risks Section -->
               <div class="col-12 mt-4">
          <h4>Critical Risks</h4>
          <div class="d-flex align-items-center">
            <material-input
              id="add-risk"
              v-model="customRisk"
              label="Add Risk"
              variant="static"
              class="flex-grow-1 me-2 w-75"
            />
            <button type="button" class="btn btn-info mt-4" @click="addCustomRisk">
              ADD RISK
            </button>
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <material-checkbox :checked="permitsStore.risks.includes('Asbestos Exposure')"
            @change="updateRisk('Asbestos Exposure', $event.target.checked)">
            Asbestos Exposure
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Body Injury')"
            @change="updateRisk('Body Injury', $event.target.checked)">
            Body Injury
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Eye Damage')"
            @change="updateRisk('Eye Damage', $event.target.checked)">
            Eye Damage
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Burns')"
            @change="updateRisk('Burns', $event.target.checked)">
            Burns
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Trips Slips Falls')"
            @change="updateRisk('Trips Slips Falls', $event.target.checked)">
            Trips, Slips, Falls
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Strain')"
            @change="updateRisk('Strain', $event.target.checked)">
            Strain
          </material-checkbox>
        </div>

        <div class="col-12 col-sm-3">
          <material-checkbox :checked="permitsStore.risks.includes('Chemical exposure')"
            @change="updateRisk('Chemical exposure', $event.target.checked)">
            Chemical exposure
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Collision')"
            @change="updateRisk('Collision', $event.target.checked)">
            Collision
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Engulfment')"
            @change="updateRisk('Engulfment', $event.target.checked)">
            Engulfment
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Electrocution')"
            @change="updateRisk('Electrocution', $event.target.checked)">
            Electrocution
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Explosion')"
            @change="updateRisk('Explosion', $event.target.checked)">
            Explosion
          </material-checkbox>
        </div>

        <div class="col-12 col-sm-3">
          <material-checkbox :checked="permitsStore.risks.includes('Crush')"
            @change="updateRisk('Crush', $event.target.checked)">
            Crush
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Sprains and Strains')"
            @change="updateRisk('Sprains and Strains', $event.target.checked)">
            Sprains and Strains
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Hearing Loss')"
            @change="updateRisk('Hearing Loss', $event.target.checked)">
            Hearing Loss
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Drowning')"
            @change="updateRisk('Drowning', $event.target.checked)">
            Drowning
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Falling Objects')"
            @change="updateRisk('Falling Objects', $event.target.checked)">
            Falling Objects
          </material-checkbox>
        </div>

        <div class="col-12 col-sm-3">
          <material-checkbox :checked="permitsStore.risks.includes('Falling from Height')"
            @change="updateRisk('Falling from Height', $event.target.checked)">
            Falling from Height
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Asphyxiation')"
            @change="updateRisk('Asphyxiation', $event.target.checked)">
            Asphyxiation
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Noxious Gases')"
            @change="updateRisk('Noxious Gases', $event.target.checked)">
            Noxious Gases
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Fire')"
            @change="updateRisk('Fire', $event.target.checked)">
            Fire
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Foot Injury')"
            @change="updateRisk('Foot Injury', $event.target.checked)">
            Foot Injury
          </material-checkbox>
          <material-checkbox :checked="permitsStore.risks.includes('Hand Injury')"
            @change="updateRisk('Hand Injury', $event.target.checked)">
            Hand Injury
          </material-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
