import { defineStore } from "pinia";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { useSystemUserStore } from "./systemUser";
import { useArcGISStore } from "./arcgisStore";


export const useAuthStore = defineStore("auth", {
  state: () => ({
    loginId: "",
    userDetails: null,
    isAuthenticated: false,
    // roles: [],
    role: '',
    error: null,
  }),
  actions: {
    async fetchLoginId() {
      const arcgisStore = useArcGISStore();
      try {
        const session = await fetchAuthSession();
        if (session.tokens) {
          await arcgisStore.fetchAccessToken();
          this.setAuthenticated(true);
          const user = await getCurrentUser();
          this.loginId = user.signInDetails.loginId; 
          await this.fetchSystemUserDetails();
        }
      } catch (error) {
        console.error("Error fetching login ID:", error);
        this.setAuthenticated(false);
        this.error = error; // Store the error in case it occurs
      }
    },
    async fetchSystemUserDetails() {
      const systemUserStore = useSystemUserStore(); // Get access to the systemUser store
      await systemUserStore.fetchSystemUsers(); // Fetch all system users

      // Match the loginId from auth store to the system user's email or another unique field
      const matchedUser = systemUserStore.systemUsers.find(
        (user) => user.Email === this.loginId
      );
      console.log("Match User", matchedUser);
      console.log("Login ID", this.loginId);

      this.userDetails = matchedUser;
      if (this.userDetails) {
        // this.roles = matchedUser.Role.Title || [];
        this.role = matchedUser.Role?.Title || '';
      } else {
        console.error(
          "No matching system user found for loginId:",
          this.loginId
        );
      }
    },
    setAuthenticated(status) {
      this.isAuthenticated = status; // Explicitly set the isAuthenticated flag
    },
    logout() {
      this.loginId = '',
      this.userDetails = null;
      this.isAuthenticated = false;
      this.role = '';
      this.error = null;
    }
  },
  getters: {
    systemUserDetails(state) {
      return state.userDetails;
    },
    userRole(state) {
      return state.role;

    },
    userOrganisation(state) {
      return state.userDetails?.Organisations || [];
    },
  },
});
