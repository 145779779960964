import { defineStore } from "pinia";
import axios from "axios";
import TucumcariClient from "@/api/tucumcari";
// import moment from 'moment';

export const useArcGISStore = defineStore("arcgis", {
  state: () => ({
    accessToken: null,
    tokenExpiryTime: null,
    clientId: "",
    clientSecret: "",
    serviceUrl: null,
    layers: [],
    allItemData: [],
    error: null,
    userInfo: null,
    webMaps: [],
    selectedPort: null,
    isLoading: false,
  }),
  actions: {
    async fetchOauthInfo() {
      try {
        const client = new TucumcariClient();
        const response = await client.getOauthInfo();
        const responseData = await response.response;
        const data = await responseData.body.json();

        console.log("OAuth Info: ", data);
        this.clientId = data.clientId;
        this.clientSecret = data.clientSecret;

      } catch (error) {
        console.error("Failed to fetch OAuth Info:", error);
        this.error = "Failed to fetch ArcGIS access token.";
      }
    },
    async fetchAccessToken() {
      const tokenEndpoint = "https://www.arcgis.com/sharing/rest/oauth2/token";

      try {
        const params = new URLSearchParams();
        params.append("client_id", this.clientId);
        params.append("client_secret", this.clientSecret);
        params.append("grant_type", "client_credentials");

        const response = await axios.post(tokenEndpoint, params, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        const { access_token, expires_in } = response.data;
        console.log(response.data);
        this.accessToken = access_token;
        this.tokenExpiryTime = Date.now() + expires_in * 1000;

        await this.fetchPortalInfo();
        await this.fetchWebMaps();
      } catch (error) {
        this.error = "Failed to fetch ArcGIS access token.";
        console.error(this.error, error);
      }
    },
    // async fetchAccessToken() {
    //     try {
    //         const client = new TucumcariClient();
    //         const response = await client.getArcgisAccessToken();
    //         const responseData = await response.response;
    //         const data = await responseData.body.json();

    //         console.log('Access Token: ', data);
    //         this.accessToken = data.accessToken;
    //         this.tokenExpiryTime = moment(data.expiryTime).format('LLLL');

    //         // this.startExpiryCheck();
    //         await this.fetchPortalInfo();
    //         await this.fetchWebMaps();
    //     } catch (error) {
    //         console.error('Failed to fetch ArcGIS access token.', error);
    //         this.error = 'Failed to fetch ArcGIS access token.';
    //     }
    // },

    // startExpiryCheck() {
    //     const timeUntilExpiry = moment(this.tokenExpiryTime).diff(moment(), 'milliseconds');
    //     setTimeout(() => {
    //         alert('Session expired. Please sign in again.');
    //         this.logout();
    //     }, timeUntilExpiry);
    // },

    logout() {
      this.accessToken = null;
      this.tokenExpiryTime = null;
      this.serviceUrl = null;
      this.layers = [];
      this.allItemData = [];
      this.userInfo = null;
      this.webMaps = [];
      this.clientId = "";
      this.clientSecret = "";
    },

    async initializeArcGISData() {
      try {
        this.isLoading = true;
        await this.searchFeatureService();
        if (this.serviceUrl) {
          await this.fetchLayersInService(this.serviceUrl);
          if (this.layers.length > 0) {
            await this.fetchAllLayerDetails();
            await this.fetchAllItemData();
          } else {
            throw new Error("No layers found in the service.");
          }
        } else {
          throw new Error("No service URL available to fetch layers.");
        }
      } catch (error) {
        console.error(error);
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async fetchPortalInfo() {
      try {
        const response = await axios.get(
          "https://www.arcgis.com/sharing/rest/portals/self",
          {
            params: { f: "json" },
            headers: { Authorization: `Bearer ${this.accessToken}` },
          }
        );
        this.userInfo = response.data;
        console.log("user info: ", this.userInfo);
      } catch (error) {
        console.error("Failed to fetch portal info from ArcGIS.", error);
        this.error = "Failed to fetch portal info from ArcGIS.";
      }
    },

    async searchFeatureService() {
      try {
        const response = await axios.get(
          "https://www.arcgis.com/sharing/rest/search",
          {
            params: {
              // q: `id: ${this.selectedPort} AND feature layer`,
              q: `id:cfd4a80b17964f20a133e1b9c82876ee`,
              f: "json",
              token: this.accessToken,
            },
          }
        );
        if (response.data.results.length > 0) {
          this.serviceUrl = response.data.results[0].url;
        } else {
          throw new Error("No services found matching the query.");
        }
      } catch (error) {
        console.error("Failed to search for Feature Service:", error);
        this.error = "Failed to search for Feature Service.";
      }
    },

    async fetchLayerDetails(layerId) {
      try {
        const layerUrl = `${this.serviceUrl}/${layerId}`;
        const response = await axios.get(layerUrl, {
          params: {
            f: "json",
            token: this.accessToken,
          },
        });
        return response.data;
      } catch (error) {
        console.error(
          `Failed to fetch details for layer ID ${layerId}.`,
          error
        );
        return null;
      }
    },

    async fetchAllLayerDetails() {
      const detailedLayers = [];
      for (const layer of this.layers) {
        const details = await this.fetchLayerDetails(layer.id);
        if (details) detailedLayers.push(details);
      }
      this.layers = detailedLayers;
    },

    async fetchLayersInService(serviceUrl) {
      try {
        const response = await axios.get(serviceUrl, {
          params: {
            f: "json",
            token: this.accessToken,
          },
        });
        this.layers = response.data.layers || [];
      } catch (error) {
        console.error(
          "Failed to fetch layers from the Feature Service.",
          error
        );
        this.error = "Failed to fetch layers from the Feature Service.";
      }
    },

    async fetchAllItemData() {
      try {
        const allItems = [];
        for (const layer of this.layers) {
          const layerUrl = `${this.serviceUrl}/${layer.id}/query`;
          const response = await axios.get(layerUrl, {
            params: {
              f: "json",
              where: "1=1",
              outFields: "*",
              token: this.accessToken,
            },
          });
          const layerItemData = response.data.features.map((feature) => ({
            ...feature.attributes,
            layerId: layer.id,
            layerName: layer.name,
          }));
          allItems.push(...layerItemData);
        }
        this.allItemData = allItems;
      } catch (error) {
        console.error("Failed to fetch item data from all layers.", error);
        this.error = "Failed to fetch item data from all layers.";
      }
    },

    async fetchWebMaps() {
      try {
        const response = await axios.get(
          "https://www.arcgis.com/sharing/rest/search",
          {
            params: {
              q: `type:"Web Map" AND owner:"${this.userInfo?.appInfo?.appOwner || ""}"`,
              f: "json",
              token: this.accessToken,
              num: 100,
              sortField: "title",
              sortOrder: "asc",
            },
          }
        );
        this.webMaps = response.data.results || [];
        console.log("Fetch WebMaps:", this.webMaps);
      } catch (error) {
        console.error("Failed to fetch web maps.", error);
        this.error = "Failed to fetch web maps.";
      }
    },
  },
  getters: {
    appOwner: (state) => state.userInfo?.appInfo?.appOwner || "",
    allPortWebMapId: (state) => state.webMaps[0],
    napierPortWebMapId: (state) => state.webMaps[1],
    allItems: (state) => state.allItemData,
  },
});
