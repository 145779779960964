<script setup>
import { useRoute } from 'vue-router';
import SidenavItem from './SidenavItem.vue';
import SidenavCollapse from './SidenavCollapse.vue';
import { hasPermission } from '@/utils/checkPermission';

// Composition API - using Vue Router
const route = useRoute();

function getRoute() {
  const routeArr = route.path.split('/');
  return routeArr[1];
}
</script>
<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100 main"
  >
    <ul class="navbar-nav">
      <li v-if="hasPermission('Dashboard')" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <router-link class="nav-link text-white" to="/index">
          <i class="material-icons-round opacity-10">dashboard</i>
          <span class="nav-link-text ms-2">Dashboard</span>
        </router-link>
      </li>
      <li v-if="hasPermission('Active Work')" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <router-link class="nav-link text-white" to="/active-work">
          <i class="material-icons-round opacity-10">my_location</i>
          <span class="nav-link-text ms-2">Active Work</span>
        </router-link>
      </li>
      <li v-if="hasPermission('Reports')" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <router-link class="nav-link text-white" to="/reports">
          <i class="material-icons-round opacity-10">summarize</i>
          <span class="nav-link-text ms-2">Reports</span>
        </router-link>
      </li>

      <li v-if="hasPermission('Permits')" class="mt-3 nav-item">
        <hr class="horizontal light mt-0" />
        <h6 class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder text-white">SITE</h6>
      </li>

      <li v-if="hasPermission('Permits')" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="Permits"
          nav-text="Permits/Site Works"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">list_alt</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item to="/activity-list" mini-icon="AL" text="Activity List" />
              <sidenav-item to="/add-activity" mini-icon="AA" text="Add Activity" />
              <sidenav-item to="/traffic-management" mini-icon="TM" text="Traffic Management" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li v-if="hasPermission('Safety')" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="Safety"
          nav-text="Safety"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">support</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item to="/incidents" mini-icon="I" text="Incidents" />
              <sidenav-item to="/hazards" mini-icon="H" text="Hazards" />
              <sidenav-item to="/report-issue" mini-icon="RI" text="Report Safety Issue" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li v-if="hasPermission('Registry')" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="Registry"
          nav-text="Registry"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">domain</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item to="/registry" mini-icon="RL" text="Registry List" />
              <sidenav-item to="/add-item" mini-icon="AI" text="Add Item" />
              <sidenav-item to="/stored-locations" mini-icon="SL" text="Stored Locations" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li v-if="hasPermission('People')" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="People"
          nav-text="People"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">assignment_ind</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item to="/contractors" mini-icon="C" text="Contractors" />
              <sidenav-item to="/personnel" mini-icon="P" text="Personnel" />
              <sidenav-item to="/add-people" mini-icon="AP" text="Add People" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li v-if="hasPermission('General Settings')" class="mt-3 nav-item">
        <hr class="horizontal light mt-0" />
        <h6 class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder text-white">SETTINGS</h6>
      </li>

      <li v-if="hasPermission('General Settings')" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="Settings"
          nav-text="General Settings"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">settings</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item to="/profile" mini-icon="P" text="My Area" />
              <sidenav-item to="/templates" mini-icon="T" text="Templates" />
              <sidenav-item to="/settings" mini-icon="G" text="Global Settings" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li v-if="hasPermission('User Manager')" class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="Manager"
          nav-text="User Manager"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">group</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item to="/role-manager" mini-icon="RM" text="Role Manager" />
              <sidenav-item to="/users" mini-icon="U" text="Users" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<style scoped>
/* Add your custom styles if needed */
</style>
