<template>
    <div id="closeModal" class="modal fade" tabindex="-1" aria-labelledby="closeModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="closeModal" class="modal-title">Close Incident</h5>
            <MaterialButton color="none" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></MaterialButton>
          </div>
          <div class="modal-body">
            <p>This incident is now closed, with follow-up actions.</p>
          </div>
          <div class="modal-footer justify-content-between">
            <MaterialButton variant="gradient" color="dark" data-bs-dismiss="modal">Cancel</MaterialButton>
            <MaterialButton variant="gradient" color="info" class="mb-0" data-bs-dismiss="modal">Close Incident</MaterialButton>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MaterialButton from "@/components/materialKit/MaterialButton.vue";
  
  export default {
    name: "CloseModal",
    components: { MaterialButton },
  };
  </script>
  