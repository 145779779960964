<template>
    <div id="contactModal" class="modal fade" tabindex="-1" aria-labelledby="contactModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="contactModal" class="modal-title">Add contact</h5>
            <MaterialButton color="none" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></MaterialButton>
          </div>
          <div class="modal-body">
            <form>
              <material-input id="link" label="Link to incident" variant="static" class="mt-4 ms-0" />
              <material-input id="mainContact" label="Main contact" variant="static" class="mt-4 ms-0" />
              <material-input id="company" label="Company" variant="static" class="mt-4 ms-0" />
              <material-input id="phone" label="Phone" variant="static" class="mt-4 ms-0" />
              <material-input id="email" label="Email" variant="static" class="mt-4 ms-0" />
            </form>
          </div>
          <div class="modal-footer justify-content-between">
            <MaterialButton variant="gradient" color="dark" data-bs-dismiss="modal">Cancel</MaterialButton>
            <MaterialButton variant="gradient" color="info" class="mb-0" data-bs-dismiss="modal">Add</MaterialButton>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MaterialButton from "@/components/materialKit/MaterialButton.vue";
  import MaterialInput from "@/components/materialKit/MaterialInput.vue";
  
  export default {
    name: "ContactModal",
    components: { MaterialButton, MaterialInput },
  };
  </script>
  