<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';
import { usePeopleStore } from '@/stores/people';
import { DataTable } from 'simple-datatables';
import { useRouter } from 'vue-router';
import { useSystemUserStore } from '@/stores/systemUser';

const props = defineProps({
  filterStatus: {
    type: String,
    default: 'all',
  },
});

const peopleStore = usePeopleStore();
const sysUserStore = useSystemUserStore();
const sysUser = sysUserStore.allSystemUsers;
const dataTable = ref(null);
const router = useRouter();

const fetchPeople = async () => {
  await peopleStore.fetchPeople();
  await nextTick();
  initDataTable();
  filterPeople();
};

const initDataTable = async () => {
  await nextTick();

  // Check if the permits table exists
  const peopleTable = document.querySelector("#people");

  if (!peopleTable) {
    console.error("People table not found. Make sure the DOM is rendered.");
    return; // Exit if table is not found
  }

  if (dataTable.value) {
    dataTable.value.destroy();
  }
  dataTable.value = new DataTable("#people", {
    searchable: true,
    fixedHeight: true,
  });

  // Add click event to rows
  const tableBody = document.querySelector("#people tbody");
  if (tableBody) {
    const rows = tableBody.querySelectorAll("tr");
    rows.forEach((row) => {
      const id = row.dataset.id;
      row.addEventListener("click", () => {
        router.push(`/person-profile/${id}`);
      });
    });
  }
};

const filterPeople = async () => {
  await nextTick();

  const tableBody = document.querySelector("#people tbody");
  if (tableBody) {
    const rows = tableBody.querySelectorAll("tr");
    rows.forEach((row) => {
      const statusCell = row.querySelector("td:nth-child(1)");
      if (statusCell) {
        if (props.filterStatus === "all") {
          row.style.display = "";
        } else {
          row.style.display = statusCell.textContent.trim() === props.filterStatus ? "" : "none";
        }
      }
    });
  }
};

watch(() => props.filterStatus, filterPeople);

onMounted(async () => {
  await fetchPeople();
});
</script>

<template>
  <div class="table-responsive">
    <table id="people" class="table table-flush">
      <thead class="thead-light">
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">User Type</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Company</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Role</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in sysUser" :key="item.ID" :data-id="item.ID">
          <td class="text-sm font-weight-normal">
            <button class="btn w-100 m-0 px-1 py-0 text-white" :class="{
              pending: item.Type === 'Contractor',
              waiting: item.Type === 'Port Personal',
              draft: item.Type === 'System User',
            }">
              {{ item.Type }}
            </button>
          </td>
          <td class="text-sm font-weight-normal">{{ item.Name }}</td>
          <td class="text-sm font-weight-normal">{{ item.Company }}</td>
          <td class="text-sm font-weight-normal">{{ item.Role.Title }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
tr {
  cursor: pointer;
}
</style>
