<script setup>
import { onMounted, watch, ref } from 'vue';
import BaseMap from "@/components/maps/baseMap.vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import { usePermitsStore } from '@/stores/permits';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { DateTime } from "luxon";

// Use the Pinia store
const permitsStore = usePermitsStore();

// Date fields
const isUpdating = ref(false);
const fromDate = ref(DateTime.now().plus({ hours: 4 }).toJSDate());
const toDate = ref(DateTime.now().plus({ hours: 5 }).toJSDate());

// Formatted dates (12-hour format with AM/PM)
const formattedFromDate = ref(DateTime.now().plus({ hours: 4 }).toFormat('d-M-yyyy h:mm a'));
const formattedToDate = ref(DateTime.now().plus({ hours: 5 }).toFormat('d-M-yyyy h:mm a'));

// Flatpickr config
const config = {
  enableTime: true,
  dateFormat: "d-m-Y h:i K", // 12-hour time format with AM/PM in flatpickr
};

// Watch for changes in fromDate and toDate and emit them
function emitDateChange() {
  if (!isUpdating.value) {
    isUpdating.value = true; // Set flag to true before emitting
    const event = new CustomEvent('flatPickrDateChanged', {
      detail: {
        fromDate: fromDate.value,
        toDate: toDate.value,
      }
    });
    document.dispatchEvent(event);
    
    // Reset flag after a small delay to prevent infinite loops
    setTimeout(() => {
      isUpdating.value = false;
    }, 100); 
  }
}

watch([fromDate, toDate], emitDateChange);

// On mounted, sync from TimeSlider
onMounted(() => {
  document.addEventListener('timeSliderUpdated', (event) => {
    if (!isUpdating.value) {
      isUpdating.value = true; // Set flag before updating flatpickr
      fromDate.value = DateTime.fromISO(event.detail.fromDate).toJSDate();
      toDate.value = DateTime.fromISO(event.detail.toDate).toJSDate();
      
      // Update formatted dates
      formattedFromDate.value = DateTime.fromJSDate(fromDate.value).toFormat('d-M-yyyy h:mm a');
      formattedToDate.value = DateTime.fromJSDate(toDate.value).toFormat('d-M-yyyy h:mm a');
      
      // Reset flag after a small delay
      setTimeout(() => {
        isUpdating.value = false;
      }, 100); 
    }
  });
});

// Watch date pickers to store the selected dates in Pinia
watch(fromDate, (newDate) => {
  permitsStore.setStartDate(newDate); // Directly set the date object
  formattedFromDate.value = DateTime.fromJSDate(newDate).toFormat('d-M-yyyy h:mm a'); // Update formatted date
});

watch(toDate, (newDate) => {
  permitsStore.setEndDate(newDate); // Directly set the date object
  formattedToDate.value = DateTime.fromJSDate(newDate).toFormat('d-M-yyyy h:mm a'); // Update formatted date
});
</script>

<template>
  <div class="pt-3 bg-white multisteps-form__panel border-radius-xl js-active position-relative"
    data-animation="FadeIn">
    <div class="multisteps-form__content">
      <div class="mt-3 row text-start">
        <h4>When the work is to be conducted</h4>
        <div class="col-12">
          <div class="mt-4">
            <material-input id="location" variant="static" label="Location" :value="permitsStore.workLocation"
              @update:value="permitsStore.setWorkLocation($event)" />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-3 ms-0">From</label>
          <div class="input-group input-group-static">
            <span class="input-group-text"><i class="fas fa-calendar"></i></span>
            <flat-pickr v-model="fromDate" class="form-control datetimepicker" :config="config" />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <label class="mt-3 ms-0">To</label>
          <div class="input-group input-group-static">
            <span class="input-group-text"><i class="fas fa-calendar"></i></span>
            <flat-pickr v-model="toDate" class="form-control datetimepicker" :config="config" />
          </div>
        </div>
        <div class="col-12">
          <div class="mt-4">
            <h4>You can select location and time on the map</h4>
            <BaseMap :mapType="'PlanningView'" :canDraw="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
