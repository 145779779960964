import { createRouter, createWebHistory } from "vue-router";
// import { fetchAuthSession } from 'aws-amplify/auth';
import { useAuthStore } from "@/stores/auth";
import { useArcGISStore } from "@/stores/arcgisStore";

import Index from "../pages/Index.vue";
import Reports from "../pages/Reports.vue";
import SignIn from "../pages/Auth/SignIn.vue";
import Reset from "../pages/Auth/Reset.vue";

import ActiveWork from "../pages/ActiveWork/Active.vue";

import Hazards from "../pages/Safety/Hazards.vue";
import Hazard from "../pages/Safety/Hazard.vue";
import Incidents from "../pages/Safety/Incidents.vue";
import Incident from "../pages/Safety/Incident.vue";
import ReportSafety from "../pages/Safety/safety-wizard/Report.vue";

import WorksList from "../pages/Permits/WorksList.vue";
import AddPermit from "../pages/Permits/permit-wizard/AddPermit.vue";
import Permit from "../pages/Permits/Permit.vue";
import TrafficManagement from "../pages/Permits/TrafficManagement.vue";

import AssetManagement from "../pages/Assets/AssetManagement.vue";
import AddAsset from "../pages/Assets/AddAsset.vue";
import Asset from "../pages/Assets/Asset.vue";
import StoredLocations from "../pages/Assets/StoredLocations.vue";

import RoleManager from "../pages/People/RoleManager.vue";
import Users from "../pages/People/Users.vue";
import Contractors from "../pages/People/Contractors.vue";
import Personnel from "../pages/People/Personnel.vue";
import PersonProfile from "../pages/People/PersonProfile.vue";
import AddPeople from "../pages/People/AddPeople.vue";

import Profile from "../pages/Settings/Profile.vue";
import Templates from "../pages/Settings/Templates.vue";
import EditTemplates from "../pages/Settings/template-wizard/EditTemplate.vue";
import GlobalSettings from "../pages/Settings/GlobalSettings.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/index",
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/index",
    name: "Dashboard",
    component: Index,
    meta: {
      title: "Port Mapping",
      requiresAuth: true,
    },
  },
  {
    path: "/active-work",
    name: "Active Work",
    component: ActiveWork,
    meta: {
      title: "Active Work",
      requiresAuth: true,
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      title: "Reports",
      requiresAuth: true,
    },
  },
  {
    path: "/hazards",
    name: "Hazards",
    component: Hazards,
    meta: {
      title: "Hazards",
      requiresAuth: true,
    },
  },
  {
    path: "/hazard/:id",
    name: "Hazard",
    component: Hazard,
    meta: {
      title: "Hazard",
      requiresAuth: true,
    },
  },
  {
    path: "/incidents",
    name: "Incidents",
    component: Incidents,
    meta: {
      title: "Incidents",
      requiresAuth: true,
    },
  },
  {
    path: "/incident/:id",
    name: "Incident",
    component: Incident,
    meta: {
      title: "Incident",
      requiresAuth: true,
    },
  },
  {
    path: "/report-issue",
    name: "Report Safety Issue",
    component: ReportSafety,
    meta: {
      title: "Report Safety Issue",
      requiresAuth: true,
    },
  },
  {
    path: "/activity-list",
    name: "Activity List",
    component: WorksList, //change to Activity
    meta: {
      title: "Activity List",
      requiresAuth: true,
    },
  },
  {
    path: "/add-activity",
    name: "Add Activity",
    component: AddPermit,
    meta: {
      title: "Add Activity",
      requiresAuth: true,
    },
  },
  {
    path: "/permit/:layerName/:id",
    name: "Permit",
    component: Permit, //change to Activity
    meta: {
      title: "Permit",
      requiresAuth: true,
    },
  },
  {
    path: "/templates",
    name: "Templates",
    component: Templates,
    meta: {
      title: "Templates",
      requiresAuth: true,
    },
  },
  {
    path: "/edit-template",
    name: "Edit Templates",
    component: EditTemplates,
    meta: {
      title: "Edit Templates",
      requiresAuth: true,
    },
  },
  {
    path: "/traffic-management",
    name: "Traffic Management",
    component: TrafficManagement,
    meta: {
      title: "Traffic Management Plan",
      requiresAuth: true,
    },
  },
  {
    path: "/registry", // asset-management
    name: "Registry",
    component: AssetManagement,
    meta: {
      title: "Registry",
      requiresAuth: true,
    },
  },
  {
    path: "/add-item",
    name: "Add Item",
    component: AddAsset,
    meta: {
      title: "Add Item",
      requiresAuth: true,
    },
  },
  {
    path: "/registry/:id",
    name: "Registry Information",
    component: Asset, //change to Registry
    meta: {
      title: "Registry Information",
      requiresAuth: true,
    },
  },
  {
    path: "/stored-locations",
    name: "Stored Locations",
    component: StoredLocations,
    meta: {
      title: "Stored Locations",
      requiresAuth: true,
    },
  },
  {
    path: "/role-manager",
    name: "Role Manager",
    component: RoleManager,
    meta: {
      title: "Role Manager",
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      title: "Users",
      requiresAuth: true,
    },
  },
  {
    path: "/add-people",
    name: "Add People",
    component: AddPeople,
    meta: {
      title: "Add People",
      requiresAuth: true,
    },
  },
  {
    path: "/person-profile/:id",
    name: "Person Profile",
    component: PersonProfile,
    meta: {
      title: "Person Profile",
      requiresAuth: true,
    },
  },
  {
    path: "/personnel",
    name: "Personnel",
    component: Personnel,
    meta: {
      title: "Personnel",
      requiresAuth: true,
    },
  },
  {
    path: "/contractors",
    name: "Contractors",
    component: Contractors,
    meta: {
      title: "Contractors",
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: "My settings",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: GlobalSettings,
    meta: {
      title: "Global Settings",
      requiresAuth: true,
    },
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
    meta: {
      title: "Sign In",
    },
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
    meta: {
      title: "Reset Password",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || "/"),
  routes,
  linkActiveClass: "active",
});

// Add a route guard to protect routes
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore(); // Access the auth store
  const arcgisStore = useArcGISStore();
  
  // Check if the route requires authentication
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    // If not authenticated, redirect to sign-in
    
    await arcgisStore.fetchOauthInfo();
    await arcgisStore.fetchAccessToken();
    await authStore.fetchLoginId(); // Attempt to fetch the login info
    if (!authStore.isAuthenticated) {
      return next({ name: "Sign In" });
    }
  }

  // // Check if the route requires a feature/permission
  // if (to.meta.requiredFeature && !hasPermission(to.meta.requiredFeature)) {
  //   return next({ name: "Dashboard" }); // Redirect if the user lacks permission
  // }

  // If the user is authenticated or the route does not require auth, continue
  document.title = to.meta?.title ?? "Dashboard";
  next();
});

export default router;
