<script setup>
import { onMounted, ref } from 'vue';
import ContractorTable from '@/components/tables/ContractorTable.vue';
import MaterialButton from '@/components/materialKit/MaterialButton.vue';
import { usePeopleStore } from '@/stores/people'

const peopleStore = usePeopleStore();

const filterByStatus = ref('Contractor');

const showContractor = () => {
  filterByStatus.value = 'Contractor';
};

const showPortPersonnel = () => {
  filterByStatus.value = 'Port Personnel';
};

const showAll = () => {
  filterByStatus.value = 'All';
};

onMounted(() => peopleStore.fetchPeople());
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <h4 class="m-3">Contractors</h4>
      </div>
      <div class="col-6 text-end">
        <router-link to="/add-people" class="me-2">
          <MaterialButton variant="gradient" color="info">
            Add People
          </MaterialButton>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="p-3 pb-0 card-body">
        <button class="btn" @click="showContractor">Contractors</button>
        <button class="btn" @click="showPortPersonnel">Port Personnel</button>
        <button class="btn" @click="showAll">All</button>
      </div>
      <div class="card">
        <div class="card-body">
          <ContractorTable :filterByStatus="filterByStatus" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
tr,
.bg-gradient-info {
  cursor: pointer;
}
</style>
