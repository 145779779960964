<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import MaterialCheckbox from "@/components/materialKit/MaterialCheckbox.vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import PeopleTable from "@/components/tables/PeopleTable.vue";
import { useSystemUserStore } from '@/stores/systemUser';

const peopleTableRef = ref(null);
const router = useRouter();

const sysUserStore = useSystemUserStore();

const addUser = () => {
  router.push('/users');
};

onMounted(() => sysUserStore.fetchSystemUsers());
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <h4 class="m-3">System Users</h4>
      </div>
      <div class="col-6 text-end">
        <router-link to="/contractors" class="me-2">
          <MaterialButton variant="gradient" color="info">Contractors</MaterialButton>
        </router-link>
        <router-link to="/personnel">
          <MaterialButton variant="gradient" color="info">Port Personnel</MaterialButton>
        </router-link>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="card mt-4">
            <div class="card-body pt-0">
              <h4 class="m-3">Add New</h4>
              <material-input id="title" label="Name" variant="static" class="mt-4 ms-0" />
              <div>
                <h5 class="text-sm mt-4">System Roles</h5>
                <div class="d-flex">
                  <material-checkbox id="checkboxId" class="p-3">Supervisor</material-checkbox>
                  <material-checkbox id="checkboxId" class="p-3">Manager</material-checkbox>
                  <material-checkbox id="checkboxId" class="p-3">Admin</material-checkbox>
                </div>
                <div class="d-flex">
                  <material-checkbox id="checkboxId" class="p-3">System User</material-checkbox>
                  <material-checkbox id="checkboxId" class="p-3">Safety Team</material-checkbox>
                </div>
                <h5 class="m-3">Contact</h5>
                <material-input id="title" label="Email" variant="static" class="mt-4 ms-0" />
                <material-input id="title" label="Phone" variant="static" class="mt-4 ms-0" />
              </div>

              <div class="text-end mb-4 mt-4">
                <button class="btn bg-gradient-info mb-0 toast-btn me-2" type="button" @click="addUser">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card mt-4">
            <h4 class="m-3">Current Users</h4>
            <div class="card-body pt-0">
              <PeopleTable ref="peopleTableRef" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
tr,
.bg-gradient-info {
  cursor: pointer;
}
</style>
