<script setup>
import { computed, onMounted } from "vue";
import Sidenav from "./layout/Sidenav";
import Navbar from "@/layout/TopnavBar.vue";
import AppFooter from "@/layout/Footer.vue";
import { useMainStore } from '@/stores/index';
import { useAuthStore } from '@/stores/auth';
import OrganisationModal from "@/components/OrganisationModal";

const store = useMainStore();
const authStore = useAuthStore();

const color = computed(() => store.color);
const isAbsolute = computed(() => store.isAbsolute);
const isNavFixed = computed(() => store.isNavFixed);
const navbarFixed = computed(() => store.navbarFixed);
const absolute = computed(() => store.absolute);
// const showSidenav = computed(() => store.showSidenav);
// const showNavbar = computed(() => store.showNavbar);
// const showFooter = computed(() => store.showFooter);

const navbarMinimize = store.navbarMinimize;

const userOrganisation = computed(() => authStore.userDetails?.Organisations.length);

onMounted(() => {
  // const sidenav = document.getElementsByClassName("g-sidenav-show")[0];
  // if (window.innerWidth > 1200) {
  //   sidenav.classList.add("g-sidenav-pinned");
  // }
});
</script>

<template>
  <OrganisationModal v-if="authStore.loginId && userOrganisation > 1" /> <!-- Show modal after login -->
  <sidenav v-if="store.showSidenav" :custom_class="color" />
  <main v-if="store.showMain" class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    <!-- nav -->
    <navbar
      v-if="store.showNavbar"
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
    />
    <router-view />
    <app-footer v-show="store.showFooter" />
  </main>
</template>

<style scoped>
/* Your styles here */
</style>
