<script setup>
import { ref, onMounted, watch, onBeforeUnmount } from 'vue';
import { usePermitsStore } from '@/stores/permits';
import Choices from 'choices.js';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css'; // Import Dropzone styles

// Use the Pinia store
const permitsStore = usePermitsStore();
let choicesInstances = {}; // Store Choices instances
let dropzoneInstance;

// Initialize Dropzone
const initializeDropzone = () => {
  dropzoneInstance = new Dropzone("#addFiles", {
    url: "/file-upload", // Your file upload endpoint
    maxFilesize: 5, // Limit file size to 5MB
    acceptedFiles: ".pdf,.doc,.docx,.jpg,.png,.jpeg", // Accept only certain file types
    addRemoveLinks: true, // Allow removing files
    success(file, response) {
      console.log("File uploaded successfully", response);
      // You can store the uploaded file information in the Pinia store or process it as needed
      permitsStore.addSupportingFile(response.filePath);
    },
    error(file, errorMessage) {
      console.error("File upload error", errorMessage);
    },
  });
};

// Approvers state
const selectedApprovers = ref([]);

// Initialize Choices for approvers multi-select
const initializeChoices = (id, defaultValue) => {
  const element = document.getElementById(id);
  if (element) {
    if (element.choicesInstance) {
      element.choicesInstance.destroy();
    }
    const choicesInstance = new Choices(element, {
      searchEnabled: true,
      allowHTML: true,
    });
    element.choicesInstance = choicesInstance;
    if (defaultValue) {
      choicesInstance.setChoiceByValue(defaultValue);
    }
    choicesInstances[id] = choicesInstance;
    return choicesInstance;
  }
};

// Watch for changes in approvers multi-select and update the store
watch(
  () => {
    const element = document.getElementById('approvers');
    if (element) {
      return Array.from(element.selectedOptions).map(option => option.value);
    }
    return [];
  },
  (newValues) => {
    selectedApprovers.value = newValues;
    permitsStore.setSelectedApprovers(newValues);
  }
);

onMounted(() => {
  initializeChoices('approvers', permitsStore.selectedApprovers);
  initializeDropzone();
});

// Clean up Choices instances before the component is destroyed
onBeforeUnmount(() => {
  Object.keys(choicesInstances).forEach((id) => {
    if (choicesInstances[id]) {
      choicesInstances[id].destroy();
    }
  });
  choicesInstances = {}; // Clear the instances after destruction

  if (dropzoneInstance) {
    dropzoneInstance.destroy();
  }
});
</script>

<template>
  <div class="pt-3 bg-white multisteps-form__panel border-radius-xl js-active position-relative" data-animation="FadeIn">
    <div class="multisteps-form__content">

      <!-- Upload files section -->
      <div class="mt-4 row text-start">
        <h4>Add Supporting files</h4>
        <div class="col-12 mb-4">
          <label class="form-control mb-0">Upload files</label>
          <div id="addFiles" class="form-control border dropzone"></div>
        </div>
      </div>

      <!-- Approvers section -->
      <div class="mt-4 row text-start">
        <h4>Choose approvers or approval group</h4>
        <div class="col-12">
          <div class="d-block">
            <select id="approvers" multiple class="form-control" name="Status">
              <option value="">No one Selected</option>
              <option value="Paula - H&S Manager">Paula - H&S Manager</option>
              <option value="Renie - H&S Manager">Renie - H&S Manager</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
