<script setup>
import { ref, onMounted } from 'vue';
import Dropzone from 'dropzone';
import PlansTable from "@/components/tables/PlansTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
import baseMap from "@/components/maps/baseMap.vue";

const filterByStatus = ref('Active');

const setFilter = (status) => {
  filterByStatus.value = status;
};

onMounted(() => {
  let myDropzone = new Dropzone("#addFiles");
  myDropzone.on("addedfile", (file) => {
    console.log(`File added: ${file.name}`);
  });
});
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <h4 class="m-3">Traffic Management Plans</h4>
      </div>
      <div class="col-6 text-end">
        <router-link to="/active-work" class="me-2">
          <MaterialButton variant="gradient" color="info">
            Active Work (Map View)
          </MaterialButton>
        </router-link>
        <router-link to="/add-activity">
          <MaterialButton variant="gradient" color="info">
            New Site Works / Permit
          </MaterialButton>
        </router-link>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-lg-4">
        <div class="card mt-4">
          <div class="card-body pt-0">
            <div class="mt-1 mb-1">
              <button class="btn" @click="setFilter('Active')">Active Plans</button>
              <button class="btn" @click="setFilter('Archived')">Archived Plans</button>
            </div>
            <PlansTable :filterByStatus="filterByStatus" />
          </div>
        </div>
      </div>
      <div class="col-lg-8 mt-lg-0 mt-4">
        <div class="card mt-4">
          <div class="row mt-4">
            <div class="col-6">
              <h4 class="m-3">Add Plan</h4>
            </div>
            <div class="col-6 text-end">
              <button class="btn bg-gradient-info mb-0 toast-btn me-2" type="button">
                Save
              </button>
              <button class="btn bg-gradient-info mb-0 toast-btn me-2" type="button">
                Save as copy
              </button>
            </div>
          </div>
          <div class="card-body">
            <form>
              <MaterialInput id="title" variant="static" label="Plan Name" />
              <MaterialInput id="route" variant="static" label="Route" />
              <h4 class="mt-4">Notes</h4>
              <label class="form-control mb-0">Note</label>
              <MaterialTextarea id="notes" variant="static" class="ms-0" />
              <h4 class="mt-4">Add Supporting files</h4>
              <label class="form-control mb-0">Upload files</label>
              <div id="addFiles" action="/file-upload" class="form-control border dropzone"></div>
              <div class="mt-4">
                <MaterialInput id="location" variant="dynamic" label="Location" />
              </div>
              <div class="mt-4">
                <h4>You can select location and time on the map</h4>
                <base-map />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
