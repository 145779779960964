<script setup>
import { inject } from 'vue';
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialRadio from "@/components/materialKit/MaterialRadio.vue";

const nextStep = inject('nextStep');
</script>

<template>
  <div class="pt-3 bg-white multisteps-form__panel js-active position-relative" data-animation="FadeIn">
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="d-block mb-4">
          <MaterialInput id="title" variant="dynamic" label="Template Name" />
        </div>
      </div>
      <div class="mt-3 row text-start">
        <h4>Details</h4>
        <div class="mt-4 col-12 col-sm-6 d-flex">
          <MaterialRadio id="site-work" name="radio" checked>Site Works</MaterialRadio>
          <MaterialRadio id="permit" name="radio">Permit</MaterialRadio>
        </div>
        <div class="d-block mb-4">
          <MaterialInput id="applicant" label="Qualifications Required" variant="static" class="mt-4 ms-0" />
          <MaterialInput id="applicant" label="Associated Item" variant="static" class="mt-4 ms-0" />
        </div>
      </div>
      <div class="mt-4 button-row d-flex">
        <button
          class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="nextStep"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>
