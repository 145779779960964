<script setup>
import { onMounted, ref } from 'vue';
import PermitsTable from "@/components/tables/PermitsTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import { hasPermission } from '@/utils/checkPermission';
import { usePermitsStore } from '@/stores/permits';

const permitStore = usePermitsStore();

const filterStatus = ref(null);

const setFilterStatus = (status) => {
  filterStatus.value = status;
};

const clearFilter = () => {
  filterStatus.value = null;
};

onMounted(async () => {
  await permitStore.fetchPermits();
  await permitStore.fetchMockPermits();

});
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <h4 class="m-3">Permits & Site Works</h4>
      </div>
      <div class="col-6 text-end">
        <router-link to="/active-work" class="me-2">
          <MaterialButton variant="gradient" color="info">Active Work (Map View)</MaterialButton>
        </router-link>
        <router-link to="/add-activity">
          <MaterialButton variant="gradient" color="info">New Permit/Site Works</MaterialButton>
        </router-link>
      </div>
      <div class="mt-1 mb-1">
        <button id="showAll" class="btn" @click="clearFilter">All</button>
        <button v-if="hasPermission('canFilter')" id="showWaiting" class="btn" @click="setFilterStatus('A')">Awaiting my approval</button>
        <button id="showPending" class="btn" @click="setFilterStatus('P')">Pending approval</button>
        <button id="showDeclined" class="btn" @click="setFilterStatus('Declined')">Declined</button>
        <!-- <button id="showUpdated" class="btn" @click="setFilterStatus('Updated')">Updated</button> -->
        <button v-if="hasPermission('canFilter')" id="showCancelled" class="btn" @click="setFilterStatus('Cancelled')">Cancelled</button>
        <button v-else id="showCancelled" class="btn" @click="setFilterStatus('Cancelled')">Cancelled</button>
        <button id="showDrafts" class="btn" @click="setFilterStatus('Draft')">Draft</button>
      </div>
      <div class="card">
        <div class="card-body">
          <PermitsTable :filterStatus="filterStatus" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
