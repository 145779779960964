<template>
    <div class="p-3 pb-0 card-body">
      <div class="mt-3 row">
        <div class="col-8">
          <h4>Servicing History</h4>
          <div v-if="!isDisabled" class="text-end mb-4">
            <button class="btn bg-gradient-info mb-0 toast-btn me-2" type="button" data-bs-toggle="modal" data-bs-target="#serviceModal">Add entry</button>
          </div>
          <ServiceModal />
          <div v-for="(service, index) in services" :key="index" class="mb-4 border card-plain border-radius-lg p-4">
            <h6 class="text-dark font-weight-bold mb-0">Booked by: {{ service.bookedBy }}</h6>
            <div class="row">
              <div class="col-12">
                <p class="text-sm mt-1 mb-0">
                  <b>Date servicing due: </b>
                  {{ service.when }} 
                </p>
                <p class="text-sm mt-1 mb-0">
                  <b>Date servicing completed:</b>
                  {{ service.due }} 
                </p>
                <p class="text-sm mt-1 mb-0">
                  <b>Note:</b>
                  {{ service.note }} 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <h4>Contacts</h4>
          <div v-if="!isDisabled" class="text-end mb-4">
            <button class="btn bg-gradient-info mb-0 toast-btn me-2" type="button" data-bs-toggle="modal" data-bs-target="#contactModal">Add contact</button>
          </div>
          <ContactModal />
          <div v-for="(contact, index) in contacts" :key="index" class="mb-4 border card-plain border-radius-lg p-4">
            <h6 class="text-dark font-weight-bold mb-0">{{ contact.mainContact }}</h6>
            <div class="row">
              <div class="col-12">
                <p class="text-sm mt-1 mb-0">
                  <b>Company: </b> <br>
                  {{ contact.company }} 
                </p>
                <p class="text-sm mt-1 mb-0">
                  <b>Phone:</b> <br>
                  {{ contact.phone }} 
                </p>
                <p class="text-sm mt-1 mb-0">
                  <b>Email:</b> <br>
                  {{ contact.email }} 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ServiceModal from "../modal/ServiceModal.vue";
  import ContactModal from "../modal/ContactModal.vue";
  
  export default {
    name: "ServicingTab",
    components: {
      ServiceModal,
      ContactModal
    },
    props: {
      services: Array,
      contacts: Array,
      isDisabled: Boolean
    }
  };
  </script>
  