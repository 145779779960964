import { useAuthStore } from '@/stores/auth';
import { permissionsMap } from './permissions';

export function hasPermission(feature) {
  const authStore = useAuthStore();

  if (!authStore.isAuthenticated) return false;

  const role = authStore.role;

  return permissionsMap[role]?.includes(feature) || false;
}