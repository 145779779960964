<template>
    <div class="p-3 pb-0 card-body">
      <div class="mt-3 row">
        <h4>Custom Integrations</h4>
        <div class="text-end mb-4">
          <button
            class="btn bg-gradient-info mb-0 toast-btn me-2"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#integrationModal"
          >
            Add integrations
          </button>
        </div>
        <IntegrationModal />
        <div class="col-12 mb-4">
          <label class="form-control mb-0">Integration</label>
          <material-textarea
            id="description"
            variant="static"
            class="ms-0"
            :value="integration"
            :disabled="isDisabled"
            @update:value="(val) => '(integration = val)'"
          ></material-textarea>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import IntegrationModal from "../modal/IntegrationModal.vue";
  import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";
  
  export default {
    name: "CustomTab",
    components: {
      IntegrationModal,
      MaterialTextarea,
    },
    props: {
      integration: String,
      isDisabled: Boolean,
    },
  };
  </script>
  