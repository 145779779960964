<script setup>
import { defineProps } from 'vue';
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";

const props = defineProps({
  permit: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <div id="cloneModal" class="modal fade" tabindex="-1" aria-labelledby="cloneModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="cloneModal" class="modal-title">Clone this permit / Site Works</h5>
          <MaterialButton color="none" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></MaterialButton>
        </div>
        <div class="modal-body">
          <p>Duplicate this permit as a copy</p>
          <form>
            <material-input id="title" label="Label" variant="static" class="mt-2 ms-0" :value="props.permit + ' copy'" />
          </form>
        </div>
        <div class="modal-footer justify-content-between">
          <MaterialButton variant="gradient" color="dark" data-bs-dismiss="modal">Close</MaterialButton>
          <MaterialButton variant="gradient" color="info" class="mb-0" data-bs-dismiss="modal">Clone</MaterialButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
