<script setup>
import { computed, onBeforeMount, ref } from 'vue';
// import DefaultStatisticsCard from "@/components/cards/DefaultStatisticsCard.vue";
import PermitsTable from "@/components/tables/PermitsTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import { useArcGISStore } from "@/stores/arcgisStore";
import { hasPermission } from '@/utils/checkPermission';
import { useOrganisationStore } from "@/stores/organisation";
import { useAuthStore } from '@/stores/auth';
import { usePeopleStore } from '@/stores/people';
import { useMainStore } from '@/stores';

const arcgisStore = useArcGISStore();
const mainStore = useMainStore();
const authStore = useAuthStore();
const organisationStore = useOrganisationStore();
const peopleStore = usePeopleStore();

const fetchAppData = async () => {
  await peopleStore.fetchPeople();
};

const filterStatus = ref(null);

const setFilterStatus = (status) => {
  filterStatus.value = status;
};

const clearFilter = () => {
  filterStatus.value = null;
};

const isLoading = computed(() => arcgisStore.isLoading);

onBeforeMount(async () => {
  checkOrganization();
  await fetchAppData();
});

const userOrganisationLength = computed(() => authStore.userDetails?.Organisations.length);
const userOrganisation = computed(() => authStore.userDetails?.Organisations);

const checkOrganization = async () => {

  if (userOrganisationLength.value == 1) {
    arcgisStore.initializeArcGISData();
    organisationStore.selectedOrganisation = userOrganisation.value[0]?.WebMapID;
    mainStore.showSidenav = true;
    mainStore.showNavbar = true;
    mainStore.showFooter = true;
  }
};

// List of presets for Permit and Site Works
const permitPresets = ref([
  { label: 'Tug bunkering', route: '/add-activity' },
  { label: 'Hot works', route: '/add-activity' }
]);

const siteWorksPresets = ref([
  { label: 'Repairs / Maintenance', route: '/add-activity' },
  { label: 'Cruise Activity', route: '/add-activity' }
]);
</script>

<template>
  <div class="py-4 container-fluid">
    <div v-if="authStore.role == 'Contractor'" class="row mb-4">
      <div class="col-lg-4 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Pending Approval</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <router-link to="/activity-list">
                  <div class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning">
                    <h5 class="text-white mt-3">1</h5>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Draft</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <router-link to="/activity-list">
                  <div class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning">
                    <h5 class="text-white mt-3">1</h5>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Dashboard header for Admin and Manager -->
    <div v-else class="row mb-4">
      <div class="col-lg-4 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">New incidents reported</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <router-link to="/incidents">
                  <div class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning">
                    <h5 class="text-white mt-3">4</h5>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Total incidents in review</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <router-link to="/incidents">
                  <div class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning">
                    <h5 class="text-white mt-3">1</h5>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Item with an upcoming expiry</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <router-link to="/asset-management">
                  <div class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-warning">
                    <h5 class="text-white mt-3">3</h5>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-9 col-sm-12">
        <div class="card h-100">
          <div class="card-header mb-0 pb-0">
            <h5 class="mb-0 pb-0 text-start">Permits/Site Works</h5>
            <div class="text-end">
              <router-link to="/activity-list" class="me-2">
                <MaterialButton variant="gradient" color="info">
                  All Permits/Site Works
                </MaterialButton>
              </router-link>
              <router-link to="/add-activity">
                <MaterialButton variant="gradient" color="info">
                  Log new permit
                </MaterialButton>
              </router-link>
            </div>
          </div>
          <div v-if="isLoading" class="loading-spinner">
            <p>Loading data...</p>
          </div>
          <div v-else class="card-body pt-0">
            <div class="text-end mt-1 mb-1">
              <button class="btn" @click="clearFilter">All</button>
              <button v-if="hasPermission('canFilter')" class="btn"
                @click="setFilterStatus('A')">Awaiting my approval</button>
              <button class="btn" @click="setFilterStatus('P')">Pending approval</button>
              <button class="btn" @click="setFilterStatus('Declined')">Declined</button>
              <button v-if="hasPermission('canFilter')" class="btn"
                @click="setFilterStatus('Cancelled')">Cancelled/declined</button>
              <button v-else class="btn" @click="setFilterStatus('Cancelled')">Cancelled</button>
              <button class="btn" @click="setFilterStatus('Draft')">Draft</button>
            </div>
            <PermitsTable :filterStatus="filterStatus" class="permitTable" />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-12">
        <div class="card">
          <div class="card-header pb-2">
            <h5 class="card-title">Select Permit Presets</h5>
          </div>
          <div class="card-body pt-2">
            <ul class="list-group list-group-flush text-uppercase text-xs  font-weight-bolder">
              <li v-for="preset in permitPresets" :key="preset.label" class="list-group-item border-0 ps-2">
                <router-link :to="preset.route"><i class="fa-solid fa-caret-right"></i> {{ preset.label }}</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header pb-2">
            <h5 class="card-title">Select Site Works Presets</h5>
          </div>
          <div class="card-body pt-2">
            <ul class="list-group-item border-0 px-0 text-uppercase text-xs  font-weight-bolder">
              <li v-for="preset in siteWorksPresets" :key="preset.label" class="list-group-item border-0 ps-2">
                <router-link :to="preset.route"><i class="fa-solid fa-caret-right"></i> {{ preset.label }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
