<script setup>
import { onMounted, onBeforeUnmount, watch, ref, computed, nextTick } from 'vue';
import MaterialInput from '@/components/materialKit/MaterialInput.vue';
import MaterialRadio from '@/components/materialKit/MaterialRadio.vue';
import Choices from 'choices.js';
import { useTemplateStore } from '@/stores/template';
import { usePermitsStore } from '@/stores/permits';
import { usePeopleStore } from '@/stores/people';
import { useAuthStore } from '@/stores/auth';

// Store
const templateStore = useTemplateStore();
const permitsStore = usePermitsStore();
const peopleStore = usePeopleStore();
const authStore = useAuthStore();

const templates = computed(() => templateStore.allTemplates);
const people = computed(() => peopleStore.allPeople);


let choicesInstances = {}; // Store instances globally within the component
const isPreset = ref(false);

const initializeChoices = async (id, defaultValue) => {
  await nextTick(); // Wait for DOM to be fully rendered

  console.log('Initializing Choices for:', id);
  const element = document.getElementById(id);
  if (!element) {
    console.error(`Element with id ${id} not found!`);
    return;
  }

  if (choicesInstances[id]) {
    choicesInstances[id].destroy(); // Destroy previous instance
  }

  const choicesInstance = new Choices(element, {
    searchEnabled: true,
    allowHTML: true,
    delimiter: ",",
    editItems: true,
    maxItemCount: 5,
    removeItemButton: true,
    addItems: true,
  });

  if (defaultValue) {
    choicesInstance.setChoiceByValue(defaultValue);
  }

  choicesInstances[id] = choicesInstance;
};

onMounted(() => {
  console.log('Fetched templates:', templates.value);
  
  // Initialize Choices only when the Start step is active
  if (permitsStore.activeStep === 0) {
    initializeChoices('preset', permitsStore.selectedPreset);
  }
});

watch(() => permitsStore.activeStep, async (newStep) => {
  // Reinitialize Choices only when entering the Start step
  if (newStep === 0) {
    await nextTick(); // Ensure the DOM is fully updated
    initializeChoices('preset', permitsStore.selectedPreset);
  }
});

onBeforeUnmount(() => {
  Object.keys(choicesInstances).forEach((id) => {
    if (choicesInstances[id]) {
      choicesInstances[id].destroy();
    }
  });
  choicesInstances = {};
});

const showPreset = () => {
  isPreset.value = true;
};

const hideAll = () => {
  isPreset.value = false;
};

watch(templates, async (newTemplates) => {
  if (newTemplates && newTemplates.length > 0) {
    await nextTick(); // Ensure DOM is updated
    setTimeout(() => {
      initializeChoices('template', permitsStore.selectedTemplate);
    }, 100);
  }
});

watch(people, async (newPeople) => {
  if (newPeople && newPeople.length > 0) {
    await nextTick(); // Ensure DOM is updated
    setTimeout(() => {
      initializeChoices('people', permitsStore.selectedPeople);
    }, 100);
  }
});

const onTemplateChange = async (event) => {
  const selectedTemplateId = event.target.value;
  console.log("Selected Template ID:", selectedTemplateId); // Check the ID
  permitsStore.setSelectedTemplate(selectedTemplateId);

  if (selectedTemplateId) {
    await permitsStore.fetchPermitTemplate(selectedTemplateId);  // Fetch the template predefined values
  }
};

// watch(
//   () => document.getElementById('preset')?.value,
//   (newValue) => {
//     permitsStore.setSelectedPreset(newValue);
//   }
// );

// Watch for the selected activity type
watch(
  () => permitsStore.selectActivityType,
  (newValue) => {
    console.log("Selected Activity Type:", newValue);
  }
);
</script>

<template>
  <div class="pt-3 bg-white multisteps-form__panel js-active position-relative" data-animation="FadeIn">
    <div class="multisteps-form__content">
      <div class="mt-3 row text-start">
        <h4>Start</h4>
        <!-- Radio buttons for activity type -->
        <div class="mt-4 col-12 col-sm-4 d-flex">
          <material-radio id="site-work" v-model="permitsStore.selectActivityType" name="activity-type"
            value="Site Works">
            Site Works
          </material-radio>
          <material-radio id="permit" v-model="permitsStore.selectActivityType" name="activity-type" value="Permit">
            Permit
          </material-radio>
        </div>
        <div class="col-12 col-sm-4 text-start">
          <div class="d-block">
            <label class="ms-0">Template</label>
            <select id="template" class="form-control" name="Status" @change="onTemplateChange($event)">
              <option value="">Select a template</option> <!-- Default blank option -->
              <option v-for="template in templates" :key="template.ID" :value="template.ID">
                {{ template.Name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-12 col-sm-4">
          <label class="ms-0">Or Choose Preset</label><br>
          <button class="btn bg-gradient-primary mb-0 toast-btn me-2" type="button" @click="showPreset">
            Create from preset
          </button>

        </div>
      </div>
      <div v-show="isPreset" class="mt-3 row">
        <div class="mt-4 col-12 col-sm-6 mt-sm-0 text-start">
          <div class="d-block">
            <label class="mt-2 ms-0">Selected Preset</label>
            <select id="preset" class="form-control" name="Status">
              <option value="Confined Space" selected>Confined Space</option>
              <option value="Bunkering">Bunkering</option>
              <option value="Corridor Access">Corridor Access</option>
              <option value="Crane and Heavy Lifts">Crane and Heavy Lifts</option>
              <option value="Dive Ops">Dive Ops</option>
            </select>
          </div>
        </div>
        <div class="mt-4 col-12 col-sm-6 mt-sm-0 text-end">
          <button class="btn bg-gradient-dark mb-0 mt-4 toast-btn me-2" type="button" @click="hideAll">Clear
            Preset</button>
        </div>
      </div>
      <div class="mt-3 row text-start">
        <h4>Details</h4>

        <!-- Project Title Input -->
        <div class="d-block mb-4">
          <material-input id="title" variant="static" label="Project Title" :value="permitsStore.projectTitle"
            @update:value="permitsStore.projectTitle = $event" />
        </div>

        <!-- Applicant Input -->
        <div class="d-block mb-4">
          <material-input id="applicant" variant="static" label="Applicant" :value="authStore.systemUserDetails?.Name"
            @update:value="permitsStore.applicant = $event" />
        </div>

        <!-- Description Input -->
        <div class="d-block mb-4">
          <material-input id="description" variant="static" label="Description" :value="''"
            @update:value="permitsStore.description = $event" />
        </div>

        <!-- Qualifications Required Input -->
        <div class="d-block mb-4">
          <material-input id="qualifications" variant="static" label="Qualifications Required"
            :value="permitsStore.qualificationsRequired" @update:value="permitsStore.qualificationsRequired = $event"
            class="mt-4 ms-0" />
        </div>
      </div>
      <div class="mt-3 row text-start">
        <h4>Who will be conducting the work</h4>
        <div class="col-12">
          <div class="d-block">
            <select id="people" class="form-control" name="Status" multiple
              :value="permitsStore.selectedPeople == '' ? null : permitsStore.selectedPeople">
              <option value="">No one Selected</option>
              <option v-for="person in people" :key="person.ID" :value="person.Name">
                {{ person.Name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="mt-3 row text-start">
        <div class="col-6 col-sm-9">
          <p class="mt-0">If you cannot find who is conducting the work they have not been added to the system.</p>
        </div>
        <div class="col-6 col-sm-3">
          <router-link to="/add-people">
            <button class="btn bg-gradient-primary mb-0 toast-btn me-2" type="button">Add people</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
