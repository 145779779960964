import { get, post } from "aws-amplify/api";

export default class TucumcariClient{
  //////////////////////////////////////////////////////
  ////////////// ArcGIS Endpoints //////////////////////
  //////////////////////////////////////////////////////
  async getArcgisAccessToken() {
    return await get({
      apiName: "Tucumcari",
      path: `/arcgis/token`,
    });
  }

  async getOauthInfo() {
    return await get({
      apiName: "Tucumcari",
      path: `/arcgis/token`,
    });
  }
  ///////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////
  // 2FA
  async get2FA() {
    return await get({
      apiName: "Tucumcari",
      path: `/2fa`,
    });
  }

  async post2FA(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/2fa`,
      options: { body: Packet },
    });
  }

  // Activities
  async getActivities() {
    return await get({
      apiName: "Tucumcari",
      path: `/activities`,
    });
  }

  // AreaSetting
  async getAreaSetting() {
    return await get({
      apiName: "Tucumcari",
      path: `/areasetting`,
    });
  }

  async postAreaSetting(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/areasetting`,
      options: { body: Packet },
    });
  }

  // Asset
  async getAllAsset() {
    return await get({
      apiName: "Tucumcari",
      path: `/asset`,
    });
  }

  async postAsset(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/asset`,
      options: { body: Packet },
    });
  }

  async getAssetVal(asset) {
    return await get({
      apiName: "Tucumcari",
      path: `/asset/${asset}`,
    });
  }

  async postAssetVal(asset, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/asset/${asset}`,
      options: { body: Packet },
    });
  }

  async getAssetValArchive(asset) {
    return await get({
      apiName: "Tucumcari",
      path: `/asset/${asset}/archive`,
    });
  }

  async postAssetValArchive(asset, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/asset/${asset}/archive`,
      options: { body: Packet },
    });
  }

  // Global Setting
  async getGlobalSettings() {
    return await get({
      apiName: "Tucumcari",
      path: `/globalsettings`,
    });
  }

  async postGlobalSettings(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/globalsettings`,
      options: { body: Packet },
    });
  }

  // Password
  async password(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/password`,
      options: { body: Packet },
    });
  }

  // Hazards
  async getHazards() {
    return await get({
      apiName: "Tucumcari",
      path: `/hazards`,
    });
  }

  // Notification
  async getNotification() {
    return await get({
      apiName: "Tucumcari",
      path: `/notification`,
    });
  }

  async getNotificationVal(notification) {
    return await get({
      apiName: "Tucumcari",
      path: `/notification/${notification}`,
    });
  }

  // People
  async getAllPeople() {
    return await get({
      apiName: "Tucumcari",
      path: `/people`,
    });
  }

  async postPeople(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/people`,
      options: { body: Packet },
    });
  }

  async getPeoplePerson(person) {
    return await get({
      apiName: "Tucumcari",
      path: `/people/${person}`,
    });
  }

  async postPeoplePerson(person, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/people/${person}`,
      options: { body: Packet },
    });
  }

  // Permit
  async getAllPermit() {
    return await get({
      apiName: "Tucumcari",
      path: `/permit`,
    });
  }
  // async getAllPermit(HandlerID, Packet = {}) {
  //   await get({
  //     apiName: "Tucumcari",
  //     path: "/permit",
  //     options: {
  //       body: {
  //         Packet,
  //       },
  //     },
  //   });
  // }

  // add
  async postAddPermit(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/permit`,
      options: { body: Packet },
    });
  }

  async getPermitByID(permit) {
    return await get({
      apiName: "Tucumcari",
      path: `/permit/${permit}`,
    });
  }
  // update
  async postUpdatePermit(permit, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/permit/${permit}`,
      options: { body: Packet },
    });
  }

  // Policy
  async getPolicy() {
    return await get({
      apiName: "Tucumcari",
      path: `/policy`,
    });
  }

  async postPolicy(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/policy`,
      options: { body: Packet },
    });
  }

  async getPolicyVal(policy) {
    return await get({
      apiName: "Tucumcari",
      path: `/policy/${policy}`,
    });
  }

  async postPolicyVal(policy, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/policy/${policy}`,
      options: { body: Packet },
    });
  }

  // Preset
  async getPreset() {
    return await get({
      apiName: "Tucumcari",
      path: `/preset`,
    });
  }

  async postPreset(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/preset`,
      options: { body: Packet },
    });
  }

  async getPresetVal(preset) {
    return await get({
      apiName: "Tucumcari",
      path: `/preset/${preset}`,
    });
  }

  async postPresetVal(preset, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/preset/${preset}`,
      options: { body: Packet },
    });
  }

  // Related to Incidents
  async getRelatedToIncidents() {
    return await get({
      apiName: "Tucumcari",
      path: `/relatedtoincidents`,
    });
  }

  // Report
  async getReport() {
    return await get({
      apiName: "Tucumcari",
      path: `/report`,
    });
  }

  async getReportVal(report) {
    return await get({
      apiName: "Tucumcari",
      path: `/report/${report}`,
    });
  }

  // Risks
  async getRisk() {
    return await get({
      apiName: "Tucumcari",
      path: `/risk`,
    });
  }

  // Role
  async getRole() {
    return await get({
      apiName: "Tucumcari",
      path: `/role`,
    });
  }

  async postRole(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/role`,
      options: { body: Packet },
    });
  }

  async getRoleVal(role) {
    return await get({
      apiName: "Tucumcari",
      path: `/role/${role}`,
    });
  }

  async postRoleVal(role, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/role/${role}`,
      options: { body: Packet },
    });
  }

  async getRoles() {
    return await get({
      apiName: "Tucumcari",
      path: `/roles`,
    });
  }

  // Safety Issue
  async getSafetyIssue() {
    return await get({
      apiName: "Tucumcari",
      path: `/safetyissue`,
    });
  }

  async postSafetyIssue(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/safetyissue`,
      options: { body: Packet },
    });
  }

  async getSafetyIssueVal(issue) {
    return await get({
      apiName: "Tucumcari",
      path: `/safetyissue/${issue}`,
    });
  }

  async postSafetyIssueVal(issue, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/safetyissue/${issue}`,
      options: { body: Packet },
    });
  }

  // Incidents
  async getAllIncident() {
    return await get({
      apiName: "Tucumcari",
      path: `/incident`,
    });
  }

  // add
  async postAddIncidents(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/incident`,
      options: { body: Packet },
    });
  }

  async getIncidentsByID(incident) {
    return await get({
      apiName: "Tucumcari",
      path: `/incident/${incident}`,
    });
  }
  // update
  async postUpdateIncident(incident, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/incident/${incident}`,
      options: { body: Packet },
    });
  }

    // Hazards
    async getAllHazard() {
      return await get({
        apiName: "Tucumcari",
        path: `/hazard`,
      });
    }
  
    // add
    async postAddHazard(Packet = {}) {
      return await post({
        apiName: "Tucumcari",
        path: `/hazard`,
        options: { body: Packet },
      });
    }
  
    async getHazardsByID(hazard) {
      return await get({
        apiName: "Tucumcari",
        path: `/hazard/${hazard}`,
      });
    }
    // update
    async postUpdateHazard(hazard, Packet = {}) {
      return await post({
        apiName: "Tucumcari",
        path: `/hazard/${hazard}`,
        options: { body: Packet },
      });
    }

  // Stored Location
  async getStoredLocation() {
    return await get({
      apiName: "Tucumcari",
      path: `/storedlocation`,
    });
  }

  async postStoredLocation(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/storedlocation`,
      options: { body: Packet },
    });
  }

  async getStoredLocationVal(location) {
    return await get({
      apiName: "Tucumcari",
      path: `/storedlocation/${location}`,
    });
  }

  async postStoredLocationVal(location, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/storedlocation/${location}`,
      options: { body: Packet },
    });
  }

  // System User
  async getSystemUser() {
    return await get({
      apiName: "Tucumcari",
      path: `/systemuser`,
    });
  }

  async postSystemUser(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/systemuser`,
      options: { body: Packet },
    });
  }

  async getSystemUserVal(user) {
    return await get({
      apiName: "Tucumcari",
      path: `/systemuser/${user}`,
    });
  }

  async postSystemUserVal(user, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/systemuser/${user}`,
      options: { body: Packet },
    });
  }

  // Template
  async getAllTemplate() {
    return await get({
      apiName: "Tucumcari",
      path: `/template`,
    });
  }

  async postAddTemplate(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/template`,
      options: { body: Packet },
    });
  }

  async getTemplateById(template) {
    return await get({
      apiName: "Tucumcari",
      path: `/template/${template}`,
    });
  }

  async postUpdateTemplateById(template, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/template/${template}`,
      options: { body: Packet },
    });
  }

  // Traffic Management Plan
  async getAllTrafficManagementPlan() {
    return await get({
      apiName: "Tucumcari",
      path: `/trafficmanagementplan`,
    });
  }

  async postAddTrafficManagementPlan(Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/trafficmanagementplan`,
      options: { body: Packet },
    });
  }

  async getTrafficManagementById(plan) {
    return await get({
      apiName: "Tucumcari",
      path: `/trafficmanagementplan/${plan}`,
    });
  }

  async postUpdateTrafficManagementPlanVal(plan, Packet = {}) {
    return await post({
      apiName: "Tucumcari",
      path: `/trafficmanagementplan/${plan}`,
      options: { body: Packet },
    });
  }

  // Work conductors
  async getWorkConductors() {
    return await get({
      apiName: "Tucumcari",
      path: `/workconductors`,
    });
  }

  // Organisations
  async getOrganisations() {
    return await get({
      apiName: "Tucumcari",
      path: `/organisations`,
    });
  }
}
