<script setup>
import { ref, onMounted, inject } from "vue";
import Choices from "choices.js";

const prevStep = inject('prevStep');

const skills = ref(null);

onMounted(() => {
  if (skills.value) {
    new Choices(skills.value, {
      delimiter: ",",
      editItems: true,
      maxItemCount: 5,
      removeItemButton: true,
      addItems: true,
      allowHTML: true,
    });
  }
});
</script>

<template>
  <div class="pt-3 bg-white multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="multisteps-form__content">
      <div class="mt-4 row text-start">
        <h4>Team this template applies to</h4>
        <div class="col-12">
          <div class="d-block">
            <select id="people" ref="skills" multiple class="form-control" name="Status">
              <option value="">No one Selected</option>
              <option value="Gary Cat">Gary Cat</option>
              <option value="Safety Group">Safety Group</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mt-4 button-row d-flex">
        <button class="mb-0 btn bg-gradient-light js-btn-prev" type="button" title="Prev" @click="prevStep">Prev</button>
        <div class="text-end ms-auto">
          <button class="mb-0 btn bg-gradient-primary me-4" type="button" title="Archive">Archive</button>
          <button class="mb-0 btn bg-gradient-dark" type="button" title="Save Template">Save Template</button>
        </div>
      </div>
    </div>
  </div>
</template>
