<script setup>
import { ref } from 'vue';
import MaterialButton from '@/components/materialKit/MaterialButton.vue';
import MaterialInput from '@/components/materialKit/MaterialInput.vue';
import MaterialTextarea from '@/components/materialKit/MaterialTextarea.vue';

// You can define any reactive data or functions here if needed
const step = ref('');
const hazards = ref('');
const inherentRiskScore = ref('');
const residualRiskScore = ref('');
const controlMethods = ref('');
</script>

<template>
  <div id="addStepModal" class="modal fade" tabindex="-1" aria-labelledby="addStepModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="addStepModal" class="modal-title">Add Procedure Step</h5>
          <MaterialButton color="none" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></MaterialButton>
        </div>
        <div class="modal-body">
          <form class="row">
            <div class="col-12">
              <material-input id="step" label="Step" variant="static" class="mt-2 ms-0" v-model="step" />
            </div>
            <div class="col-12 text-start">
              <label class="mt-4 ms-0">Hazards</label>
              <material-textarea id="hazards" variant="static" class="mt-0 ms-0" v-model="hazards"></material-textarea>
            </div>
            <div class="col-12 col-sm-6">
              <material-input id="riskScore1" label="Inherent Risk Score" variant="static" class="mt-2 ms-0" v-model="inherentRiskScore" />
            </div>
            <div class="col-12 col-sm-6">
              <material-input id="riskScore2" label="Residual Risk Score" variant="static" class="mt-2 ms-0" v-model="residualRiskScore" />
            </div>
            <div class="col-12 text-start">
              <label class="mt-4 ms-0">Control Methods & Monitoring</label>
              <material-textarea id="controlMethods" variant="static" class="mt-0 ms-0" v-model="controlMethods"></material-textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer justify-content-between">
          <MaterialButton variant="gradient" color="dark" data-bs-dismiss="modal">Close</MaterialButton>
          <MaterialButton variant="gradient" color="info" class="mb-0" data-bs-dismiss="modal">Add Step</MaterialButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Your styles here */
</style>
