<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useIncidentStore } from '@/stores/modules/incidents';
import { DataTable } from 'simple-datatables';
import moment from 'moment';

const props = defineProps({
  filterStatus: {
    type: Object,
    required: true,
  },
});

const store = useIncidentStore();
const router = useRouter();
const dataTable = ref(null);

const incidents = computed(() => store.allIncidents);

const sortedList = computed(() => {
  return incidents.value.slice().sort((a, b) => {
    const dateA = moment(a.reportDate, 'DD/MM/YYYY');
    const dateB = moment(b.reportDate, 'DD/MM/YYYY');
    return dateB - dateA;
  });
});

const fetchIncidents = async () => {
  await store.fetchMockIncidents();
  //await store.fetchIncidents();
  initDataTable();
};

const initDataTable = () => {
  if (dataTable.value) {
    dataTable.value.destroy();
  }
  dataTable.value = new DataTable('#incidents', {
    searchable: true,
    fixedHeight: true,
  });

  const tableBody = document.querySelector('#incidents tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    const id = row.dataset.id;
    row.addEventListener('click', () => {
      console.log('Row clicked', id);
      router.push(`/incident/${id}`);
    });
  });
};

const filterIncidents = (status) => {
  const tableBody = document.querySelector('#incidents tbody');
  const rows = tableBody.querySelectorAll('tr');
  rows.forEach((row) => {
    const statusCell = row.querySelector('td:nth-child(1)');
    if (status === 'All' || statusCell.textContent.trim() === status) {
      row.style.display = '';
    } else {
      row.style.display = 'none';
    }
  });
};

watch(() => props.filterStatus, (newStatus) => {
  filterIncidents(newStatus);
});

onMounted(fetchIncidents);
</script>

<template>
  <div class="table-responsive">
    <table id="incidents" class="table table-flush">
      <thead class="thead-light">
        <tr>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">First Reported</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Incident occurred</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Location</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Hazards</th>
          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Associated Item</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in sortedList" :key="item.ID" :data-id="item.ID">
          <td class="text-sm font-weight-normal">
            <button class="btn w-100 mb-0 p-0 text-white" :class="{
              draft: item.Status == 'New',
              review: item.Status == 'In Review',
              openIcid: item.Status == 'Open Incident',
              closed: item.Status == 'Closed',
            }">
              {{ item.Status }}
            </button>
          </td>
          <td class="text-sm font-weight-normal">{{ item.reportDate }}</td>
          <td class="text-sm font-weight-normal">{{ item.occurDate }}</td>
          <td class="text-sm font-weight-normal">{{ item.Title }}</td>
          <td class="text-sm font-weight-normal">{{ item.Location }}</td>
          <td class="text-sm font-weight-normal">
            <button class="btn w-100 mb-0 p-0 text-white" :class="{ openIcid: item.hazards }">linked</button>
          </td>
          <td class="text-sm font-weight-normal">
            <button class="btn w-100 mb-0 p-0 text-white" :class="{ openIcid: item.asset }">has item</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
tr {
  cursor: pointer;
}
</style>
