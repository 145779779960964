<script setup>
import {inject } from 'vue';
import MaterialCheckbox from "@/components/materialKit/MaterialCheckbox.vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";

const nextStep = inject('nextStep');
const prevStep = inject('prevStep');

const activities = [
  "Work at Heights",
  "Confined Space Entry",
  "Hazardous Substance",
  "Mobile Plant",
  "Roof Access",
  "Power Tools",
  "Excavation >300mm",
  "Excavation >1.5m",
  "Working with Chemicals",
  "Energised electrical work",
  "Breakwater Access",
  "Workshop Machinery",
  "Hot Works",
  "Punt",
  "Forklift Cage",
  "Palfinger",
  "Pits",
  "Welding or Gas Cutting",
  "Pavement Inspection",
  "Building Inspections",
  "Survey Boat Operations",
  "Bollard Removal/Exchange",
];

const risks = [
  "Asbestos Exposure",
  "Body Injury",
  "Eye Damage",
  "Burns",
  "Trips, Slips, Falls",
  "Strain",
  "Chemical Exposure",
  "Collision",
  "Engulfment",
  "Electrocution",
  "Explosion",
  "Crush",
  "Sprains and Strains",
  "Hearing Loss",
  "Drowning",
  "Falling Objects",
  "Falling from Height",
  "Asphyxiation",
  "Noxious Gases",
  "Fire",
  "Foot Injury",
  "Hand Injury",
];
</script>

<template>
  <div class="pt-3 bg-white multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="multisteps-form__content">
      <div class="row mt-4 text-start">
        <h4>Standard Activities</h4>
        <material-input id="add-act" label="Add Standard Activity" variant="static" class="mt-2 ms-0 mb-4" />

        <div class="col-12 col-sm-3" v-for="(activity, index) in activities" :key="index">
          <material-checkbox :id="'checkbox-' + index">{{ activity }}</material-checkbox>
        </div>

        <div class="col-12 mt-4">
          <h4>Risks</h4>
          <material-input id="add-risk" label="Add Risk" variant="static" class="mt-2 ms-0 mb-4" />
        </div>

        <div class="col-12 col-sm-3" v-for="(risk, index) in risks" :key="index">
          <material-checkbox :id="'risk-checkbox-' + index">{{ risk }}</material-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="mt-4 button-row d-flex col-12">
          <button
            class="mb-0 btn bg-gradient-light js-btn-prev"
            type="button"
            title="Prev"
            @click="prevStep"
          >
            Prev
          </button>
          <button
            class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
            type="button"
            title="Next"
            @click="nextStep"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
