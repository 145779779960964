<script setup>
import { ref, provide, nextTick } from "vue";
import Start from "./components/Start.vue";
import Activities from "./components/Activites.vue";
import Procedure from "./components/Procedure.vue";
import Final from "./components/Final.vue";

const activeStep = ref(0);
const activeClass = "js-active position-relative";
const formSteps = 4;

const nextStep = () => {
  if (activeStep.value < formSteps) {
    activeStep.value += 1;
    scrollToTop();
  } else {
    activeStep.value -= 1;
  }
};

const prevStep = () => {
  if (activeStep.value > 0) {
    activeStep.value -= 1;
    scrollToTop();
  }
};

const scrollToTop = () => {
  nextTick(() => {
    const topAnchor = document.querySelector('#top');
    if (topAnchor) {
      topAnchor.scrollIntoView({ behavior: 'smooth' });
    }
  });
};

provide('nextStep', nextStep);
provide('prevStep', prevStep);
provide('activeStep', activeStep);
provide('activeClass', activeClass);
</script>

<template>
  <div id="top" class="py-4 h-100 container-fluid">
    <div class="row">
      <h4>Permit / Site Works Templates</h4>
      <div class="text-center col-12">
        <div class="mb-5 multisteps-form">
          <div class="row">
            <div class="mx-auto my-5 col-12 col-lg-8">
              <div class="card">
                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div class="bg-gradient-info shadow-info border-radius-lg pt-4 pb-3">
                    <div class="multisteps-form__progress">
                      <button class="multisteps-form__progress-btn js-active" type="button" title="User Info"
                        :class="activeStep >= 0 ? activeClass : ''" @click="activeStep = 0">
                        <span>Details</span>
                      </button>
                      <button class="multisteps-form__progress-btn" type="button" title="Activities & Risk"
                        :class="activeStep >= 1 ? activeClass : ''" @click="activeStep = 1">
                        <span>Activities & Risk</span>
                      </button>
                      <button class="multisteps-form__progress-btn" type="button" title="Procedure"
                        :class="activeStep >= 2 ? activeClass : ''" @click="activeStep = 2">
                        <span>Procedure</span>
                      </button>
                      <button class="multisteps-form__progress-btn" type="button" title="Account"
                        :class="activeStep >= 3 ? activeClass : ''" @click="activeStep = 3">
                        <span>Finalise</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <Start v-if="activeStep === 0" />
                    <!--single form panel-->
                    <Activities :class="activeStep === 1 ? activeClass : ''" />
                    <!--single form panel-->
                    <Procedure :class="activeStep === 2 ? activeClass : ''" />
                    <!--single form panel-->
                    <Final :class="activeStep === 3 ? activeClass : ''" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
