<template>
    <div v-show="tab === 'reports'" class="p-3 pb-0 card-body">
      <div class="mt-3 row">
        <h4>Reports</h4>
        <div v-for="(report, index) in reports" :key="index" class="col-12 mb-4">
          <div class="mt-2 border card-plain border-radius-lg p-4">
            <div class="row">
              <div class="col-9">
                <h6 class="text-dark font-weight-bold mb-0">{{ report.title }}</h6>
              </div>
              <div class="col-3">
                <button
                  class="btn w-100 mb-2 p-0 text-white"
                  :class="{
                    updated: report.fromStatus == 'Merged in',
                    new: report.fromStatus == 'New',
                  }"
                >
                  {{ report.fromStatus }}
                </button>
              </div>
              <div class="col-6">
                <p class="text-sm mt-1 mb-0"><b>Reported By:</b> <br>{{ report.reported }}</p>
              </div>
              <div class="col-6">
                <p class="text-sm mt-1 mb-0"><b>Reported:</b> <br>{{ report.when }}</p>
              </div>
              <div class="col-12">
                <p class="text-sm mt-1 mb-0"><b>Description:</b> <br>{{ report.descipt }}</p>
                <hr>
                <p v-if="report.supportImages" class="text-sm mt-1 mb-0 font-weight-bold">Supporting images</p>
                <div v-for="(image, imageIndex) in report.supportImages" :key="imageIndex" class="d-inline">
                  <img :src="image" alt="Control Image" width="150px;" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ReportsTab",
    props: {
      tab: {
        type: String,
        required: true,
      },
      reports: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  