<script setup>
import { ref, onMounted } from 'vue';
import HazardsTable from "@/components/tables/HazardsTable.vue";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";

const filterStatus = ref('All');

const showLinked = () => {
  filterStatus.value = 'Linked';
};

const showOpen = () => {
  filterStatus.value = 'Open Hazard';
};

const showNew = () => {
  filterStatus.value = 'New';
};

const showClosed = () => {
  filterStatus.value = 'Closed';
};

const showAll = () => {
  filterStatus.value = 'All';
};

onMounted(() => {
});
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-6">
        <h4 class="m-3">Site Hazards</h4>
      </div>
      <div class="col-6 text-end">
        <router-link to="/active-work" class="me-2">
          <MaterialButton variant="gradient" color="info">
            Recent Hazards (Map View)
          </MaterialButton>
        </router-link>
        <router-link to="/report-issue" class="me-2">
          <MaterialButton variant="gradient" color="info">
            Report Hazard
          </MaterialButton>
        </router-link>
        <router-link to="/incidents">
          <MaterialButton variant="gradient" color="warning">
            Incident List
          </MaterialButton>
        </router-link>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">New hazards reported</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-danger"
                  @click="showNew"
                >
                  <h5 class="text-white mt-3">1</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="card mt-4">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-9 col-sm-12">
                <h5 class="m-3">Open hazards linked to incidents</h5>
              </div>
              <div class="col-lg-3 col-sm-12 text-end">
                <div
                  class="text-center shadow icon icon-lg icon-shape border-radius-md bg-gradient-danger"
                  @click="showLinked"
                >
                  <h5 class="text-white mt-3">1</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="p-3 pb-0 card-body">
          <button class="btn" @click="showAll">All</button>
          <button class="btn" @click="showNew">New Hazards</button>
          <button class="btn" @click="showOpen">Open Hazards</button>
          <button class="btn" @click="showLinked">Hazards linked to incidents</button>
          <button class="btn" @click="showClosed">Closed Hazards</button>
        </div>
        <div class="card">
          <div class="card-body">
            <HazardsTable :filterStatus="filterStatus" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
tr,
.bg-gradient-info,
.icon-lg {
  cursor: pointer;
}
</style>
