<script setup>
import { ref, onMounted } from "vue";
import * as Choices from "choices.js";
import MaterialButton from "@/components/materialKit/MaterialButton.vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialAlert from "@/components/materialKit/MaterialAlert.vue";

// Define reactive variables
const notices = ref([
  {
    notice: "Bunkering for site A permit has been updated",
    link: "/permit/123456",
    noticeLogged: "03/05/2024 10.00AM",
  },
  {
    notice: "New incident has been logged for Crane Malfunction",
    link: "/incident/123456",
    noticeLogged: "22/04/2024 12.30AM",
  },
  {
    notice: "Bunkering for site A permit has been updated",
    link: "/permit/123456",
    noticeLogged: "12/04/2024 3.30PM",
  },
]);

const presets = ref([
  { title: "Bunkering for site A", type: "Permit" },
  { title: "Site Work Repairs", type: "Permit" },
  { title: "Preset 3", type: "Site Works" },
]);

// Choices.js initialization for the skills input field
onMounted(() => {
  if (document.getElementById("choices-skills")) {
    const skills = document.getElementById("choices-skills");
    new Choices(skills, {
      delimiter: ",",
      editItems: true,
      maxItemCount: 5,
      removeItemButton: true,
      addItems: true,
      allowHTML: true,
    });
  }
});
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row mb-5">
      <div class="col-lg-8 mt-lg-0 mt-4">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-1 font-weight-bolder">James Waugh</h5>
            <p class="mb-0 font-weight-bold text-sm">Supervisor</p>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-6">
                <material-input
                  id="firstName"
                  variant="static"
                  label="First Name"
                  placeholder="James"
                />
              </div>
              <div class="col-6">
                <material-input
                  id="lastName"
                  variant="static"
                  label="Last Name"
                  placeholder="Waugh"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <material-input
                  id="email"
                  type="email"
                  variant="static"
                  label="Email"
                  placeholder="example@email.com"
                />
              </div>
              <div class="col-6">
                <material-input
                  id="phoneNumber"
                  type="phone"
                  variant="static"
                  label="Phone Number"
                  placeholder="+64 1234 5678"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="form-label mt-4 ms-0">Roles</label>
                <input
                  id="choices-skills"
                  class="form-control"
                  type="text"
                  value="Safety, Supervisor, Management"
                  placeholder="Enter something"
                  onfocus="focused(this)"
                  onfocusout="defocused(this)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header">
            <h5>Change Password</h5>
          </div>
          <div class="card-body pt-0">
            <div class="mb-3">
              <material-input
                id="currentPassword"
                type="password"
                variant="static"
                label="Current Password"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="newPassword"
                type="password"
                variant="static"
                label="New Password"
              />
            </div>
            <div class="mb-3">
              <material-input
                id="confirmPassword"
                type="password"
                variant="static"
                label="Confirm Password"
              />
            </div>
            <h5 class="mt-5">Password requirements</h5>
            <p class="text-muted mb-2">
              Please follow this guide for a strong password:
            </p>
            <ul class="text-muted ps-4 mb-0 float-start">
              <li>
                <span class="text-sm">One special character</span>
              </li>
              <li>
                <span class="text-sm">Min 6 characters</span>
              </li>
              <li>
                <span class="text-sm">One number (2 are recommended)</span>
              </li>
              <li>
                <span class="text-sm">Change it often</span>
              </li>
            </ul>
            <material-button
              class="float-end mt-6 mb-0"
              color="dark"
              variant="gradient"
              size="sm"
              >Update password</material-button
            >
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header d-flex">
            <h5 class="mb-0">Two-factor authentication</h5>
            <material-badge color="success" class="ms-auto">Enabled</material-badge>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <p class="my-auto">Authenticator app</p>
              <p class="text-secondary text-sm ms-auto my-auto me-3">
                Not Configured
              </p>
              <material-button
                color="dark"
                variant="outline"
                size="sm"
                class="mb-0"
                type="button"
                >Set up</material-button
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card mt-4">
          <div class="card-header">
            <h5>Notifications</h5>
          </div>
          <div class="card-body pt-0">
            <material-alert
              v-for="(item, index) in notices"
              :key="index"
              class="font-weight-light"
              color="secondary"
              dismissible
            >
              <div class="mb-2">
                <router-link :to="item.link" class="alert-link text-white">
                  <p class="text-sm me-3 pb-0 pt-0 mt-0 mb-0">
                    {{ item.notice }}
                  </p>
                </router-link>
                <p class="text-xs me-3"> {{ item.noticeLogged }}</p>
              </div>
            </material-alert>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header">
            <h5>My Permit/Site Works Presets</h5>
            <div v-for="(preset, list) in presets" :key="list" class="d-flex mb-2">
              <router-link to="/add-activity" class="text-sm">
                <p class="my-auto">{{ preset.title }}</p>
              </router-link>
              <p class="text-secondary text-xs ms-auto my-auto me-3">
                {{ preset.type }}
              </p>
              <material-button color="dark" size="sm" class="mb-0" type="button">
                Delete
              </material-button>
              <hr class="horizontal dark" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add any necessary scoped styles here */
</style>
