<script setup>
import { ref } from 'vue';
import flatPickr from 'vue-flatpickr-component';
import { useRouter } from 'vue-router';
import 'flatpickr/dist/flatpickr.css';
import MaterialInput from '@/components/materialKit/MaterialInput.vue';
import MaterialTextarea from '@/components/materialKit/MaterialTextarea.vue';

const date = ref(null);
const isDisabled = ref(false);
const router = useRouter();

const saveAsset = () => {
  // Logic to save the asset goes here
  // Redirect to asset details page after saving
  router.push('/registry');
};
</script>

<template>
  <div id="top" class="py-4 h-100 container-fluid">
    <div class="row">
      <h4>Add Asset</h4>
      <div class="col-8 mx-auto">
        <div class="card mt-4">
          <div class="card-body p-3">
            <h4 class="m-3">Details</h4>
            <div class="row mt-4">
              <div class="col-12 col-sm-8">
                <MaterialInput id="title" label="Title" variant="static" class="mt-2 ms-0" />
                <MaterialInput id="model" label="Make and Model" variant="static" class="mt-4 ms-0" />
                <MaterialInput id="warranty" label="Warranty" variant="static" class="mt-4 ms-0" />
                <MaterialInput id="licence" label="Licence" variant="static" class="mt-4 ms-0" />
              </div>
              <div class="col-12 col-sm-4">
                <label class="ms-0">Purchase date</label>
                <div class="input-group input-group-static">
                  <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  <flat-pickr
                    v-model="date"
                    :disabled="isDisabled"
                    class="form-control datetimepicker"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      enableTime: false,
                    }"
                  />
                </div>
                <label class="mt-3 ms-0">Next Service Due</label>
                <div class="input-group input-group-static">
                  <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  <flat-pickr
                    v-model="date"
                    :disabled="isDisabled"
                    class="form-control datetimepicker"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      enableTime: false,
                    }"
                  />
                </div>
              </div>
              <div class="col-12">
                <label class="mt-2 ms-0">Description</label>
                <MaterialTextarea id="description" variant="static" class="mt-2 ms-0"></MaterialTextarea>
              </div>
            </div>
            <h4 class="m-3">Contacts</h4>
            <div class="row mt-4">
              <div class="col-6">
                <MaterialInput id="purchasedFrom" label="Purchased From" variant="static" class="mt-4 ms-0" />
                <MaterialInput id="servicing" label="Servicing" variant="static" class="mt-4 ms-0" />
                <MaterialInput id="personnelResponsible" label="Personnel Responsible" variant="static" class="mt-4 ms-0" />
              </div>
              <div class="col-6">
                <MaterialInput id="purchasedContact" label="Purchased Contact" variant="static" class="mt-4 ms-0" />
                <MaterialInput id="servicingContact" label="Servicing Contact" variant="static" class="mt-4 ms-0" />
                <MaterialInput id="personnelResponsibleContact" label="Personnel Responsible Contact" variant="static" class="mt-4 ms-0" />
              </div>
            </div>
            <div class="row mt-4">
              <div class="text-end mb-4">
                <button
                  class="btn bg-gradient-info mb-0 toast-btn me-2"
                  type="button"
                  @click="saveAsset"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
