import { defineStore } from 'pinia';
import TucumcariClient from "@/api/tucumcari";
import { getMockAssets } from "@/services/mockData";

export const useAssetStore = defineStore('assets', {
  state: () => ({
    assets: [],
  }),
  getters: {
    allAssets: (state) => state.assets,
  },
  actions: {
    async fetchMockAssets() {
      const assets = await getMockAssets();
      this.assets = assets;
    },
    async fetchAssets() {
      const client = new TucumcariClient();
      try {
        const assets = await client.getAllAsset();
        console.log("API Response:", assets); // Log the assets data
        //this.assets = assets;
      } catch (error) {
        console.error("Failed to fetch assets:", error);
      }
    },
  },
});
