<script setup>
import { ref, inject } from "vue";
import MaterialInput from "@/components/materialKit/MaterialInput.vue";
import MaterialTextarea from "@/components/materialKit/MaterialTextarea.vue";

const nextStep = inject('nextStep');
const prevStep = inject('prevStep');

const operatingSteps = ref([
  {
    step: "Is the Operator Qualified to Operate the Forklift for lifting and placing a load",
    hazard: "Unqualified Operator",
    inherentRisk: "High",
    residualRisk: "Low",
    group: "All",
    control: [
      "Operator must hold a Class 4 HT Licence.",
      "Operator must hold NZQA Unit Standard 16617",
      "Operator has sufficient training for operating the Napier Port Forklift.",
      "Operator PPE – Hard hats, safety glasses, and safety footwear",
      "Dogman PPE – Hard hats, safety glasses, safety footwear, and leather gloves",
    ],
  },
  {
    step: "Forklift Pre Inspection",
    hazard: "Identify machine malfunctioning",
    inherentRisk: "Low",
    residualRisk: "Low",
    group: "All",
    control: [
      "Review weather forecast.",
      "Assess wind speed and impact on the job.",
      "If heavy rain, consider impacts.",
    ],
  },
  {
    step: "Environmental Influences",
    hazard: "High winds and heavy rain",
    inherentRisk: "Low",
    residualRisk: "Low",
    group: "All",
    control: [
      "Ensure that log book has been completed.",
      "View that Fork lift annual certification date to ensure not expired.",
      "Visually inspect forklift",
      "Take care when operating. Always check gauges, and listen for abnormal noises and abnormal operating functions when using the forklift",
    ],
  },
]);
</script>

<template>
  <div class="pt-3 bg-white multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="multisteps-form__content">
      <div class="row mt-2 text-start">
        <h4>Procedure Steps</h4>
        <div v-for="(operatingStep, index) in operatingSteps" :key="index" class="col-12 mb-4">
          <div class="mt-2 border card-plain border-radius-lg p-4">
            <div class="row">
              <div class="col-9">
                <h6 class="text-dark font-weight-bold mb-0">Step: {{ operatingStep.step }}</h6>
              </div>
              <div class="col-3 text-end mb-4">
                <button class="btn mb-0 toast-btn me-2" type="button">
                  Remove step
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <p class="text-sm mt-1 mb-0">
                  <b>Hazards:</b> <br />
                  {{ operatingStep.hazard }}
                </p>
              </div>
              <div class="col-6">
                <p class="text-sm mt-1 mb-0">
                  <b>Inherent Risk Score:</b> {{ operatingStep.inherentRisk }}<br />
                  <b>Residual Risk Score:</b> <span class="text-success">{{ operatingStep.residualRisk }}</span><br />
                  <b>Action By:</b> {{ operatingStep.group }}
                </p>
              </div>
            </div>
            <hr />
            <p class="text-sm mt-1 mb-0 font-weight-bold">Control Methods & Monitoring:</p>
            <ul class="text-sm mt-1 mb-0">
              <li v-for="(control, controlIndex) in operatingStep.control" :key="controlIndex">
                {{ control }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12 mb-4 text-start">
        <h4>Add Step</h4>
        <div class="mt-2 border card-plain border-radius-lg p-4">
          <div class="col-12">
            <MaterialInput id="step" label="Step" variant="static" class="mt-2 ms-0" />
            <label class="mt-4 ms-0">Hazards</label>
            <MaterialTextarea id="hazards" :placeholder="'Add canned text by typing /add'" variant="static" class="mt-0 ms-0" />
          </div>
          <div class="row mt-2">
            <div class="col-12 col-sm-6">
              <MaterialInput id="riskScore1" label="Inherent Risk Score" variant="static" class="mt-4 ms-0" />
            </div>
            <div class="col-12 col-sm-6">
              <MaterialInput id="riskScore2" label="Residual Risk Score" variant="static" class="mt-4 ms-0" />
            </div>
          </div>
          <div class="col-12">
            <label class="mt-4 ms-0">Control Methods & Monitoring</label>
            <MaterialTextarea id="control" :placeholder="'Add canned text by typing /add'" variant="static" class="mt-0 ms-0" />
          </div>
        </div>
      </div>

      <div class="row">
      <div class="mt-4 button-row d-flex col-12">
        <button class="mb-0 btn bg-gradient-light js-btn-prev" type="button" title="Prev" @click="prevStep">Prev</button>
        <button class="mb-0 btn bg-gradient-dark ms-auto js-btn-next" type="button" title="Next" @click="nextStep">Next</button>
      </div>
    </div>
    </div>
  </div>
</template>
