<template>
  <div v-show="tab === 'contacts'" class="p-3 pb-0 card-body">
    <div class="mt-3 row">
      <h4>Related Contacts</h4>
      <div v-if="!isDisabled" class="text-end mb-4">
        <button class="btn bg-gradient-info mb-0 toast-btn me-2" type="button" data-bs-toggle="modal"
          data-bs-target="#contactModal">
          Add contact
        </button>
      </div>
      <div class="col-12 mb-4"></div>
      <div v-if="contacts == incidentContacts">
        <div v-for="(contact, index) in contacts" :key="index"
          class="mb-4 border card-plain border-radius-lg p-4">
          <h6 class="text-dark font-weight-bold mb-0">{{ contact.mainContact }}</h6>
          <div class="row">
            <div class="col-6">
              <p class="text-sm mt-1 mb-0"><b>Company:</b> <br>{{ contact.company }}</p>
              <p class="text-sm mt-1 mb-0"><b>Related to this incident:</b> <br>{{ contact.linked }}</p>
            </div>
            <div class="col-6">
              <p class="text-sm mt-1 mb-0"><b>Phone:</b> <br>{{ contact.phone }}</p>
              <p class="text-sm mt-1 mb-0"><b>Email:</b> <br>{{ contact.email }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(contact, index) in contacts" :key="index"
          class="mb-4 border card-plain border-radius-lg p-4">
          <h6 class="text-dark font-weight-bold mb-0">{{ contact.mainContact }}</h6>
          <div class="row">
            <div class="col-6">
              <p class="text-sm mt-1 mb-0"><b>Company:</b> <br>{{ contact.company }}</p>
              <p class="text-sm mt-1 mb-0"><b>Related to this incident:</b> <br>{{ contact.linked }}</p>
            </div>
            <div class="col-6">
              <p class="text-sm mt-1 mb-0"><b>Phone:</b> <br>{{ contact.phone }}</p>
              <p class="text-sm mt-1 mb-0"><b>Email:</b> <br>{{ contact.email }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactsTab",
  props: {
    tab: {
      type: String,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
};
</script>