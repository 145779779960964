<template>
    <div id="reviewModal" class="modal fade" tabindex="-1" aria-labelledby="reviewModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="reviewModal" class="modal-title">Send for review</h5>
            <MaterialButton color="none" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></MaterialButton>
          </div>
          <div class="modal-body">
            <form>
              <label class="mt-4 ms-0">Person or Team to review the incident</label>
              <select id="reviewer-edit" class="form-control" name="Related">
                <option value="Rufus Dogface">Safety Team</option>
                <option value="Bob Guy">Bob Guy</option>
                <option value="The Electrician">Drivers Team</option>
                <option value="Fun Time">Fun Times</option>
                <option value="Mary Onthestop" selected>Mary Onthestop</option>
              </select>
            </form>
          </div>
          <div class="modal-footer justify-content-between">
            <MaterialButton variant="gradient" color="dark" data-bs-dismiss="modal">Cancel</MaterialButton>
            <MaterialButton variant="gradient" color="info" class="mb-0" data-bs-dismiss="modal">Send</MaterialButton>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MaterialButton from "@/components/materialKit/MaterialButton.vue";
  
  export default {
    name: "ReviewModal",
    components: { MaterialButton },
  };
  </script>
  